import React from "react";

const ActionCard = ({ icon, title, description, onClick }) => {

  return (
    <div className="text-center border-dashed border-2 border-gray-300 p-8 rounded-lg cursor-pointer bg-white"
      onClick={onClick}
    >
      <div className="flex justify-center mb-4">
        <img src={icon} alt={`${title} Icon`} className="w-16 h-16" />
      </div>
      <h3 className="text-lg font-medium mb-2 text-[#000000]">{title}</h3>
      {description.map((text, index) => (
        <p key={index} className="text-gray-500 text-xs">{text}</p>
      ))}
    </div>
  );
}


export default ActionCard;