import React, { useState } from 'react';
import add from "../../../../assets/plusWhite.svg";
import deleteIcon from "../../../../assets/delete.svg";
import { v4 as uuid } from 'uuid';
import CancelModal from "../../../ReUseable/Modals/CancelModal";
import Dropdown from "../../../ReUseable/Dropdown/Dropdown";
import toast from 'react-hot-toast';
import { API } from '../../../../global';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const CreateDataValidationRules = () => {
  const navigate = useNavigate();
  const [validationGroupName, setValidationGroupName] = useState("");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [rules, setRules] = useState([
    {
      id: uuid(),
      column_name: "",
      rule_type: "",
    }
  ]);

  const ruleTypes = ['numeric', 'string'];

  const numericOperationTypes = ['Max Value', 'Min Value', 'Greater Than', 'Less Than', 'Greater Than or Equalto', 'Less Than or Equalto', 'Not Null', 'Unique', 'Non negative'];

  const stringOperationTypes = ['Max Length', 'Min Length', 'Email Check', 'Not Null', 'Unique'];

  const updateColumnName = (columnName, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, column_name: columnName } : obj))
  }

  const updateRuleType = (ruleType, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, rule_type: ruleType } : obj))
  }

  const updateOperation = (operation, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, id: obj.id, rule_type: obj.rule_type, operation: operation } : obj));
  }

  const updateOtherColumn = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, column_name1: value } : obj));
  }

  const updateValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, value: value } : obj));
  }

  const updateStringValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, value: value } : obj));
  }

  const updateStringMaximumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, id: obj.id, operation: obj.operation, rule_type: obj.rule_type, max_value: value } : obj));
  }

  const updateStringMinimumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, id: obj.id, operation: obj.operation, rule_type: obj.rule_type, min_value: value } : obj));
  }

  const updateNumberMaximumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, id: obj.id, operation: obj.operation, rule_type: obj.rule_type, max_value: value } : obj));
  }

  const updateNumberMinimumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, id: obj.id, operation: obj.operation, rule_type: obj.rule_type, min_value: value } : obj));
  }

  const addRule = () => {
    const lastObj = rules[rules.length - 1];
    const ruleDuplicate = rules.filter(d => d.column_name === lastObj.column_name
      && d.rule_type === lastObj.rule_type
      && d.operation === lastObj.operation
      && d.max_value === lastObj.max_value
      && d.min_value === lastObj.min_value
      && d.column_name1 === lastObj.column_name1
    );
    if (ruleDuplicate.length > 1) {
      toast.error('Rule already exist')
    } else {
      setRules([...rules, { id: uuid(), column_name: "", rule_type: "" }]);

    }
  }

  const deleteRule = (id) => {
    setRules(rules => rules.filter(obj => id !== obj.id));
  }

  const saveDataValidationRules = async () => {
    if (validationGroupName.toString().trim().length === 0) {
      toast.error('Validation group name cannot be empty');
      return;
    }
    const countDict = rules.reduce((acc, curr) => {
      const { column_name, rule_type, operation, max_value, min_value, column_name1 } = curr;
      if (acc[column_name] && acc[rule_type] && acc[operation] && acc[max_value] && acc[min_value] && acc[column_name1]) {
        ++acc[column_name];
        ++acc[rule_type];
        ++acc[operation];
        ++acc[max_value];
        ++acc[min_value];
        ++acc[column_name1];
      } else {
        acc[column_name] = 1
        acc[rule_type] = 1
        acc[operation] = 1
        acc[max_value] = 1
        acc[min_value] = 1
        acc[column_name1] = 1
      };
      return acc;
    }, {});

    const result = rules.map((obj) => {
      obj["count"] = countDict[obj.column_name];
      return obj;
    });
    let finalDuplicateFilter = result.filter(d => d.count > 1);
    console.log(finalDuplicateFilter)
    if (finalDuplicateFilter.length === 0) {
      const result = [...new Set(rules.flatMap(
        obj => Object.keys(obj)
          .filter(key => obj[key] === '' || obj[key].toString().trim().length === 0)
      ))]
      if (result.length > 0) {
        toast.error('Fill all the fields');
        return;
      }
      if (rules.length > 0) {
        let groupList = []
        try {
          const listresponse = await axios.get(`${API}/data-validation/data-validation-groups`, {
            "Content-Type": 'application/json'
          })
          groupList = listresponse.data.data;
          let UniqueCheck = groupList.filter(d => d.group_name === validationGroupName)
          if (UniqueCheck.length > 0) {
            toast.error('Group name already exist')
          } else {
            const response = await axios.post(`${API}/data-validation/data_validation_groups`, { "group_name": validationGroupName }, { "Content-Type": 'application/json' })
            rules.forEach((rule) => {
              rule.group_id = response.data.data._id
            })
            await axios.post(`${API}/data-validation/data_validation_rules`, rules, {
              "Content-Type": 'application/json'
            })
            toast.success("Rules created successfully");
            navigate('/data-validation-groups/list');
          }

        }
        catch (error) {
          console.log(error);
        }
      }
      else {
        toast.error("Please add a rule to save it");
      }
    } else {
      toast.error('Remove Duplicate values')
    }
  }

  console.log(rules, 'rules');

  return (   
      <div className={`${cancelModalOpen ? 'overflow-hidden h-screen' : ''}`}>
        <div className='mt-16 px-5 ms-[50px]'>
          <div className='text-[#212529] text-[18px]  font-medium'>Create Validation Rule</div>
          <div className='flex gap-5 mt-5  font-normal text-sm items-center'>
            <label htmlFor="Validation-Group-Name" className="text-lite-black">
              Validation Group Name
            </label>
            <input type="text" placeholder="Enter Name" className="bg-white border custom-input border-[#C7C7C7] rounded-md w-52 text-lite-black px-2.5 py-1 focus-visible:outline-none" id="Validation-Group-Name" value={validationGroupName} onChange={(e) => setValidationGroupName(e.target.value)} />
          </div>

          {/* Rules list */}
          {rules.map((rule, index) => (
            <div className='flex justify-between items-end mt-8' key={index}>
              <div className='flex gap-5 items-center'>
                <div className='flex flex-col gap-2.5 text-sm'>
                  <label htmlFor="Column-Name" className="text-lite-black font-semibold">
                    Column Name
                  </label>
                  <input type="text" placeholder="Enter Column Name" className="border border-[#C7C7C7] rounded-md w-52 text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Column-Name" value={rule.column_name ? rule.column_name : ''} onChange={(e) => updateColumnName(e.target.value, rule.id)} />
                </div>

                {/* Rule types dropdown */}
                <div className='flex flex-col gap-2.5 text-sm'>
                  <label htmlFor="Rule-Type" className="text-lite-black font-semibold">
                    Rule Type
                  </label>
                  <Dropdown options={ruleTypes} width="w-[200px]" updateSelectedValue={updateRuleType} rule={rule} placeholder="Select Rule Type" />
                </div>

                {/* Numeric operation dropdown */}
                {(rule.rule_type !== '' && rule.rule_type === 'numeric') ?
                  <div className='flex flex-col gap-2.5  text-sm'>
                    <label htmlFor="operation" className="text-lite-black font-semibold">
                      Operation
                    </label>
                    <Dropdown options={numericOperationTypes} width="w-[200px]" updateSelectedValue={updateOperation} rule={rule} placeholder="Select Operation" />
                  </div> : ''}

                {/* Numeric other column textboxes */}
                {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Greater Than' || rule.operation === 'Less Than' || rule.operation === 'Greater Than or Equalto' || rule.operation === 'Less Than or Equalto')) ?
                  <div className='flex flex-col gap-2.5  text-sm'>
                    <label htmlFor="Value" className="text-[#212529] font-semibold">
                      Other Column Name
                    </label>
                    <input type="text" placeholder="Enter column name" className="border border-[#C7C7C7] rounded-md w-52 text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.column_name1 ? rule.column_name1 : ""} onChange={(e) => updateOtherColumn(e.target.value, rule.id)} />
                  </div> : ''}

                {/* Numeric value textboxes */}
                {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Count of Distinct Values')) ?
                  <div className='flex flex-col gap-2.5  text-sm'>
                    <label htmlFor="Value" className="text-[#212529] font-semibold">
                      Value
                    </label>
                    <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[200px] text-lite-black px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.value ? rule.value : ""} onChange={(e) => updateValue(e.target.value, rule.id)} />
                  </div> : ''}

                {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Max Value')) ?
                  <div className='flex flex-col gap-2.5 text-sm'>
                    <label htmlFor="Value" className="text-[#212529] font-semibold">
                      Value
                    </label>
                    <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[200px] text-lite-black px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.max_value ? rule.max_value : ''} onChange={(e) => updateNumberMaximumValue(e.target.value, rule.id)} />
                  </div> : ''}

                {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Min Value')) ?
                  <div className='flex flex-col gap-2.5 text-sm'>
                    <label htmlFor="Value" className="text-[#212529] font-semibold">
                      Value
                    </label>
                    <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[200px] text-lite-black px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.min_value ? rule.min_value : ''} onChange={(e) => updateNumberMinimumValue(e.target.value, rule.id)} />
                  </div> : ''}

                {/* String operation dropdown */}
                {(rule.rule_type !== '' && rule.rule_type === 'string') ?
                  <div className='flex flex-col gap-2.5  text-sm'>
                    <label htmlFor="operation" className="text-[#212529] font-semibold">
                      Operation
                    </label>
                    <Dropdown options={stringOperationTypes} width="w-[200px]" updateSelectedValue={updateOperation} rule={rule} placeholder="Select Operation" />
                  </div> : ''}

                {/* String value textboxes */}
                {((rule.rule_type !== '') && (rule.rule_type === 'string') && (rule.operation === 'Contains' || rule.operation === 'Count of Distinct Values')) ?
                  <div className='flex flex-col gap-2.5  text-sm'>
                    <label htmlFor="Value" className="text-[#212529] font-semibold">
                      String
                    </label>
                    <input type="text" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-52 text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.value ? rule.value : ''} onChange={(e) => updateStringValue(e.target.value, rule.id)} />
                  </div> : ''}

                {/* String Maximum Value textboxes */}
                {((rule.rule_type !== '') && (rule.rule_type === 'string') && (rule.operation === 'Max Length')) ?
                  <div className='flex flex-col gap-2.5  text-sm'>
                    <label htmlFor="Value" className="text-[#212529] font-semibold">
                      Maximum Length
                    </label>
                    <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-52 text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.max_value ? rule.max_value : ''} onChange={(e) => updateStringMaximumValue(e.target.value, rule.id)} />
                  </div> : ''}

                {/* String Minimum Value textboxes */}
                {((rule.rule_type !== '') && (rule.rule_type === 'string') && (rule.operation === 'Min Length')) ?
                  <div className='flex flex-col gap-2.5  text-sm'>
                    <label htmlFor="Value" className="text-[#212529] font-semibold">
                      Minimum Length
                    </label>
                    <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-52 text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.min_value ? rule.min_value : ''} onChange={(e) => updateStringMinimumValue(e.target.value, rule.id)} />
                  </div> : ''}
              </div>

              <div className='flex items-center align-middle gap-5' >
                {index === rules.length - 1 &&
                  <div>
                    <button className={`${((rule.column_name?.toString().trim().length !== 0) && (((rule.column_name1) || rule.value || rule.max_value || rule.min_value) || rule.string || rule.operation === 'Unique' || rule.operation === 'Not Null' || rule.operation === 'Non negative' || rule.operation === 'Email Check')) ? 'bg-[#0078C5]' : 'bg-[#9ac8e6] cursor-default pointer-events-none'}  disabled:bg-[#9ac8e6] px-3 py-1.5 rounded-md flex justify-center border-none items-center text-[#ffffff] gap-2 text-sm  font-normal`} onClick={() => addRule()}><img src={add} alt="add" />Add Rule</button>
                  </div>}
                <div onClick={() => deleteRule(rule.id)} className='cursor-pointer'><img src={deleteIcon} alt="delete" /></div>
              </div>
            </div>
          ))}

          <hr className='border-0.5 border-[#C7C7C7] mt-8' />

          <div className='flex justify-end mt-8'>
            <div className='flex gap-2.5 text-sm text-[#ffffff] font-normal'>
              <button className='bg-[#BFBFBF] w-16 rounded-md py-1.5' onClick={() => setCancelModalOpen(true)}>Cancel</button>
              <button className='bg-[#0078C5] w-16 rounded-md py-1.5' onClick={() => saveDataValidationRules()}>Save</button>
            </div>
          </div>
        </div>
        {cancelModalOpen ?
          <CancelModal setModalOpen={setCancelModalOpen} modalOpen={cancelModalOpen} /> : ''
        }
      </div>    
  )
}

export default CreateDataValidationRules
