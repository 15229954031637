import React from 'react';
import Layout from '../../utils/Layout';

const Settings = () => {
  return (
    <Layout>
      <div className="pt-16 ms-[50px] px-5 py-1 bg-[#F7F7F7] h-screen overflow-y-auto pb-10">
        <div className='text-xl text-[#0083BF] font-medium'>License</div>
        <hr className='border-0 h-0.5 bg-[#DDDEE0] mt-2 mb-[35px]' />
        <table className='text-base'>
          <tbody>
            <tr className='align-top'>
              <td className='pe-5 pb-4'>Current Plan</td>
              <td className='pb-4'>
                <span className='bg-gradient-to-r from-[#777777] to-[#9E9E9E] py-1.5 px-4 rounded-md text-white'>Freemium</span>
              </td>
            </tr>
            <tr className='align-top'>
              <td className='pe-5 pb-4'>Company Name</td>
              <td className='pb-4 font-semibold'>Nuivio Inc</td>
            </tr>
            <tr className='align-top'>
              <td className='pe-5 pb-4'>Workspace</td>
              <td className='pb-4 font-semibold'>5</td>
            </tr>
            <tr className='align-top'>
              <td className='pe-5 pb-4'>Data Validation</td>
              <td className='pb-4 font-semibold'>10 Times/Day</td>
            </tr>
            <tr className='align-top'>
              <td className='pe-5 pb-4'>Data Transfer from source to destination</td>
              <td className='pb-4 font-semibold'>2 Times/Day</td>
            </tr>
          </tbody>
        </table>

        <div className='mt-5'>
         <span className='bg-[#0083BF] border-[#0083BF] text-white px-3 py-[6px] rounded-md'>Upgrade Plan</span>
        </div>
        <hr className='border-0 h-0.5 bg-[#DDDEE0] mt-5 mb-[35px]' />

        <div className='flex gap-[30px]'>
          <div className='bg-white rounded-xl drop-shadow-[0_3px_6px_#0000001A] text-center px-5 py-[30px] font-medium'>
            <div className='mb-[15px] text-[#0083BF] text-xl py-2.5'>Our Plans</div>
            <div className='mb-2.5 text-sm'>Workspace</div>
            <div className='mb-2.5 text-sm bg-[#E5F7F7] rounded-[10px] py-2.5'>Data Validation</div>
            <div className='text-sm'>Data Transfer from source to destination</div>
          </div>

          <div className='bg-white w-[280px] rounded-xl drop-shadow-[0_3px_6px_#0000001A] text-center px-5 py-[30px] font-medium'>
            <div className='mb-[15px] py-2.5 text-white text-xl bg-gradient-to-r from-[#009BF8] to-[#00DFFE] rounded-[10px]'>Basic</div>
            <div className='mb-2.5 text-sm'>15</div>
            <div className='mb-2.5 text-sm bg-[#E5F7F7] rounded-[10px] py-2.5'>50 Times/Day</div>
            <div className='text-sm'>20 Times/Day</div>
          </div>

          <div className='bg-white w-[280px] rounded-xl drop-shadow-[0_3px_6px_#0000001A] text-center px-5 py-[30px] font-medium'>
            <div className='mb-[15px] py-2.5 text-white text-xl bg-gradient-to-r from-[#008E21] to-[#00C22D] rounded-[10px]'>Plus</div>
            <div className='mb-2.5 text-sm'>50</div>
            <div className='mb-2.5 text-sm bg-[#E5F7F7] rounded-[10px] py-2.5'>250 Times/Day</div>
            <div className='text-sm'>100 Times/Day</div>
          </div>

          <div className='bg-white w-[280px] rounded-xl drop-shadow-[0_3px_6px_#0000001A] text-center px-5 py-[30px] font-medium'>
            <div className='mb-[15px] py-2.5 text-white text-xl bg-gradient-to-r from-[#46006E] to-[#600097] rounded-[10px]'>Premium</div>
            <div className='mb-2.5 text-sm'>Unlimited</div>
            <div className='mb-2.5 text-sm bg-[#E5F7F7] rounded-[10px] py-2.5'>Unlimited</div>
            <div className='text-sm'>Unlimited</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Settings
