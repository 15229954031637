import React from 'react';
// import warningIcon from "../../../assets/warningIcon.svg";
import closeIcon from "../../../assets/closeIcon.svg";
import { useNavigate } from 'react-router-dom';
import warning from "../../../assets/warningYellow.svg";

const CancelModal = ({ setModalOpen, modalRef }) => {
  const navigate = useNavigate();

  return (
    <div className='flex items-center justify-center w-full h-screen fixed top-0 left-0 backdrop-brightness-75 z-40'>
      <div ref={modalRef} className='bg-[#ffffff] w-[520px] rounded-lg flex flex-col p-5 items-center'>
        <div className='flex justify-end w-full cursor-pointer'>
          <img src={closeIcon} alt="close" onClick={() => setModalOpen(false)} />
        </div>
        <img src={warning} alt="warning" className='w-[60px] h-[54px]' />
        <div className='text-[#212529] text-2xl font-medium my-2.5'>Are you sure ?</div>
        <div>Do you really want to cancel ? </div>
        <div>Changes made to this content have not been saved yet.</div>
        <div className='flex mt-2.5 gap-2.5'>
          <button className='py-1.5 px-2 border-none bg-[#BFBFBF] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => setModalOpen(false)}>Cancel</button>
          <button className='py-1.5 px-2 border-none bg-[#0078C5] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => navigate('/data-validation-groups/list')}>Proceed</button>
        </div>
      </div>
    </div>
  )
}

export default CancelModal
