import React from 'react';

const TableData = ({ sheet, sheetData, currentItems }) => {

  return (
    <div className="overflow-x-auto w-full font-semibold border border-[#DDDEE0] text-sm mb-2 z-10">
      <table className="w-full text-center text-sm table-fixed">
        {sheetData[sheet].length === 0 ?
          <tbody>
            <tr>
              <td className="px-4 py-3 text-sm text-[#212529]">There are no records to display.</td>
            </tr>
          </tbody>
          :
          <>
            <thead className="border-b font-medium border-[#DDDEE0]">
              <tr>
                {sheetData[sheet][0]?.map((h, i) => (
                  <th
                    key={i}
                    className="px-4 py-3 text-[#212529] font-semibold text-sm text-left w-44 h-[45px] break-all"
                  >
                    {h}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                currentItems.length === 0 ?
                  <td colSpan={6} className="px-4 py-3 text-sm text-[#212529] font-normal">There are no records to display.</td>
                  :
                  currentItems?.map((row, i) => (
                    <tr
                      key={i}
                      className="border-b font-normal text-sm border-[#DDDEE0]">
                      {row.map((c, i) => (
                        <td
                          key={i}
                          className="px-4 py-3 text-[#212529] text-left w-44 h-[45px] break-all">
                          {(c === null || c === undefined) ? '' :
                            typeof c === 'object' ?
                              (
                                <span>
                                  {Object.keys(c).map((key, index) => (
                                    <p key={index}>{key}: {c[key]}</p>
                                  ))}
                                </span>
                              )
                              : c
                          }
                        </td>
                      ))}
                    </tr>
                  ))}
            </tbody>
          </>}
      </table>
    </div>
  )
}

export default TableData