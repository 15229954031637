import React from 'react';
import notification from "../../assets/DashboardAssets/notification.svg";
import settings from "../../assets/DashboardAssets/settings.svg";
import profile from "../../assets/DashboardAssets/profile.svg";
import refresh from "../../assets/DashboardAssets/refresh.svg";
import dashboardIcon from "../../assets/DashboardAssets/dashboard.svg";
import DashboardDropdown from '../ReUseable/Dropdown/DashboardDropdown';
import warning from "../../assets/DashboardAssets/warning.svg";
import flag from "../../assets/DashboardAssets/flagRed.svg";
import facebook from "../../assets/DashboardAssets/facebook.svg";
import wrong from "../../assets/DashboardAssets/wrong.svg";
import link from "../../assets/DashboardAssets/link-45deg.svg";
import view from "../../assets/DashboardAssets/view.svg";
import share from "../../assets/DashboardAssets/share.svg";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import India from "../../assets/DashboardAssets/India.svg";
import UAE from "../../assets/DashboardAssets/UAE.svg";
import USA from "../../assets/DashboardAssets/USA.svg";
import Syria from "../../assets/DashboardAssets/Syria.svg";
import Srilanka from "../../assets/DashboardAssets/Srilanka.svg";

const Dashboard = () => {
  const timeRange = ['Today', 'This Week', 'This Month', 'This Year'];

  const data = [
    {
      name: 'Dec 4',
      uv: 600,
    },
    {
      name: 'Dec 5',
      uv: 350,
    },
    {
      name: 'Dec 6',
      uv: 450,
    },
    {
      name: 'Dec 7',
      uv: 850,
    },
    {
      name: 'Dec 8',
      uv: 800,
    },
    {
      name: 'Dec 9',
      uv: 1000,
    },
    {
      name: 'Dec 10',
      uv: 900,
    },
  ];

  const data1 = [
    { name: 'Extremist Content', value: 990 },
    { name: 'Privacy Violation', value: 859 },
    { name: 'Sexual Content', value: 1999 },
    { name: 'Graphic Violence', value: 1047 },
    { name: 'Hate Speech', value: 623 },
  ];

  const COLORS = ['#008CE6', '#FFB300', '#8A2BE2', '#00C8AD', '#FF5733'];

  return (
    <div className='font-roboto w-full bg-lite-grey-6 h-screen'>
      <div className='fixed top-0 left-0 right-0 z-30'>
        {/* Navbar */}
        <nav className="bg-gradient-to-r from-teal to to-blue-2 h-16">
          <div className='flex justify-between items-center h-full px-5'>
            <div className='text-white text-2xl font-medium'>Human Rights Monitor</div>
            <div className='flex gap-5'>
              <img src={notification} alt="notify" className='w-7 h-7' />
              <img src={settings} alt="settings" className='w-7 h-7' />
              <img src={profile} alt="profile" className='w-7 h-7' />
            </div>
          </div>
        </nav>

        {/* Dashboard */}
        <div className='bg-lite-blue px-5 font-medium flex justify-between items-center h-11 z-30'>
          <div className='text-dark-black text-xl xl:text-2xl font-semibold'>Dashboard</div>
          <div className='flex gap-5 justify-center items-center text-sm'>
            <div className='text-dark-black'>Last Sync: 8 Dec 22, 11:23 am</div>
            <button className='flex gap-2 bg-gradient-to-r from-teal to to-blue-2 rounded-md px-2.5 py-1.5'><span><img src={refresh} alt="refresh" /></span>Refresh</button>
            <DashboardDropdown width="w-28" options={timeRange} selectedValue="This Week" />
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <div className='fixed top-0 bottom-0 bg-lite-grey-6 w-14 border-r border-r-lite-grey-7'>
        <div className='flex mt-32 items-center justify-center'>
          <img src={dashboardIcon} alt="dashboard" />
        </div>
      </div>

      {/* Dashboard main content */}
      <div className='p-7 absolute top-24 left-14 right-0 bottom-0 overflow-y-auto'>
        {/* Dashboard cards */}
        <div className='flex flex-col gap-8'>
          <div className='flex gap-5'>
            <div className='bg-pink flex flex-col w-72 p-5 gap-4 rounded-md border-2 border-white'>
              <div className='font-medium text-xl'>
                Total Violation Posts
              </div>
              <div className='flex justify-between'>
                <div className='text-dark-black text-3xl xl:text-4xl  font-bold'>4718</div>
                <img src={warning} alt="warning" />
              </div>
            </div>

            <div className='bg-pink flex flex-col w-72 p-5 gap-4 rounded-md border-2 border-white'>
              <div className='font-medium text-xl'>
                Total Flagged Users
              </div>
              <div className='flex justify-between'>
                <div className='text-dark-black text-3xl xl:text-4xl font-bold'>3981</div>
                <img src={flag} alt="flag" />
              </div>
            </div>

            <div className='bg-lite-blue-1 flex flex-col w-72 p-5 gap-4 rounded-md border-2 border-white'>
              <div className='font-medium text-xl'>
                Violation Posts
              </div>
              <div className='flex justify-between'>
                <div className='text-dark-black text-3xl xl:text-4xl font-bold'>1969</div>
                <img src={facebook} alt="facebook" />
              </div>
            </div>

            <div className='bg-lite-blue-1 flex flex-col w-72 p-5 gap-4 rounded-md border-2 border-white'>
              <div className='font-medium text-xl'>
                Users Flagged
              </div>
              <div className='flex justify-between'>
                <div className='text-dark-black text-3xl xl:text-4xl font-bold'>1599</div>
                <img src={facebook} alt="facebook" />
              </div>
            </div>

            <div className='bg-lite-grey-8 flex flex-col w-72 p-5 gap-4 rounded-md border-2 border-white'>
              <div className='font-medium text-xl'>
                Violation Posts
              </div>
              <div className='flex justify-between'>
                <div className='text-dark-black text-3xl xl:text-4xl font-bold'>2749</div>
                <img src={wrong} alt="wrong" />
              </div>
            </div>

            <div className='bg-lite-grey-8 flex flex-col w-72 p-5 gap-4 rounded-md border-2 border-white'>
              <div className='font-medium text-xl'>
                Users Flagged
              </div>
              <div className='flex justify-between'>
                <div className='text-dark-black text-3xl xl:text-4xl font-bold'>2382</div>
                <img src={wrong} alt="wrong" />
              </div>
            </div>
          </div>

          <div className='flex gap-5'>
            {/* Recent Violation Posts */}
            <div className='flex flex-col gap-5 xl:w-[36.8rem] lg:w-[25.2rem]'>
              <div className='xl:text-2xl text-lite-black font-semibold text-xl'>Recent Violation Posts</div>
              <div className='bg-white h-[36.8rem] border-lite-grey-8 p-5 overflow-y-auto rounded-md flex flex-col gap-5'>
                {/* Posts */}
                <div className='border border-blue-3 rounded-md p-4'>
                  <div className='text-sm italic text-lite-black-2 mb-4'>Every child deserves the gift of education, play, and dreams – not the burden of labor. Let us unite to end child labor and build a world where every child can grow freely and reach for the stars.<span className='text-sky-blue-1 text-sm'>#childlabour #childrights</span></div>
                  <div className='flex justify-between items-baseline'>
                    <div className='flex items-baseline xl:gap-3 lg:gap-2'>
                      <img src={facebook} alt="facebook" />
                      <div className='text-lite-black-2 text-base'>@adam_green</div>
                    </div>
                    <div className='flex xl:gap-3 lg:gap-3'>
                      <div className='flex items-center gap-2'><img src={share} alt="share" /><span>721</span></div>
                      <div className='flex items-center gap-2'> <img src={view} alt="view" /><span>2.3K</span></div>
                      <img src={link} alt="link" className='w-5 h-5' />
                    </div>
                  </div>
                </div>

                <div className='border border-lite-black-2 rounded-md p-4'>
                  <div className='text-sm italic text-lite-black-2 mb-4'>Silence the hate, amplify the empathy. Our words have power – let's use them to build bridges, not walls.<span className='text-sky-blue-1 text-sm'>#speakwithkindness #hatespeech</span></div>
                  <div className='flex justify-between items-baseline'>
                    <div className='flex gap-3 items-baseline'>
                      <img src={wrong} alt="wrong" />
                      <div className='text-lite-black-2 text-base'>@beetle_fan</div>
                    </div>
                    <div className='flex xl:gap-3 lg:gap-3'>
                      <div className='flex items-center gap-2'><img src={share} alt="share" /><span>721</span></div>
                      <div className='flex items-center gap-2'> <img src={view} alt="view" /><span>2.3K</span></div>
                      <img src={link} alt="link" className='w-5 h-5' />
                    </div>
                  </div>
                </div>

                <div className='border border-blue-3 rounded-md p-4'>
                  <div className='text-sm italic text-lite-black-2 mb-4'>Every child deserves the gift of education, play, and dreams – not the burden of labor. Let us unite to end child labor and build a world where every child can grow freely and reach for the stars.<span className='text-sky-blue-1 text-sm'>#childlabour #childrights</span></div>
                  <div className='flex justify-between items-baseline'>
                    <div className='flex gap-3 items-baseline'>
                      <img src={facebook} alt="facebook" />
                      <div className='text-lite-black-2 text-base'>@adam_green</div>
                    </div>
                    <div className='flex xl:gap-3 lg:gap-3'>
                      <div className='flex items-center gap-2'><img src={share} alt="share" /><span>721</span></div>
                      <div className='flex items-center gap-2'> <img src={view} alt="view" /><span>2.3K</span></div>
                      <img src={link} alt="link" className='w-5 h-5' />
                    </div>
                  </div>
                </div>

                <div className='border border-lite-black-2 rounded-md p-4'>
                  <div className='text-sm italic text-lite-black-2 mb-4'>Silence the hate, amplify the empathy. Our words have power – let's use them to build bridges, not walls.<span className='text-sky-blue-1 text-sm'>#speakwithkindness #hatespeech</span></div>
                  <div className='flex justify-between items-baseline'>
                    <div className='flex gap-3 items-baseline'>
                      <img src={wrong} alt="wrong" />
                      <div className='text-lite-black-2 text-base'>@beetle_fan</div>
                    </div>
                    <div className='flex xl:gap-3 lg:gap-3'>
                      <div className='flex items-center gap-2'><img src={share} alt="share" /><span>721</span></div>
                      <div className='flex items-center gap-2'> <img src={view} alt="view" /><span>2.3K</span></div>
                      <img src={link} alt="link" className='w-5 h-5' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='xl:w-[36.8rem] lg:w-[25.2rem]'>
              <div className='flex flex-col gap-5'>
                {/* Violation Posts Analytics */}
                <div className='flex flex-col gap-5'>
                  <div className='xl:text-2xl text-xl text-lite-black font-semibold'>Violation Posts Analytics</div>
                  <div className='bg-white rounded-md h-80 border-2 border-lite-grey-8 py-3 pe-3'>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={data}
                        margin={{ top: 10, right: 20, left: -15, bottom: 0 }}>
                        <defs>
                          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#00C8AD" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#00C8AD" stopOpacity={0.1} />
                          </linearGradient>
                        </defs>
                        <CartesianGrid vertical={false} stroke="#98989866" strokeWidth={0.4} />
                        <XAxis axisLine={false} tickLine={false} interval={0} dataKey="name" tick={{ fontSize: 12 }} tickMargin={7} />
                        <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12 }} type="number" />
                        <Tooltip />
                        <Area type="monotone" dataKey="uv" stroke="#00C8AD" strokeWidth={2} fill="url(#colorUv)" />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                {/* Most Shared Violation Posts */}
                <div className='flex flex-col gap-5'>
                  <div className='xl:text-2xl text-xl text-lite-black font-semibold'>Most Shared Violation Posts</div>
                  <div className='bg-white rounded-md h-[12.5rem] border-2 border-lite-grey-8'>
                    <div className="mt-0 font-semibold text-sm mb-2 z-10 h-full overflow-y-auto">
                      <table className="w-full table-fixed">
                        <thead className="border-b border-b-dark-black text-dark-black font-semibold text-base">
                          <tr>
                            <th className="px-4 py-3 text-left">
                              Post
                            </th>
                            <th className="px-4 py-3text-center">
                              Platform
                            </th>
                            <th className="px-4 py-3text-center">
                              Category
                            </th>
                            <th className="px-4 py-3 text-center">
                              Shared
                            </th>
                            <th className="px-4 py-3 text-center">
                              Post Link
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left overflow-hidden text-ellipsis whitespace-nowrap">
                              Challenge the status quo... Empower change through... Unite against violations... Transforming words into... Creating a world...
                            </td>
                            <td className="py-1 flex justify-center">
                              <img src={facebook} alt="facebook" className='w-4 h-4' />
                            </td>
                            <td className="py-1 text-center">
                              Graphic Violence
                            </td>
                            <td className="py-1 text-center">
                              27K times
                            </td>
                            <td className="py-1 text-xs text-dark-black flex justify-center">
                              <img src={link} alt="link" className='w-2.5 h-2.5' />
                            </td>
                          </tr>
                          <tr
                            className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left overflow-hidden text-ellipsis whitespace-nowrap">
                              Challenge the status quo... Empower change through... Unite against violations... Transforming words into... Creating a world...
                            </td>
                            <td className="py-1 flex justify-center">
                              <img src={facebook} alt="facebook" className='w-4 h-4' />
                            </td>
                            <td className="py-1 text-center">
                              Graphic Violence
                            </td>
                            <td className="py-1 text-center">
                              27K times
                            </td>
                            <td className="py-1 text-xs text-dark-black flex justify-center">
                              <img src={link} alt="link" className='w-2.5 h-2.5' />
                            </td>
                          </tr>
                          <tr
                            className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left overflow-hidden text-ellipsis whitespace-nowrap">
                              Challenge the status quo... Empower change through... Unite against violations... Transforming words into... Creating a world...
                            </td>
                            <td className="py-1 flex justify-center">
                              <img src={facebook} alt="facebook" className='w-4 h-4' />
                            </td>
                            <td className="py-1 text-center">
                              Graphic Violence
                            </td>
                            <td className="py-1 text-center">
                              27K times
                            </td>
                            <td className="py-1 text-xs text-dark-black flex justify-center">
                              <img src={link} alt="link" className='w-2.5 h-2.5' />
                            </td>
                          </tr>
                          <tr
                            className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left overflow-hidden text-ellipsis whitespace-nowrap">
                              Challenge the status quo... Empower change through... Unite against violations... Transforming words into... Creating a world...
                            </td>
                            <td className="py-1 flex justify-center">
                              <img src={facebook} alt="facebook" className='w-4 h-4' />
                            </td>
                            <td className="py-1 text-center">
                              Graphic Violence
                            </td>
                            <td className="py-1 text-center">
                              27K times
                            </td>
                            <td className="py-1 text-xs text-dark-black flex justify-center">
                              <img src={link} alt="link" className='w-2.5 h-2.5' />
                            </td>
                          </tr>
                          <tr
                            className="font-medium text-xs text-dark-black">
                            <td className="px-4 py-1 text-left overflow-hidden text-ellipsis whitespace-nowrap">
                              Challenge the status quo... Empower change through... Unite against violations... Transforming words into... Creating a world...
                            </td>
                            <td className="py-1 flex justify-center">
                              <img src={facebook} alt="facebook" className='w-4 h-4' />
                            </td>
                            <td className="py-1 text-center">
                              Graphic Violence
                            </td>
                            <td className="py-1 text-center">
                              27K times
                            </td>
                            <td className="py-1 text-xs text-dark-black flex justify-center">
                              <img src={link} alt="link" className='w-2.5 h-2.5' />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='xl:w-[36.8rem] lg:w-[25.2rem]'>
              <div className='flex flex-col gap-5'>
                {/* Violation By Category */}
                <div className='flex flex-col gap-5'>
                  <div className='xl:text-2xl text-xl text-lite-black font-semibold'>Violation By Category</div>
                  <div className='bg-white rounded-md h-80 border-2 border-lite-grey-8 relative'>
                    {/* Legend for the Pie chart */}
                    <div className='absolute top-0 bottom-0 xl:left-80 left-[13rem] flex flex-col justify-evenly'>
                      <div className='flex flex-col gap-5 font-medium text-base'>
                        <div className='flex gap-3'>
                          <div className='bg-[#008CE61A] rounded-full w-7 h-7 relative flex justify-center items-center'>
                            <div className='bg-blue rounded-full w-4 h-4 absolute'></div>
                          </div>
                          <div className='text-lite-black-4 flex flex-col'>Extremist Content<span className='text-lite-grey-10 text-xs'>990 Posts</span></div>
                        </div>
                        <div className='flex gap-3'>
                          <div className='bg-[#FFB3001A] rounded-full w-7 h-7 relative flex justify-center items-center'>
                            <div className='bg-yellow rounded-full w-4 h-4 absolute'></div>
                          </div>
                          <div className='flex flex-col text-lite-black-4'>Privacy Violation<span className='text-lite-grey-10 text-xs'>859 Posts</span></div>
                        </div>
                        <div className='flex gap-3'>
                          <div className='bg-[#8A2BE21A] rounded-full w-7 h-7 relative flex justify-center items-center'>
                            <div className='bg-purple rounded-full w-4 h-4 absolute'></div>
                          </div>
                          <div className='text-lite-black-4 flex flex-col'>Sexual Content<span className='text-lite-grey-10 text-xs'>1,999 Posts</span></div>
                        </div>
                        <div className='flex gap-3'>
                          <div className='bg-[#00C8AD1A] rounded-full w-7 h-7 relative flex justify-center items-center'>
                            <div className='bg-teal rounded-full w-4 h-4 absolute'></div>
                          </div>
                          <div className='text-lite-black-4 flex flex-col'>Graphic Violence<span className='text-lite-grey-10 text-xs'>1,047 Posts</span></div>
                        </div>
                        <div className='flex gap-3'>
                          <div className='bg-[#FF57331A] rounded-full w-7 h-7 relative flex justify-center items-center'>
                            <div className='bg-red rounded-full w-4 h-4 absolute'></div>
                          </div>
                          <div className='text-lite-black-4 flex flex-col'>Hate Speech<span className='text-lite-grey-10 text-xs'>623 Posts</span></div>
                        </div>
                      </div>
                    </div>
                    <div className='h-80 w-full lg:-ml-5 xl:ml-0'>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <text x={125} y={159} textAnchor="middle" dominantBaseline="central" fontSize={36} fontFamily="Roboto" fontWeight={600}>
                            4718
                          </text>
                          <Pie
                            data={data1}
                            cx={120}
                            // cy={160}
                            innerRadius={55}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          {/* <Legend layout="vertical" verticalAlign="middle" align="right" /> */}
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>

                {/* Most Shared Violation Posts */}
                <div className='flex flex-col gap-5'>
                  <div className='xl:text-2xl text-xl text-lite-black font-semibold'>Violation Posts By Demographic</div>
                  <div className='bg-white rounded-md h-[12.5rem] border-2 border-lite-grey-8'>
                    <div className="mt-0 font-semibold text-sm mb-2 z-10 h-full overflow-y-auto">
                      <table className="w-full table-fixed">
                        <thead className="border-b border-b-dark-black text-dark-black font-semibold text-base">
                          <tr>
                            <th className="px-4 py-3 text-left">
                              Location
                            </th>
                            <th className="px-4 py-3 text-center">
                              No of Posta
                            </th>
                            <th className="px-4 py-3 text-center">
                              Average Share Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left flex">
                              <img src={India} alt="facebook" className='w-8 h-5 me-2' />India
                            </td>
                            <td className="py-1 text-center">
                              1496 Posts
                            </td>
                            <td className="py-1 text-center">
                              9.6K times
                            </td>
                          </tr>
                          <tr className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left flex">
                              <img src={Srilanka} alt="facebook" className='w-8 h-5 me-2' />Srilanka
                            </td>
                            <td className="py-1 text-center">
                              1496 Posts
                            </td>
                            <td className="py-1 text-center">
                              9.6K times
                            </td>
                          </tr>
                          <tr className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left flex">
                              <img src={Syria} alt="facebook" className='w-8 h-5 me-2' />Syria
                            </td>
                            <td className="py-1 text-center">
                              1496 Posts
                            </td>
                            <td className="py-1 text-center">
                              9.6K times
                            </td>
                          </tr>
                          <tr className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left flex">
                              <img src={UAE} alt="facebook" className='w-8 h-5 me-2' />UAE
                            </td>
                            <td className="py-1 text-center">
                              1496 Posts
                            </td>
                            <td className="py-1 text-center">
                              9.6K times
                            </td>
                          </tr>
                          <tr className="font-medium text-xs text-dark-black border-b border-b-lite-grey-8">
                            <td className="px-4 py-1 text-left flex">
                              <img src={USA} alt="facebook" className='w-8 h-5 me-2' />USA
                            </td>
                            <td className="py-1 text-center">
                              1496 Posts
                            </td>
                            <td className="py-1 text-center">
                              9.6K times
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
