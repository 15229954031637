import React from 'react';

const PrepareStepper = ({ previewStepper, thankyou, connectDb, connectApi }) => {

  return (
    <div className="w-full flex text-center justify-center mt-10">
      <ul className="progressbar_prepare font-medium text-sm text-[#616161]">
        <li className={`${thankyou ? 'green' : 'active'}`}>{connectDb || connectApi ? 'Connect' : 'Upload'}</li>
        <li className={`${(previewStepper && thankyou) ? 'green' : previewStepper ? 'active' : ''}`}>Preview</li>
        <li className={`${thankyou ? 'green' : ''}`}>Finish</li>
      </ul>
    </div>

  )
}
export default PrepareStepper
