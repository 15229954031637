import React, { useState, useEffect, useRef } from "react";
import { FaRegClock } from "react-icons/fa";

const CustomTimePicker = ({ selectedTime, setSelectedTime }) => {
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [hours, setHours] = useState("12");
  const [minutes, setMinutes] = useState("00");
  const [ampm, setAmPm] = useState("PM");
  const [hourRange, setHourRange] = useState([]);
  const [minuteRange, setMinuteRange] = useState([]);
  const timePickerRef = useRef(null);
  const [focusedColumn, setFocusedColumn] = useState(null);

  const hourRef = useRef(null);
  const minuteRef = useRef(null);
  const ampmRef = useRef(null);

  const updateSelectedTime = (newHours = hours, newMinutes = minutes, newAmPm = ampm) => {
    setSelectedTime(`${newHours}:${newMinutes} ${newAmPm}`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (timePickerRef.current && !timePickerRef.current.contains(event.target)) {
        setShowTimePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [timePickerRef]);

  useEffect(() => {
    if (showTimePicker && hourRef.current) {
      hourRef.current.focus();
    }
  }, [showTimePicker])

  useEffect(() => {
    updateSelectedTime(hours, minutes, ampm);
    // eslint-disable-next-line
  }, [hours, minutes, ampm]);

  const calculateInitialRanges = () => {
    const hourInt = parseInt(hours);
    const newHourRange = Array.from({ length: 6 }, (_, i) => ((hourInt + i) % 12 || 12));
    setHourRange(newHourRange);

    const minuteInt = parseInt(minutes);
    const newMinuteRange = Array.from({ length: 6 }, (_, i) => (minuteInt + i) % 60);
    setMinuteRange(newMinuteRange);
  };

  const toggleTimePicker = () => {
    setShowTimePicker(!showTimePicker);
    if (!showTimePicker) {
      setFocusedColumn("hours");
      calculateInitialRanges();    
    }
  };

  const handleHourSelection = (newHour) => {
    setHours(newHour);
    setFocusedColumn("hours");
  };

  const handleMinuteSelection = (newMinute) => {
    setMinutes(newMinute);
    setFocusedColumn("minutes");
  };

  const handlePeriodSelection = (newPeriod) => {
    setAmPm(newPeriod);
    setFocusedColumn("ampm");
  };

  const handleArrowKeyPress = (e, type) => {
    if (e.key === "Tab" || e.key === "ArrowRight") {
      e.preventDefault();
      if (type === "hours") {
        setFocusedColumn("minutes");
        minuteRef.current.focus();
      } else if (type === "minutes") {
        setFocusedColumn("ampm");
        ampmRef.current.focus();
      }
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      if (type === "minutes") {
        setFocusedColumn("hours");
        hourRef.current.focus();
      } else if (type === "ampm") {
        setFocusedColumn("minutes");
        minuteRef.current.focus();
      }
    } else {
      if (type === "hours") {
        const currentIndex = hourRange.indexOf(parseInt(hours));
        if (e.key === "ArrowUp") {
          e.preventDefault();
          if (currentIndex === 0) {
            const newHourRange = hourRange.map(hour => ((hour - 2 + 12) % 12 + 1) || 12);
            setHourRange(newHourRange);
            setHours(newHourRange[0].toString().padStart(2, "0"));
          } else {
            setHours(hourRange[currentIndex - 1].toString().padStart(2, "0"));
          }
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          if (currentIndex === hourRange.length - 1) {
            const newHourRange = hourRange.map(hour => ((hour) % 12 + 1) || 12);
            setHourRange(newHourRange);
            setHours(newHourRange[newHourRange.length - 1].toString().padStart(2, "0"));
          } else {
            setHours(hourRange[currentIndex + 1].toString().padStart(2, "0"));
          }
        }
      } else if (type === "minutes") {
        const currentIndex = minuteRange.indexOf(parseInt(minutes));
        if (e.key === "ArrowUp") {
          e.preventDefault();
          if (currentIndex === 0) {
            const newMinuteRange = minuteRange.map(minute => ((minute - 1 + 60) % 60));
            setMinuteRange(newMinuteRange);
            setMinutes(newMinuteRange[0].toString().padStart(2, "0"));
          } else {
            setMinutes(minuteRange[currentIndex - 1].toString().padStart(2, "0"));
          }
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          if (currentIndex === minuteRange.length - 1) {
            const newMinuteRange = minuteRange.map(minute => ((minute + 1) % 60));
            setMinuteRange(newMinuteRange);
            setMinutes(newMinuteRange[newMinuteRange.length - 1].toString().padStart(2, "0"));
          } else {
            setMinutes(minuteRange[currentIndex + 1].toString().padStart(2, "0"));
          }
        }
      } else if (type === "ampm") {
        const periods = ["AM", "PM"];
        const currentIndex = periods.indexOf(ampm);
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
          const newAmPm = periods[(currentIndex + (e.key === "ArrowUp" ? -1 : 1) + periods.length) % periods.length];
          setAmPm(newAmPm);
        }
      }
    }
  };

  return (
    <div className="relative" ref={timePickerRef}>
      <input
        type="text"
        value={selectedTime}
        readOnly
        onClick={toggleTimePicker}
        className="block w-full px-3 py-2 border border-[#B6B6B6] rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#212529] cursor-pointer pr-8"
      />

      <FaRegClock
        className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
        onClick={toggleTimePicker}
      />

      {showTimePicker && (
        <div className="absolute z-50 top-1/2 left-[100%] ml-2 transform -translate-y-40 bg-white border border-[#B6B6B6] rounded-lg p-4 shadow-lg flex gap-4">
          <div
            className="flex flex-col items-center outline-none"
            tabIndex={0}
            ref={hourRef}
            onKeyDown={(e) => handleArrowKeyPress(e, "hours")}
          >
            {hourRange.map((hour) => {
              const paddedHour = hour.toString().padStart(2, "0");
              return (
                <div
                  key={paddedHour}
                  onClick={() => handleHourSelection(paddedHour)}
                  className={`cursor-pointer py-2 px-3 rounded text-center w-[48px] ${hours === paddedHour ? "bg-[#0078C5] text-white" : "hover:bg-[#D4F1FF]"} ${focusedColumn === "hours" && hours === paddedHour ? "border-2 border-black" : ""}`}
                >
                  {paddedHour}
                </div>
              );
            })}
          </div>

          <div
            className="flex flex-col items-center outline-none"
            tabIndex={1}
            ref={minuteRef}
            onKeyDown={(e) => handleArrowKeyPress(e, "minutes")}
          >
            {minuteRange.map((minute) => {
              const paddedMinute = minute.toString().padStart(2, "0");
              return (
                <div
                  key={paddedMinute}
                  onClick={() => handleMinuteSelection(paddedMinute)}
                  className={`cursor-pointer py-2 px-3 rounded text-center w-[48px] ${minutes === paddedMinute ? "bg-[#0078C5] text-white" : "hover:bg-[#D4F1FF]"} ${focusedColumn === "minutes" && minutes === paddedMinute ? "border-2 border-black" : ""}`}
                >
                  {paddedMinute}
                </div>
              );
            })}
          </div>

          <div
            className="flex flex-col items-center outline-none"
            tabIndex={2}
            ref={ampmRef}
            onKeyDown={(e) => handleArrowKeyPress(e, "ampm")}
          >
            {["AM", "PM"].map((period) => (
              <div
                key={period}
                onClick={() => handlePeriodSelection(period)}
                className={`cursor-pointer py-2 px-3 rounded text-center w-[48px] ${ampm === period ? "bg-[#0078C5] text-white" : "hover:bg-[#D4F1FF]"} ${focusedColumn === "ampm" && ampm === period ? "border-2 border-black" : ""}`}
              >
                {period}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTimePicker;