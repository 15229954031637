import Prepare from "./components/Prepare/Preview";
import { Routes, Route, Navigate } from 'react-router-dom';
import Workflow from "./components/Workflow/Workflow";
import "./index.css";
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from "react-toastify";
import DataValidationGroupsList from "./components/Workflow/DataValidation/DataValidationRules/DataValidationGroupsList";
import CreateDataValidationRules from "./components/Workflow/DataValidation/DataValidationRules/CreateDataValidationRules";
import Dashboard from "./components/Dashboard/Dashboard";
import EditDataValidationRules from "./components/Workflow/DataValidation/DataValidationRules/EditDataValidationRules";
import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";
import VerifyEmail from "./components/Authentication/VerifyEmail";
import Settings from "./components/Settings/Settings";
import { ProtectedRoutes } from "./utils/ProtectedRoutes";
import Landing from "./components/Authentication/Landing";
import SetPassword from "./components/Authentication/SetPassword/SetPassword";
import ResendVerifyEmail from "./components/Authentication/ResendVerifyEmail/ResendVerifyEmail";
import { useAuth } from "./hooks/useAuth";

function App() {
  const { user } = useAuth();

  return (
    <div className="font-poppins">
      <ToastContainer />
      <Toaster />
      <Routes>
        <Route path="/" element={user?.token ? (<Navigate to="/prepare" />) : <Landing />} />
        <Route path="/login" element={user?.token ? (<Navigate to="/prepare" />) : <Login />} />
        <Route path="/sign-up" element={user?.token ? (<Navigate to="/prepare" />) : <Register />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/resend-verify-email" element={<ResendVerifyEmail />} />
        <Route path="/verify-account/:uuid/:token" element={<SetPassword />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/prepare" element={<Prepare />} />
          <Route path="/workflow" element={<Workflow />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/data-validation-groups/list" element={<DataValidationGroupsList />} />
          <Route path="/data-validation-rules/create" element={<CreateDataValidationRules />} />
          <Route path="/data-validation-rules/edit/:groupId" element={<EditDataValidationRules />} />
        </Route>
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
}


export default App;