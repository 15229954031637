import React from 'react';
import { useNavigate } from 'react-router-dom';
import homeBg from "../../assets/homeBg.png";
import Nav from '../ReUseable/Navbar/Nav';
import mail from "../../assets/mail.svg";
import close from "../../assets/closeIcon.svg";

const VerifyEmail = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  }

  return (
    <div className='bg-[#E5F8F8] h-screen w-full relative'>
      <Nav showWorkspaceDetails={false} />

      <div className='flex w-full h-full'>
        <div className='h-full w-[39.7%] pt-[50px] flex items-center justify-center z-20 relative'>
          <img src={homeBg} alt="img" className='w-full absolute left-0 top-0 h-screen' />
          <div className='px-[48px] text-white absolute'>
            <div className='font-medium text-5xl pb-5 flex justify-center'>Ignitho<br /> Data Accelerator</div>
            <div className='text-lg py-5 text-center'>Unlock the power of data acceleration with IDA</div>
            <div className='pt-5 pb-3 text-sm text-center'>The cutting-edge data accelerator solution designed to supercharge your organization's data-driven operations. </div>
            <div className='py-3 text-sm text-center'>Whether you're a small startup or a large enterprise, revolutionizes the way you handle data, empowering you to make faster, smarter decisions.</div>
            <div className='py-3 text-sm text-center'>Experience the future of data acceleration with Ignitho Data Product. Contact us today for a personalized demo and see how Ignitho Data Product can transform your organization's data capabilities.</div>
          </div>
        </div>

        <div className='w-[60.3%]'>
          <div className='flex items-center justify-center h-full pt-[50px]'>
            <div className='w-[352px] pb-10 pt-5 bg-white flex gap-5 rounded-[20px] items-center flex-col drop-shadow-[0_0_30px_#00578F1A]'>
              <img src={close} alt="close" className='self-end mx-5 cursor-pointer'onClick={handleBack} />
              <img src={mail} alt="email" className='w-[72px] h-[72px]' />
              <div className='text-blue-1 text-xl text-center'>
                Please verify your email
              </div>
              <div className='text-center font-normal text-sm px-5'>
                <div>Thank you for registering!</div>
                <div>
                  An email has been sent to the <br/>provided email address.
                  Please verify<br/> your email to complete the <br/>registration process.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
