import React from 'react';
import workflow from "../../../assets/workflow.svg";
import prepare from "../../../assets/prepare.svg";
import validation from "../../../assets/validation.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import bgWhite from "../../../assets/whiteBgSidebar.svg";
import settings from "../../../assets/settings.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { groupId } = useParams();

  return (
    <div className='fixed top-0 bottom-0 bg-[#D4F1FF] w-[50px] z-20'>
      <div className='flex flex-col gap-5 mt-[75px] items-center justify-center'>
        {location.pathname === '/prepare' ?
          <div className='relative flex items-center w-full justify-center group'>
            <img src={prepare} alt="prepare" className='absolute w-5 h-5 object-cover z-10' />
            <img src={bgWhite} alt="bg" />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Prepare
            </span>
          </div>
          :
          <div className='relative flex items-center w-full justify-center group'>
            <img src={prepare} alt="prepare" className='cursor-pointer absolute w-5 h-5 object-cover z-10' onClick={() => navigate('/prepare')} />
            <img src={bgWhite} alt="bg" className='cursor-pointer invisible relative' />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Prepare
            </span>
          </div>
        }

        {location.pathname === '/workflow' ?
          <div className='relative flex items-center w-full justify-center group'>
            <img src={workflow} alt="prepare" className='absolute w-5 h-5 object-cover z-10' />
            <img src={bgWhite} alt="bg" />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Workflow
            </span>
          </div>
          :
          <div className='relative flex items-center w-full justify-center group'>
            <img src={workflow} alt="prepare" className='cursor-pointer absolute w-5 h-5 object-cover z-10' onClick={() => navigate('/workflow')} />
            <img src={bgWhite} alt="bg" className='cursor-pointer invisible' />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Workflow
            </span>
          </div>}

        {location.pathname === '/data-validation-groups/list' || location.pathname === '/data-validation-rules/create' || location.pathname === `/data-validation-rules/edit/${groupId}` ?
          <div className='relative flex items-center w-full justify-center group'>
            <img src={validation} alt="prepare" className='absolute w-5 h-5 object-cover z-10' />
            <img src={bgWhite} alt="bg" />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Validation
            </span>
          </div>
          :
          <div className='relative flex items-center w-full justify-center group'>
            <img src={validation} alt="prepare" className='cursor-pointer absolute w-5 h-5 object-cover z-10' onClick={() => navigate('/data-validation-groups/list')} />
            <img src={bgWhite} alt="bg" className='cursor-pointer invisible' />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Validation
            </span>
          </div>}

        {location.pathname === '/settings' ?
          <div className='relative flex items-center w-full justify-center group'>
            <img src={settings} alt="prepare" className='absolute w-5 h-5 object-cover z-10' />
            <img src={bgWhite} alt="bg" />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Settings
            </span>
          </div>
          :
          <div className='relative flex items-center w-full justify-center group'>
            <img src={settings} alt="prepare" className='cursor-pointer absolute w-5 h-5 object-cover z-10' onClick={() => navigate('/settings')} />
            <img src={bgWhite} alt="bg" className='cursor-pointer invisible' />
            <span className='group-hover:visible invisible up-arrow text-sm'>
              Settings
            </span>
          </div>}
      </div>
    </div>
  )
}

export default Sidebar


