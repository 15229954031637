import React, { useEffect, useRef, useState } from 'react';
import downArrow from "../../../assets/downArrowWhite.svg";
import addBlue from "../../../assets/addBlue.svg";
import search from "../../../assets/searchDropdown.svg";
import { useAuth } from '../../../hooks/useAuth';
import { API } from '../../../global';
import axios from 'axios';
import editIcon from "../../../assets/edit.svg";

const WorkspaceDropdown = ({ options, setAddWorkspaceModal, setEditWorkspaceModal,
  selectedValue, placeholder, width, dropdownWidth, setWorkspaceName,
  setDescription, setPrivateWorkspace, setSelectedUsers, setWorkspaceId, setWorkspaceUsers }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { user, updateUser } = useAuth();

  const filteredOptions = options.length > 0 ? options.filter(option =>
    option?.workspace_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  ) : [];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, so close it
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelection = async (option) => {
    setSelected(option.workspace_name);
    setDropdownOpen(false);
    try {
      const updatedUser = await axios.put(`${API}/users/edit_users/${user._id}`, { current_workspace: option._id }, { "Content-Type": "application/json" });
      console.log(updatedUser);
      const userUpdated = { ...user, current_workspace: option };
      updateUser(userUpdated);
    } catch (error) {
      console.log(error);
    }
  }

  const handleAddWorkspace = () => {
    setAddWorkspaceModal(true);
    setDropdownOpen(false);
  }

  const handleEditWorkspace = async (event, option) => {
    event.stopPropagation();
    setEditWorkspaceModal(true);
    setDropdownOpen(false);
    setWorkspaceName(option.workspace_name);
    setDescription(option.description);
    setPrivateWorkspace(option.private_workspace);
    setWorkspaceId(option._id);
    try {
      const users = await axios.get(`${API}/admin/workspace/users/get_users/${option._id}`,
        { "Content-Type": "application/json" });
      setWorkspaceUsers(users.data.data);
      setSelectedUsers(users.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={`${width} font-medium relative cursor-pointer`} ref={dropdownRef}>
      <div className={`bg-[#339DCD] px-2.5 py-1.5 text-sm text-center text-white flex items-center justify-between rounded-md border border-white`} onClick={() => setDropdownOpen(!dropdownOpen)}>
        <span className='overflow-hidden whitespace-nowrap me-3 text-ellipsis'>
          {user.current_workspace && Object.keys(user.current_workspace).length !== 0
            ? user.current_workspace.workspace_name
            : options.length > 0
              ? options[0].workspace_name
              : placeholder}
        </span>
        <img src={downArrow} alt="down-arrow" />
      </div>
      <div className={`bg-white absolute border border-lite-grey-4 right-0 rounded-md z-20 ${dropdownOpen ? 'block' : 'hidden'}`}>
        <ul className={`${dropdownWidth} z-10 bg-white max-h-60 overflow-y-auto rounded-t-lg`}>
          <li className='text-sm py-2.5 px-2.5 flex text-lite-black gap-2 border-b-lite-grey-4 border' onClick={() => ''}>
            <img src={search} alt="search" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search for a Workspace"
              className="placeholder:text-lite-grey-3 outline-none"
            />
          </li>
          {filteredOptions?.map((option, index) => (
            <li key={index} className={`text-sm px-2.5 py-2.5 flex justify-between overflow-hidden text-ellipsis text-lite-black whitespace-nowrap ${(selected.workspace_name === option.workspace_name || selectedValue.workspace_name === option.workspace_name) ? 'bg-[#D4F1FF]' : 'hover:bg-lite-grey-1'}`} onClick={() => handleSelection(option)}>
              {option.workspace_name}
              {user?.current_role_key === 'ida_admin' && option.workspace_name !== 'Default workspace' ?
                <img src={editIcon} alt="edit" className="cursor-pointer" onClick={(e) => handleEditWorkspace(e, option)} /> : ''}
            </li>
          ))}
        </ul>
        {user?.current_role_key === 'ida_admin' ?
          <div className='text-sm py-2.5 px-2.5 z-20 flex justify-center rounded-b-lg bg-white border border-t-lite-grey-4 text-lite-black'>
            <span className='cursor-pointer flex gap-1' onClick={() => handleAddWorkspace()}>
              <img src={addBlue} alt="add" />
              <span className='text-blue-1 font-semibold text-[10px] cursor-pointer'>Add Workspace</span>
            </span>
          </div> : ''}
      </div>
    </div>
  )
}

export default WorkspaceDropdown
