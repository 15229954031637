import React, { useState } from 'react';

import { PiArrowRightBold } from "react-icons/pi";

import backWhite from "../../../assets/backWhite.svg";
import TableNameList from '../../ReUseable/TableNameList/TableNameList';

const DestinationTables = (props) => {
  const { handleDataValidationPreview, handleBackFromDestinationTables, setSkipDataValidation, sheetNames } = props;
  const [selectedTables, setSelectedTables] = useState([]);

  const handleContinue = () => {
    handleDataValidationPreview();
  }

  const handleBack = () => {
    handleBackFromDestinationTables();
  }

  return (
    <div className="bg-white mx-5">
      <div className="mb-4 flex justify-between items-center text-sm">
        <div className="text-lg font-medium text-[#212529]">Select Destination Table</div>
        <div className="flex gap-4 items-center">
          <label className="text-gray-600 cursor-pointer">
            <input type="checkbox" className="mr-2 align-middle" onChange={(e) => setSkipDataValidation(e.target.checked)} />
            <span className='align-middle'>Skip Data Validation</span>
          </label>
          <button
            className="bg-[#0078C5] hover:bg-[#00578F] text-white px-[10px] py-[5px] rounded-md flex items-center gap-2"
            onClick={handleContinue}
          >
            <PiArrowRightBold color="#fff" size={15} />
            Continue
          </button>
          <button className="flex items-center gap-2 bg-[#BFBFBF] px-[10px] py-[5px] hover:bg-[#A5A5A5] back-btn text-white rounded-md text-sm"
            onClick={handleBack}
          >
            <img src={backWhite} alt="back" className='back-btn-img' />
            Go Back
          </button>
        </div>
      </div>

      <TableNameList
        tableNames={sheetNames}
        selectedTables={selectedTables}
        setSelectedTables={setSelectedTables}
        title="Table Name"
      />
    </div>
  );
};

export default DestinationTables;
