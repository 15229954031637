import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from "../../global";
import Nav from '../ReUseable/Navbar/Nav';
import homeBg from "../../assets/homeBg.png";
import RegisterModal from '../ReUseable/Modals/RegisterModal';
import validator from "email-validator";
import toast from "react-hot-toast";

const Landing = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('Email is required');
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    if (e.target.id === "email") {
      setEmail(e.target.value);
      setEmailError(false);
    }
  };

  const validateEmail = (email) => {
    return validator.validate(email)
  };

  const validate = () => {
    let error = false;
    if (!email) {
      error = true;
      setEmailError(true);
    }
    if (email && !validateEmail(email)) {
      setEmailError(true);
      error = true;
      setEmailErrorMsg('Email Id is not valid')
    }

    return error;
  };

  const handleGetTenant = (e) => {
    e.preventDefault();
    if (!validate()) {
      setLoading(true);
      setEmailError(false);
      fetch(`${API}/admin/tenant/get-tenant-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      })
        .then((res) => res.json())
        .then((response) => {
          setLoading(false);
          if (response.success) {
            if (response.result) {
              navigate('/login');
              localStorage.setItem('tenantId', response.result._id);
              localStorage.setItem('emailData', email)
            }
          } else {
            if (response.error.msg === "Tenant Not Found, please contact admin") {
              setShowPopUp(true);
            } else {              
              toast.error(response.error.msg);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong. Please try again later");
          setLoading(false);
        });
    };
  }

  const handleStartSignUp = () => {
    if (email) {
      localStorage.setItem('emailData', email)
    }
    navigate('/sign-up');
  }

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Clicked outside the modal, so close it
      setShowPopUp(false);
    }
  };

  const handleClosePopup = () => {
    setShowPopUp(false);
  }

  const navigateToSignUp = () => {
    localStorage.removeItem("emailData");
    navigate("/sign-up");
  }

  return (
    <div className={`${showPopUp ? 'overflow-hidden' : ''} bg-[#E5F8F8] h-screen w-full relative`}>
      {/* navbar */}
      <Nav showWorkspaceDetails={false} />

      <div className='flex w-full h-full'>
        <div className='h-full w-[39.7%] flex items-center justify-center z-20 relative pt-[50px]'>
          <img src={homeBg} alt="img" className='w-full absolute left-0 top-0 h-screen' />
          <div className='px-[48px] text-white absolute'>
            <div className='font-medium text-5xl pb-5 flex justify-center'>Ignitho<br /> Data Accelerator</div>
            <div className='text-lg py-5 text-center'>Unlock the power of data acceleration with IDA</div>
            <div className='pt-5 pb-3 text-sm text-center'>The cutting-edge data accelerator solution designed to supercharge your organization's data-driven operations. </div>
            <div className='py-3 text-sm text-center'>Whether you're a small startup or a large enterprise, revolutionizes the way you handle data, empowering you to make faster, smarter decisions.</div>
            <div className='py-3 text-sm text-center'>Experience the future of data acceleration with Ignitho Data Product. Contact us today for a personalized demo and see how Ignitho Data Product can transform your organization's data capabilities.</div>
          </div>
        </div>

        <div className='w-[60.3%]'>
          <div className='flex items-center justify-center h-full pt-[50px]'>
            <div className='w-[352px] py-10 text-sm bg-white flex rounded-[20px] flex-col drop-shadow-[0_0_30px_#00578F1A]'>
              <div className='text-blue-1 text-xl text-center mb-6'>
                Login
              </div>
              <form onSubmit={(e) => handleGetTenant(e)} onKeyDown={(e) => e.key === 'Enter' ? handleGetTenant(e) : ''}>
                <div className='flex flex-col gap-5 mx-10 text-lite-black-3'>
                  <div className='flex flex-col gap-1.5'>
                    <label className='font-medium' htmlFor='email'>
                      E-Mail ID
                    </label>
                    <input type="email" placeholder="Enter your E-Mail ID" id="email" value={email}
                      className='!bg-[#F7F7F7] focus:!outline-0 focus:!border-[#86b7fe] focus:!border focus:!shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] !border-[#F7F7F7] !border px-3 py-2 rounded-md'
                      onChange={handleChange} autoFocus />
                    {emailError ? (
                      <div className='text-red text-xs'>{emailErrorMsg}</div>
                    ) : null}
                  </div>
                  <div className='flex justify-center gap-2.5'>
                    <button type="submit" className='bg-blue-1 text-white w-full rounded-md py-1.5 hover:bg-[#00578F]' disabled={loading} onClick={handleGetTenant}>
                      {loading ? "Loading..." : "Login"}
                    </button>
                  </div>
                </div>
              </form>
              <hr className='border-0 h-0.5 bg-[#C7C7C7] my-5 mx-10' />
              <span className='m-auto w-10 -mt-8 flex items-center justify-center bg-white'>or</span>
              <div className='text-center mb-3 mt-2'>
                Register your account
              </div>
              <div className='text-center'>
                <button className='border border-blue-1 text-blue-1 hover:bg-blue-1 hover:text-white w-20 rounded-md py-1.5' onClick={navigateToSignUp}>Register</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopUp ?
        <RegisterModal modalRef={modalRef} handleCancel={handleClosePopup} handleRegister={handleStartSignUp} /> : ''
      }
    </div>
  )
};

export default Landing
