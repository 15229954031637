import React, { useEffect, useState } from 'react';
import LeftArrow from "../../assets/leftArrow.svg";
import RightArrow from "../../assets/rightArrow.svg";
import disableLeft from "../../assets/disableLeft.svg";
import disableRight from "../../assets/disableRight.svg";
import { PiArrowRightBold } from "react-icons/pi";
// import { useNavigate } from "react-router-dom";
import TableData from '../Workflow/Preview/TableData';
import backWhite from "../../assets/backWhite.svg";
import Pagination from './Pagination/Pagination';

const FilePreview = ({ handlePreview, sheet, setSheet, sheetData, checkboxes,
  selectedValues, currentIndex, displayedElements, handleBackwardClick, handleForwardClick,
  setFileUploaded, setCheckboxes, setCheckAll, setTableShow, setSname }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = sheetData[sheet]?.slice(1).slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCurrentPage(1);    
  }, [sheet])

  const handleBackFromSheetsPreview = () => {
    setCheckboxes([]);
    setSname(true);
    setTableShow(false);
    setCheckAll(false);
  }

  return (
    <>
      <div className="ps-5 border-l-[1px] border-[#CCCCCC]">
        <div className="flex justify-between items-center mb-5">
          <span>Preview</span>
          {selectedValues.length === 1 ?
            <div className='flex gap-4'>
              <button className="flex items-center gap-2 bg-[#BFBFBF] px-[10px] py-[5px] hover:bg-[#A5A5A5] back-btn text-white rounded-md text-sm"
                onClick={() => {
                  handleBackFromSheetsPreview();
                }}>
                <img src={backWhite} alt="back" className='back-btn-img' />
                Go Back
              </button>
              <button className="flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm"
                onClick={() => {
                  handlePreview();
                  setFileUploaded(false)
                }}>
                <PiArrowRightBold color="#fff" size={15} />{" "}
                Continue
              </button>
            </div>
            :
            <div className='flex gap-4'>
              <button className="flex items-center gap-2 bg-[#BFBFBF] px-[10px] py-[5px] hover:bg-[#A5A5A5] back-btn text-white rounded-md text-sm"
                onClick={() => {
                  handleBackFromSheetsPreview();
                }}>
                <img src={backWhite} alt="back" className='back-btn-img' />
                Go Back
              </button>
              <button className="flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm"
                onClick={() => {
                  handlePreview();
                  setFileUploaded(false)
                }}>
                <PiArrowRightBold color="#fff" size={15} />{" "}
                Continue
              </button>
            </div>
          }
        </div>
        <>
          <div className="text-sm flex justify-start">
            {selectedValues.length === 1 ? (
              <h5 className="mt-0 overflow-auto  bg-[#F9FAFB] border-x-[1px] border-t-[1px] border-[#DDDEE0] px-4 py-3 w-full rounded-t-lg font-semibold text-sm">
                Sheet Name : {sheet}
              </h5>
            ) : (
              <div className="flex">
                {displayedElements.map((s, i) => (
                  <div className='group relative'>
                    <label htmlFor={`${s}`}>
                      <h5
                        key={i}
                        className={`px-2 py-2.5 text-center rounded-t-lg w-28 border-x-[1px] border-t-[1px] border-[#DDDEE0] drop-item cursor-pointer font-medium ${s === sheet
                          ? "sheet_active"
                          : "bg-[#F9FAFB]"
                          }`}
                      >
                        <input
                          id={`${s}`}
                          name={`${s}`}
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded hidden"
                          checked={s === sheet}
                          value={s}
                          onChange={(e) => {
                            setSheet(e.target.value);
                          }}
                        />
                        {s}
                      </h5>
                    </label>
                    <span className='group-hover:visible invisible down-arrow text-sm'>
                      {s}
                    </span>
                  </div>
                ))}
              </div>
            )}

            {checkboxes.length <= 5 ? (
              " "
            ) : (
              <div className="flex justify-end w-full gap-5 items-center">
                <div className="flex gap-6">
                  <button
                    onClick={handleBackwardClick}
                    disabled={currentIndex === 0}
                  >
                    {currentIndex === 0 ? (
                      <img src={disableLeft} alt="left" />
                    ) : (
                      <img src={LeftArrow} alt="left" />
                    )}
                  </button>

                  <button
                    onClick={handleForwardClick}
                    disabled={
                      currentIndex ===
                      selectedValues.length - 5
                    }
                  >
                    {currentIndex ===
                      selectedValues.length - 5 ? (
                      <img src={disableRight} alt="right" />
                    ) : (
                      <img src={RightArrow} alt="right" />
                    )}
                  </button>
                </div>
                {/* dropdown */}
                {/* <div className="text-[14px] group">
            <button
              className="px-1.5 py-1 rounded-sm border-[1px] flex items-center w-24 justify-between"
              onClick={handleMouse}
            >
              Sheet <img src={DownArrow} alt="" />
            </button>
            {isDropdownVisible && (
              <div className="relative right-32 top-2 text-sm text-[#212529] group-hover:flex">
                <div className="bg-white absolute w-56 h-fit border-[1px] border-[#707070] rounded-md z-20 py-1">
                  {SheetNames.map((n, i) => (
                    <ul key={i}>
                      <li
                        className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm"
                        onClick={() =>
                          setDropdownVisible(false)
                        }
                      >
                        {n}
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            )}
          </div> */}
              </div>
            )}
          </div>
        </>
        <TableData sheet={sheet} sheetData={sheetData} currentItems={currentItems} />
      </div>
      <Pagination data={sheetData[sheet].slice(1)} currentPage={currentPage} setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} indexOfFirstItem={indexOfFirstItem}
        indexOfLastItem={indexOfLastItem} sheet={sheet} />
    </>
  )
}

export default FilePreview