import React, { useState } from 'react';

import bulb from "../../../assets/bulb.svg";

const MappingPopover = ({ obj, index, setSourceTargetMappedArr, updateTargetField }) => {
  const [mappingRecommendationsOpen, setMappingRecommendationsOpen] = useState(false);

  const handleMappingRecommendations = (id) => {
    setMappingRecommendationsOpen(!mappingRecommendationsOpen);
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mappingRecommendations: true } : { ...obj, mappingRecommendations: false }))
  }

  return (
    <>
      {obj.target_field?.prob_score >= 0.8 ?
        <img src={bulb} alt="bulb" className='mb-1 cursor-default' />
        :
        <div className='relative'>
          <BulbIcon mappingRecommendations={obj.target_field_arr?.length} mappingRecommendationsOpen={mappingRecommendationsOpen} setMappingRecommendationsOpen={setMappingRecommendationsOpen} handleMappingRecommendations={handleMappingRecommendations} obj={obj} />
          {mappingRecommendationsOpen && obj.mappingRecommendations ?
            <div className='absolute bg-[#fff] -top-[7px] w-64 cursor-pointer h-64 z-10 py-[15px] px-[15px] right-10 border border-[#616161] rounded-[5px] overflow-y-auto'>
              <div className='text-sm font-semibold'>Mapping recommendations</div>
              <hr className='border border-[#DDDEE0] my-[10px]' />
              <div className='text-[12px] font-semibold mb-1'>SOURCE FIELD</div>
              <div className='text-[12px] text-[#212529] mb-2'>{obj.source_field}</div>
              {
                obj.target_field_arr.map((item, i) => (
                  <div className='mb-1'>
                    <label htmlFor={i} className='text-[#212529] font-normal text-[12px]'><input type="radio" name={`map-recommendations${index}`} id={i} value={item.predicted_label} onChange={(e) => updateTargetField(e, obj.id)} className='me-2' />{item.predicted_label}</label>
                  </div>
                ))
              }
            </div> : ''
          }
        </div>
      }
    </>
  )
}

export default MappingPopover

const BulbIcon = ({ mappingRecommendations, mappingRecommendationsOpen, setMappingRecommendationsOpen, handleMappingRecommendations, obj }) => {
  return (
    <div className='cursor-pointer' onClick={() => handleMappingRecommendations(obj.id)}>
      <svg id="Component_34_2" data-name="Component 34 – 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="31" height="25" viewBox="0 0 31 25">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_132" data-name="Rectangle 132" width="25" height="25" fill="#707070" />
          </clipPath>
        </defs>
        <g id="Component_13_3" data-name="Component 13 – 3">
          <g id="Group_62" data-name="Group 62" clipPath="url(#clip-path)">
            <path id="Path_31" data-name="Path 31" d="M0,183.928a.6.6,0,0,1,.639-.38c.633.007,1.267,0,1.9,0,.352,0,.552.169.582.462a.48.48,0,0,1-.46.572c-.73.02-1.461.012-2.192,0A.519.519,0,0,1,0,184.221Z" transform="translate(0 -171.579)" fill="#707070" />
            <path id="Path_32" data-name="Path 32" d="M339.043,184.246a.6.6,0,0,1-.64.38c-.625-.007-1.251,0-1.876,0a.562.562,0,0,1-.53-.235.511.511,0,0,1,.385-.8c.73-.027,1.461-.013,2.192-.006a.521.521,0,0,1,.469.368Z" transform="translate(-314.043 -171.605)" fill="#707070" />
            <path id="Path_33" data-name="Path 33" d="M184.6,0a.6.6,0,0,1,.38.639c-.007.625,0,1.25,0,1.876a.569.569,0,0,1-.214.516.513.513,0,0,1-.819-.371c-.027-.73-.013-1.461-.006-2.191A.518.518,0,0,1,184.31,0Z" transform="translate(-171.957)" fill="#707070" />
            <path id="Path_34" data-name="Path 34" d="M87.481,99.812c-.439,0-.88-.025-1.318,0a1.677,1.677,0,0,1-1.8-1.749c-.021-.674-.027-1.351,0-2.025a3.633,3.633,0,0,0-1.436-3.058A7.4,7.4,0,0,1,81,90.65a7.277,7.277,0,1,1,13.682-4.385,6.8,6.8,0,0,1-2.5,6.567,3.946,3.946,0,0,0-1.55,2.664c-.054.5-.023,1.007-.027,1.511,0,.407,0,.813,0,1.22a1.6,1.6,0,0,1-1.587,1.584c-.512.005-1.025,0-1.538,0m2.076-4.176a.865.865,0,0,0,.023-.1,4.931,4.931,0,0,1,.818-2.268,7.377,7.377,0,0,1,1.335-1.412A6.2,6.2,0,0,0,92.009,83a6.142,6.142,0,0,0-8.47-.454,6.055,6.055,0,0,0-2.207,5.8,5.783,5.783,0,0,0,2.179,3.77,4.685,4.685,0,0,1,1.672,2.315c.118.384.172.788.259,1.2ZM85.395,96.7c0,.428,0,.833,0,1.237a2.2,2.2,0,0,0,.032.339.508.508,0,0,0,.576.5c.293,0,.585,0,.878,0,.7,0,1.4,0,2.1,0,.35,0,.557-.141.571-.437.025-.54.007-1.083.007-1.636Z" transform="translate(-74.982 -74.821)" fill="#707070" />
            <path id="Path_35" data-name="Path 35" d="M54.223,53.523a1.238,1.238,0,0,1,.311.179q.726.7,1.429,1.426a.509.509,0,0,1-.061.805.489.489,0,0,1-.745-.063q-.69-.737-1.377-1.478a.48.48,0,0,1-.094-.562.543.543,0,0,1,.536-.307" transform="translate(-50.143 -50.033)" fill="#707070" />
            <path id="Path_36" data-name="Path 36" d="M292.37,293.909a2.538,2.538,0,0,1-.5-.292c-.444-.418-.87-.855-1.3-1.29a.5.5,0,0,1-.046-.756.48.48,0,0,1,.7-.032c.515.5,1.019,1.005,1.52,1.517a.469.469,0,0,1,.074.545.339.339,0,0,1-.421.239l-.029.069" transform="translate(-271.471 -272.4)" fill="#707070" />
            <path id="Path_37" data-name="Path 37" d="M293.3,55.715a1.174,1.174,0,0,1-.174.309q-.677.7-1.378,1.382a.536.536,0,0,1-.908-.167.629.629,0,0,1,.125-.483c.469-.507.968-.986,1.459-1.473a.478.478,0,0,1,.562-.1.534.534,0,0,1,.313.537" transform="translate(-271.9 -51.536)" fill="#707070" />
            <path id="Path_38" data-name="Path 38" d="M54.215,293.916c-.24-.036-.429-.1-.512-.32a.514.514,0,0,1,.142-.577c.472-.469.939-.943,1.414-1.408a.513.513,0,1,1,.723.725c-.46.469-.923.936-1.4,1.391a1.447,1.447,0,0,1-.37.19" transform="translate(-50.173 -272.427)" fill="#707070" />
            <path id="Path_39" data-name="Path 39" d="M184.569,119.791a4.677,4.677,0,0,1,4.531,4.61c0,.441-.3.686-.691.565a.492.492,0,0,1-.347-.525,3.587,3.587,0,0,0-.889-2.318,3.655,3.655,0,0,0-2.734-1.3.515.515,0,0,1-.481-.778c.1-.2.262-.262.612-.256" transform="translate(-171.914 -111.979)" fill="#707070" />
          </g>
        </g>
        <g id="Group_94" data-name="Group 94" transform="translate(-1140 -393)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="7" cy="7" r="7" transform="translate(1157 395)" fill="#0083bf" />
          <text id="_4" data-name="4" transform="translate(1161 405)" fill="#fff" fontSize="8" fontFamily="Poppins-Medium, Poppins" fontWeight="500"><tspan x="0" y="0">{mappingRecommendations}</tspan></text>
        </g>
      </svg>
    </div>

  )
}

