import React, { useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import api from '../../../assets/ApiIcon.svg'
import ApiTablePreview from "../../ReUseable/ApiTablePreview";
import { API } from "../../../global";
import axios from "axios";
import loadingGif from "../../../assets/loading.gif";
import apiImg from "../../../assets/api.svg";
import SheetLists from "../../ReUseable/SheetLists";
import toast from "react-hot-toast";

const ConnectToApi = ({ setSourceType, clearStates, tableshow, setSheetData, setSheet, setSheetNames,
   setPreviewStepper, setSname, sname, checkboxes, setSelectedValues, setSingleSheet, setTableshow, 
   setCheckAll, setCheckboxes, checkAll, SheetNames, sheetData, sheet, selectedValues,setThankyou }) => {
  const [hibeApiform, setHideApiform] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [showApiName, setShowApiName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [rawDataFromApi, setRawDataFromApi] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [apiUrlError, setApiUrlError] = useState(false);
  const [apiTokenError, setApiTokenError] = useState(false);

  const handleFinish = () => {
    setThankyou(true);
  };

  const handleCancel = () => {
    setSourceType(true);
  };

  const validate = () => {
    let error = false;
    if (!apiUrl) {
      error = true;
      setApiUrlError(true);
    } if (!apiToken) {
      error = true;
      setApiTokenError(true);
    }
    return error;
  };

  const handleConnectToApi = async () => {
    if (!validate()) {
      setHideApiform(true);
      setLoading(true);
      setShowApiName(true);
      const apiDetails = {
        apiUrl: apiUrl,
        apiToken: apiToken
      }
      const connectApiResponse = await axios.post(`${API}/connect_api`, apiDetails, {
        "Content-Type": 'application/json'
      });
      if (!connectApiResponse.data.success) {
        toast.error('Sorry, something went wrong, please try again');
        setHideApiform(false);
        setLoading(false);
        setShowApiName(false);
      }
      else {
        setRawDataFromApi(connectApiResponse.data.result);
        const object = {
          data: connectApiResponse.data.result
        }
        const transformedObject = transformObject(object);
        setSheetData(transformedObject);
        setSheetData(transformedObject);
        setSheet("data");
        setSheetNames(["data"]);
        setSname(true);
        setLoading(false);
        setPreviewStepper(true);
      }
    }
  }

  const transformObject = (obj) => {
    const transformedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        transformedObj[key] = [];

        // If the array in the original object is not empty
        if (obj[key].length > 0) {
          // Extract keys
          const keysSet = new Set();

          // Extract keys from all objects in the array
          obj[key].forEach(item => {
            Object.keys(item).forEach(k => keysSet.add(k));
          });

          const keys = Array.from(keysSet);
          transformedObj[key].push(keys);

          // Extract values
          obj[key].forEach(item => {
            const values = keys.map(k => item[k]);
            transformedObj[key].push(values);
          });

          // Extract values
          obj[key].forEach(item => {
            const values = keys.map(k => item[k] !== undefined ? item[k] : '');
            // const values = Object.values(item);
            transformedObj[key].push(values);
          });
        } else {
          // If the array in the original object is empty, add an empty array for that key
          transformedObj[key].push([]);
        }
      }
    }
    return transformedObj;
  }

  const handleChange = (e) => {
    if (e.target.id === "apiUrl") {
      setApiUrl(e.target.value);
      setApiUrlError(false);
    }
    if (e.target.id === "apiToken") {
      setApiToken(e.target.value);
      setApiTokenError(false);
    }
  };

  const handleSubmit = () => {
    setSelectedValues(checkboxes);
    setSingleSheet(true);
    setTableshow(true);
    setSname(false);
    setSheet(checkboxes[0]);
    setShowApiName(false);
  };

  const handleSingleSheet = () => {
    setSingleSheet(true);
    setTableshow(true);
    setSname(false);
  };

  const handleSelectAll = (checked) => {
    setCheckAll(!checkAll);
    if (checked) {
      setCheckboxes(SheetNames);
    } else {
      setCheckboxes([]);
    }
  };

  const handleCheckboxChange = (value, checked) => {
    if (checked) {
      setCheckboxes([...checkboxes, value]);
    } else {
      setCheckboxes(checkboxes.filter((item) => item !== value));
    }
  };

  const displayedElements = selectedValues.slice(
    currentIndex,
    currentIndex + 7
  );

  const handleForwardClick = () => {
    if (currentIndex < selectedValues.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBackwardClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="flex mt-2 connect-db-container">
      {!hibeApiform ? (
        <div className="flex connect-form my-10">
          <div className="connect-db mr-5">
            <div className="font-medium text-lg ">Connect</div>
            <div className="flex flex-col form-container gap-2 font-medium text-sm mb-3">
              <label htmlFor="api-url" className="mt-2">
                API URL
              </label>
              <input
                type="text" id="apiUrl"
                value={apiUrl}
                onChange={handleChange}
                placeholder="API URL"
                className="border border-1 border-[#B6B6B6] w-full px-3 py-2 rounded-lg text-[#212529] outline-0"
              />
              {apiUrlError ? (
                <div className='text-red text-xs'>Api url is required</div>
              ) : null}

              <label htmlFor="username" className="mt-2">
                API Token
              </label>
              <input
                type="text" id="apiToken"
                value={apiToken}
                onChange={handleChange}
                placeholder="API Secret/Key"
                className="border border-1 border-[#B6B6B6] w-full px-3 py-2 rounded-lg text-[#212529] outline-0"
              />
              {apiTokenError ? (
                <div className='text-red text-xs'>Api token is required</div>
              ) : null}

              <div className="flex justify-end gap-2 mb-3 mt-2">
                <button
                  type="button"
                  className="bg-[#BFBFBF] px-3 py-1 w-20 text-white rounded text-sm hover:bg-[#A5A5A5]"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="bg-[#0062A1] px-3 py-1 w-20 text-white rounded text-sm hover:bg-[#0062A1]"
                  onClick={() => handleConnectToApi()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className="px-5 flex flex-col flex-2 border-l-[1px] border-[#CCCCCC] instruction-section ">
            <div className="">
              {" "}
              <div className="font-medium text-lg ">Instructions</div>
              <div className="text-sm font-normal text-[#212529] mt-2 flex flex-col gap-7">
                <p>
                  • Enter the API URL provided by your API provider in the "API URL" field.
                </p>
                <p>
                  • Enter the API Token provided by your API provider in the "API Token" field.
                </p>
                <p>
                  • Ensure that the API URL and API Token have been entered correctly by double-checking the values entered in each field.
                </p>
                <p>
                  • Once you have confirmed that the API URL and API Token have been entered correctly, click the "Next" button to proceed.
                </p>
                <p>
                  • If there are any errors or issues with the form submission, review the error message and make any necessary corrections to the API URL or API Token fields.
                </p>

              </div>
            </div>
          </div>
        </div>
      ) : ''}

      {showApiName ?
        <div className="my-10">
          <div className="font-medium text-lg text-lite-black">
            Connect
          </div>
          <div className="p-5 flex items-center my-5 gap-9 h-20 bg-white rounded-lg shadow-[0px_0px_10px_0px_rgba(0,0,0,0.16)]">
            <span>
              <img src={apiImg} alt="db" className='w-10 h-10 object-cover' />
            </span>
            <div className="flex flex-col text-xs text-lite-black font-semibold">
              New API
              <span>JSON</span>
            </div>
            <span className="ml-24 xl:ml-36" onClick={() => clearStates()}>
              <VscChromeClose color="#707070" fontSize="15px" className='cursor-pointer' />
            </span>
          </div>
        </div>
        : ''}

      {tableshow ?
        <div className="my-10 z-20">
          <div className="text-lg font-medium text-lite-black">
            Connect
          </div>
          <div className="flex justify-between mt-5 p-5 z-30 h-20 gap-[90px] bg-white rounded-lg hover:w-fit drop-shadow-[0_0_10px_#00000029] overflow-hidden transition-all w-20 delay-[200ms]">
            <div className='flex gap-5 items-center'>
              <img src={apiImg} alt="db" className='w-10 h-10 object-cover' />
              <div className="flex flex-col text-xs text-lite-black font-semibold">
                New API
                <span>JSON</span>
              </div>
            </div>
            <div className='flex items-center' onClick={() => clearStates()}>
              <VscChromeClose color="#707070" fontSize="15px" className="cursor-pointer" />
            </div>
          </div>
        </div>
        : ''}

      {loading ?
        <div className="flex-1 my-10 border-l ms-5 border-[#CCCCCC] h-80">
          <div className="px-5 text-lg font-medium">
            Preview
          </div>
          <div className="flex justify-center flex-col items-center pt-20">
            <img src={loadingGif} alt="uploading" className="w-[50px] h-[65px]" />
            <h5 className="analyse mt-5">Analysing....</h5>
          </div>
        </div> : sname ?
          <div className="flex-1 ms-5 my-10">
            <SheetLists
              checkboxes={checkboxes}
              handleSubmit={handleSubmit} handleSingleSheet={handleSingleSheet}
              handleSelectAll={handleSelectAll}
              handleCheckboxChange={handleCheckboxChange}
              setSheet={setSheet} fileType="MySQL" icon={apiImg} setSingleSheet={setSingleSheet}
              sheetNames={SheetNames}
              checkAll={checkAll} />
          </div>
          : ''}

      {tableshow ?
        <div className="z-0 absolute left-[11rem] right-5 pb-5 my-10">
          <ApiTablePreview handleCancel={handleCancel} handleFinish={handleFinish} fileName="New API"
            fileType="JSON" icon={api} rawDataFromApi={rawDataFromApi} setUploading={setUploading}
            sheetData={sheetData} sheet={sheet} selectedValues={selectedValues}
            setSelectedValues={setSelectedValues} checkboxes={checkboxes} setCheckboxes={setCheckboxes}
            setSname={setSname} setShowApiName={setShowApiName} displayedElements={displayedElements}
            handleForwardClick={handleForwardClick} handleBackwardClick={handleBackwardClick}
            setCheckAll={setCheckAll} setTableshow={setTableshow} currentIndex={currentIndex}
            setSingleSheet={setSingleSheet} setThankyou={setThankyou}
          />
        </div>
        : ''
      }

      {uploading ?
        <div className="my-10 w-full flex justify-center">
          <div className="flex justify-center flex-col mt-10 items-center">
            <img src={loadingGif} alt="uploading" className="w-[50px] h-[65px]" />
            <h5 className="analyse mt-5">Uploading Data....</h5>
          </div>
        </div> : ''
      }

    </div>
  )
}

export default ConnectToApi