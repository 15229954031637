import React from 'react';
// import warningIcon from "../../../assets/warningIcon.svg";
import warning from "../../../assets/warningYellow.svg";
import closeIcon from "../../../assets/closeIcon.svg";

const DeleteModal = ({ setModalOpen, handleDelete, selectedRow, handleMapping, 
  showSkipData, deleteWorkspace, modalRef }) => {

  return (
    <div className='flex items-center justify-center w-full h-screen fixed top-0 left-0 backdrop-brightness-75 z-40'>
      {!showSkipData && !deleteWorkspace ?
        <div ref={modalRef} className='bg-[#ffffff] w-[520px] rounded-lg flex flex-col p-5 items-center'>
          <div className='flex justify-end w-full cursor-pointer'>
            <img src={closeIcon} alt="close" onClick={() => setModalOpen(false)} />
          </div>
          <img src={warning} alt="warning" className='w-[60px] h-[54px] ' />
          <div className='text-[#212529] text-2xl font-medium my-2.5'>Are you sure ?</div>
          <div>Do you really want to delete this item?</div>
          <div>This process cannot be undone</div>
          <div className='flex mt-2.5 gap-2.5'>
            <button className='py-1.5 px-2 border-none bg-[#BFBFBF] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => setModalOpen(false)}>Cancel</button>
            <button className='py-1.5 px-2 border-none bg-[#FF0900] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleDelete(selectedRow, handleMapping)}> Delete</button>
          </div>
        </div>
        : !deleteWorkspace ?
          <div className='bg-[#ffffff] w-[520px] rounded-lg flex flex-col p-5 items-center'>
            <div className='flex justify-end w-full cursor-pointer'>
              <img src={closeIcon} alt="close" onClick={() => setModalOpen(false)} />
            </div>
            <img src={warning} alt="warning" className='w-[60px] h-[54px]' />
            <div className='text-center'>In some of the sheets, you haven't performed a data validation check.</div>
            <div className='text-center'> Do you want to skip data validation?</div>
            <div className='flex mt-2.5 gap-2.5'>
              <button className='py-1.5 px-6 border-none bg-[#BFBFBF] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => setModalOpen(false)}>No</button>
              <button className='py-1.5 px-6 border-none bg-[#0078C5] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleDelete()}> Yes</button>
            </div>
          </div> :
          deleteWorkspace ?
            <div className='bg-[#ffffff] w-[520px] rounded-lg flex flex-col p-5 items-center'>
              <div className='flex justify-end w-full cursor-pointer'>
                <img src={closeIcon} alt="close" onClick={() => setModalOpen(false)} />
              </div>
              <img src={warning} alt="warning" className='w-[60px] h-[54px]' />
              <div className='text-center mt-5'>Do you really want to delete this workspace?</div>
              <div className='text-center'>This process cannot be undone</div>
              <div className='flex mt-5 gap-2.5'>
                <button className='py-1.5 px-6 border-none bg-[#BFBFBF] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => setModalOpen(false)}>Cancel</button>
                <button className='py-1.5 px-6 border-none bg-[#FF0900] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleDelete()}>Delete</button>
              </div>
            </div>
            : ''}
    </div >
  )
}

export default DeleteModal
