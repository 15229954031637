import React, { useEffect, useRef, useState } from 'react';
import downArrow from "../../../assets/DashboardAssets/angle-down.svg";

const DashboardDropdown = ({ options, selectedValue, width }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(selectedValue);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, so close it
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelection = (value) => {
    setSelected(value);
    setDropdownOpen(false);
  }

  return (
    <div className={`${width} font-medium relative`} ref={dropdownRef}>
      <div className={`bg-white px-2.5 py-1.5 text-sm  text-lite-black-3 flex items-center justify-between rounded-md border border-lite-grey`} onClick={() => setDropdownOpen(!dropdownOpen)}>
        {selected}
        <img src={downArrow} alt="down-arrow" />
      </div>
      <ul className={`border border-lite-grey rounded-md mt-1 ${width} z-10 absolute  bg-white max-h-80 overflow-y-auto ${dropdownOpen ? 'block' : 'hidden'}`}>
        {options.map((option, index) => (
          <li key={index} className='text-sm border-b-lite-grey-9 last:border-none border-b px-2.5 py-1 overflow-hidden text-ellipsis text-lite-black-3 whitespace-nowrap hover:bg-lite-blue-2 hover:text-teal' onClick={() => handleSelection(option)}>{option}</li>
        ))}
      </ul>
    </div>
  )
}

export default DashboardDropdown

