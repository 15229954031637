import React, { useEffect, useState } from 'react';

// import { useDrag, useDrop } from 'react-dnd';
// import { uuid } from 'uuidv4';
// import { PiArrowRightBold } from "react-icons/pi";
import toast from 'react-hot-toast';
import Papa from "papaparse";
import { v4 as uuid } from 'uuid';
import { BlobServiceClient } from '@azure/storage-blob';
import axios from "axios";

import LeftArrow from "../../../assets/leftArrow.svg";
import RightArrow from "../../../assets/rightArrow.svg";
import disableLeft from "../../../assets/disableLeft.svg";
import disableRight from "../../../assets/disableRight.svg";
import downArrow from "../../../assets/downArrow.svg";
import loadingGif from "../../../assets/loading.gif";
import remove from "../../../assets/Remove.svg";
// import backWhite from "../../../assets/backWhite.svg";
// import bulbNotify from "../../assets/bulbNotify.svg";
import Success from '../../ReUseable/Success/Success';
import { useAuth } from '../../../hooks/useAuth';
import RunOrSchedule from "../RunOrSchedule/RunOrSchedule";
import { API } from '../../../global';
import MappingPopover from './MappingPopover';

const MappingTable = (props) => {
  const { sheet, sheetData, clearStates, setSheet, checkboxes,
    selectedValues, currentIndex, displayedElements, handleBackwardClick,
    handleForwardClick, sheetNames, setDataValidation,
    setReview, setReviewStepper, setAutoMappingStepper, setDataValidationStepper,
    setSkipDataValidation, setDeleteModalOpen, setDestinationStepper } = props;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [mappingDropdownVisible, setMappingDropdownVisible] = useState(false);
  const [sourceTargetMappedArr, setSourceTargetMappedArr] = useState([]);
  const [unmappedTargetFields, setUnmappedTargetFields] = useState([]);
  const [airbyteLoading, setAirbyteLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mappingScreen, setMappingScreen] = useState(true);
  const { user, updateUser } = useAuth();
  const [showRunOrSchedule, setShowRunOrSchedule] = useState(false);

  let source_target_mapped_arr = [];

  useEffect(() => {
    setSheet(selectedValues[0]);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let updatedTargetArr = [];
    if (sheetNames.length > 1) {
      const predictResponse = JSON.parse(localStorage.getItem(`MAPPING_API_RES`));
      // grouping the predicted column names from the API response for the selected sheet by the column names
      for (let i = 0; i < sheetData[sheet][0]?.length; i++) {
        let targetArr = predictResponse?.filter((res) => `${res.source_label}` === `${sheet}.${sheetData[sheet][0][i]}`)
        const prepareSheetNames = JSON.parse(localStorage.getItem('prepare-sheetNames'));
        // Removing the sheet names from the column names in the predicted label
        if (prepareSheetNames) {
          updatedTargetArr = targetArr?.map((obj) => {
            // Use regex to replace the sheet names in predicted_label
            obj.predicted_label = obj.predicted_label.replace(new RegExp(`^(${prepareSheetNames.join('|')})\\.`), '');
            return obj;
          });
        } else {
          updatedTargetArr = targetArr?.map((obj) => {
            // Split the predicted label by dot
            const labelParts = obj.predicted_label.split('.');
            // Get the last part which is the column name
            const columnName = labelParts[labelParts.length - 1];
            // Return the updated object with only column name
            return {
              ...obj,
              predicted_label: columnName
            };
          });
        }
        const targetArrSorted = updatedTargetArr?.sort((a, b) => b?.prob_score - a?.prob_score);
        source_target_mapped_arr.push({
          id: uuid(),
          source_field: sheetData[sheet][0][i],
          target_field: targetArrSorted ? targetArrSorted[0] : {},
          mapped: targetArrSorted?.length < 1 ? false : true,
          target_field_arr: targetArrSorted,
          mappingRecommendations: false
        })
      }
      setSourceTargetMappedArr(source_target_mapped_arr);
    } else {
      const predictResponse = JSON.parse(localStorage.getItem(`MAPPING_API_RES`));
      // grouping the predicted column names from the API response for the selected sheet by the column names
      for (let i = 0; i < sheetData[sheet][0]?.length; i++) {
        let targetArr = predictResponse?.filter((res) => res.source_label === sheetData[sheet][0][i])
        const prepareSheetNames = JSON.parse(localStorage.getItem('prepare-sheetNames'));
        // Removing the sheet names from the column names in the predicted label
        if (prepareSheetNames) {
          updatedTargetArr = targetArr?.map((obj) => {
            // Use regex to replace the sheet names in predicted_label
            obj.predicted_label = obj.predicted_label.replace(new RegExp(`^(${prepareSheetNames.join('|')})\\.`), '');
            return obj;
          });
        } else {
          updatedTargetArr = targetArr?.map((obj) => {
            // Split the predicted label by dot
            const labelParts = obj.predicted_label.split('.');
            // Get the last part which is the column name
            const columnName = labelParts[labelParts.length - 1];
            // Return the updated object with only column name
            return {
              ...obj,
              predicted_label: columnName
            };
          });
        }
        const targetArrSorted = updatedTargetArr?.sort((a, b) => b?.prob_score - a?.prob_score);
        source_target_mapped_arr.push({
          id: uuid(),
          source_field: sheetData[sheet][0][i],
          target_field: targetArrSorted ? targetArrSorted[0] : {},
          mapped: targetArrSorted?.length < 1 ? false : true,
          target_field_arr: targetArrSorted,
          mappingRecommendations: false
        })
      }
      setSourceTargetMappedArr(source_target_mapped_arr);
    }
    // eslint-disable-next-line
  }, [sheet])

  const handleBackFromMappingReview = () => {
    setReview(false);
    setDataValidation(true);
    setAutoMappingStepper(false);
    setReviewStepper(false);
    setDataValidationStepper(false);
    setSkipDataValidation(false);
    setDeleteModalOpen(false);
    const response = JSON.parse(localStorage.getItem('MAPPING_API_RES'));
    if (response) {
      localStorage.removeItem('MAPPING_API_RES');
    } else {
      setDataValidation(false);
    }
  }

  const handleOnDrag = (e, id, obj) => {
    e.dataTransfer.setData("object", JSON.stringify(obj));
    e.dataTransfer.setData("id", id);
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mapped: false } : obj))
  }

  // const handleOnDrop = (e) => {
  //   const targetField = e.dataTransfer.getData("object");
  //   setUnmappedTargetFields([...unmappedTargetFields, JSON.parse(targetField)])
  // }

  const handleDragOver = (e) => {
    e.preventDefault();
  }

  // const handleOnDragUnmapped = (e, id, obj) => {
  //   e.dataTransfer.setData("id", id);
  //   e.dataTransfer.setData("object", JSON.stringify(obj));
  // }

  const handleOnDropTarget = (e, id) => {
    const obj = e.dataTransfer.getData("object");
    const object = JSON.parse(obj);
    setUnmappedTargetFields((current) => current.filter((obj) => obj.id !== object.id));
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mapped: true, target_field: { ...obj.target_field, predicted_label: object.target_field.predicted_label } } : obj))
  }

  const updateTargetField = (e, id) => {
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, target_field: { ...obj.target_field, predicted_label: e.target.value } } : obj))
  }

  const handleRemoveTargetField = (id, obj) => {
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mapped: false } : obj))
    setUnmappedTargetFields((unmappedTargetFields) => [...unmappedTargetFields, obj]);
  }

  //upload csv files to Azure Storage 
  const uploadCsvFile = async (myCsvFile) => {
    const blobServiceClient = new BlobServiceClient(`${localStorage.getItem('sas_url')}`);
    const containerName = user?.current_workspace._id.toString().toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    // Upload the file
    const options = {
      blobHTTPHeaders: {
        blobContentType: "text/csv"
      }
    };

    try {
      // Generate a unique filename using UUID
      const fileName = uuid() + ".csv";
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(myCsvFile, options);
    } catch (error) {
      console.error('Error uploading File:', error);
    }
  };

  const handleTransferData = async () => {
    setMappingScreen(false);
    setShowRunOrSchedule(false);
    setAirbyteLoading(true);
    let finalMappedData = [];
    try {
      const destinationFields = [];
      sourceTargetMappedArr.forEach((object) => {
        if (object.mapped) {
          destinationFields.push(object.target_field.predicted_label);
        }
        else {
          destinationFields.push('');
        }
      })
      finalMappedData = [destinationFields, ...sheetData[sheet].slice(1)];

      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      const workspacesResponse = await axios.get(`${API}/admin/workspace/get-workspaces`, config);
      if (!workspacesResponse.data.success) {
        setAirbyteLoading(false);
        setMappingScreen(true);
        toast.error(workspacesResponse.data.error.msg);
        return;
      }
      const workspacesArr = workspacesResponse.data.result;

      const workspaceToUpdate = workspacesArr.find(obj => obj._id === user?.current_workspace._id);

      if (workspaceToUpdate.destination_details.destination_type === "file") {
        const userUpdated = { ...user, current_workspace: workspaceToUpdate };
        updateUser(userUpdated);
        if (finalMappedData) {
          const csv = Papa.unparse(finalMappedData);
          const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });
          await uploadCsvFile(csvFile);
        }

        const transferData = await axios.get(`${API}/airbyte/transfer-data/file-to-blob`, config);
        if (!transferData.data.success) {
          setAirbyteLoading(false);
          setMappingScreen(true);
          toast.error(workspacesResponse.data.error.msg);
          return;
        }
      }

      if (workspaceToUpdate.destination_details.destination_type === "MySQL" || workspaceToUpdate.destination_details.destination_type === "MongoDB") {
        if (finalMappedData) {
          const csv = Papa.unparse(finalMappedData);
          const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });
          await uploadCsvFile(csvFile);
          const transferData = await axios.get(`${API}/airbyte/transfer-data/file-to-db`, config);
          if (!transferData.data.success) {
            setAirbyteLoading(false);
            setMappingScreen(true);
            toast.error(transferData.data.error.msg);
            return;
          }
        }
      }
      setAirbyteLoading(false);
      setSuccess(true);
      setDestinationStepper(true);
    } catch (error) {
      console.error('Error in API calls:', error);
      toast.error("Error in API calls");
      setAirbyteLoading(false);
      setMappingScreen(true);
    }
  }

  const handleRunOrSchedule = () => {
    setMappingScreen(false);
    setShowRunOrSchedule(true);
  }

  console.log(unmappedTargetFields);

  return (
    <>
      {mappingScreen ?
        <div>
          <div className="mt-2 flex justify-between mb-5">
            <div className='flex gap-5 justify-center items-center'>
              <form className="max-w-sm">
                {/* <div className="relative">
              <img src={search} alt="search" className='absolute top-0 bottom-0 my-auto text-gray-400 left-3 ' />
              <input
                type="text"
                placeholder="Search source fields"
                className="w-full py-[0.4rem] pl-12 pr-4 text-gray-500 border rounded-md outline-none text-sm"
              />
            </div> */}
              </form>
              {/* <div className="text-[14px] group">
                <button
                  className="px-3 py-1 rounded-md border-[1px] flex items-center justify-between"
                  onClick={() => setMappingDropdownVisible(!mappingDropdownVisible)}
                >
                  All source fields <img src={downArrow} alt="downarrow" className='ms-3' />
                </button>
                {mappingDropdownVisible && (
                  <div className="relative top-2 text-sm text-[#212529] group-hover:flex">
                    <div className="bg-white absolute w-56 h-fit border-[1px] border-[#707070] rounded-md z-20 py-1">
                      <ul onClick={() =>
                        setMappingDropdownVisible(false)
                      }>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Show mapped fields
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Show unmapped fields
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Reset mapping
                        </li>
                         <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Clear mapping
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          All source fields
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div> */}
              {/* <div className='flex gap-2 text-sm font-semibold'>
            <img src={Warn} alt='warn' width="24px" />
            Errors: 2
          </div> */}
            </div>
            <div className='flex gap-2.5 justify-center items-center'>
              <button className="flex items-center gap-2 bg-[#BFBFBF] px-2 py-1 hover:bg-[#A5A5A5] text-white rounded-md text-sm"
                onClick={() => {
                  clearStates();
                }}>
                Cancel
              </button>
              <button className="flex items-center gap-2 bg-[#BFBFBF] px-[10px] py-[5px] hover:bg-[#A5A5A5] back-btn text-white rounded-md text-sm"
                onClick={() => {
                  handleBackFromMappingReview();
                }}>
                {/* <img src={backWhite} alt="back" className='back-btn-img' />  */}
                Back
              </button>
              <button className="flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm"
                onClick={() => {
                  handleRunOrSchedule();
                }}>
                {/* <PiArrowRightBold color="#fff" size={15} />{" "} */}
                Continue
              </button>
            </div>
          </div>
          <>
            <div className=" mt-2 text-sm flex justify-start">
              {selectedValues?.length === 1 ? (
                <h5 className="mt-0 overflow-auto bg-[#F9FAFB] border-x-[1px] border-t-[1px] border-[#C7C7C7] px-4 py-3 w-full rounded-t-lg font-semibold text-sm">
                  Sheet Name : {sheet}
                </h5>
              ) : (
                <div className="flex">
                  {displayedElements.map((s, i) => (
                    <div className='group relative'>
                      <label htmlFor={`${s}`}>
                        <h5
                          key={i}
                          className={`px-2 py-2.5 text-center rounded-t-lg w-28 border-x-[1px] border-t-[1px] border-[#C7C7C7] drop-item cursor-pointer font-medium ${s === sheet
                            ? "sheet_active"
                            : "bg-[#F9FAFB]"
                            }`}
                        >
                          <input
                            id={`${s}`}
                            name={`${s}`}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded hidden"
                            checked={s === sheet}
                            value={s}
                            onChange={(e) => {
                              setSheet(e.target.value);
                            }}
                          />
                          {s}
                        </h5>
                      </label>
                      <span className='group-hover:visible invisible down-arrow text-sm'>
                        {s}
                      </span>
                    </div>
                  ))}

                </div>
              )}

              {checkboxes.length <= 5 ? (
                " "
              ) : (
                <div className="flex justify-end w-full gap-5 items-center">
                  <div className="flex gap-6">
                    <button
                      onClick={handleBackwardClick}
                      disabled={currentIndex === 0}
                    >
                      {currentIndex === 0 ? (
                        <img src={disableLeft} alt="left" />
                      ) : (
                        <img src={LeftArrow} alt="left" />
                      )}
                    </button>

                    <button
                      onClick={handleForwardClick}
                      disabled={
                        currentIndex ===
                        selectedValues.length - 5
                      }
                    >
                      {currentIndex ===
                        selectedValues.length - 5 ? (
                        <img src={disableRight} alt="right" />
                      ) : (
                        <img src={RightArrow} alt="right" />
                      )}
                    </button>
                  </div>

                  {/* dropdown */}
                  <div className="text-[14px] group">
                    <button
                      className="px-1.5 py-1 rounded-sm border-[1px] flex items-center w-24 justify-between"
                      onClick={() => setDropdownVisible(!dropdownVisible)}
                    >
                      Sheet <img src={downArrow} alt="downarrow" />
                    </button>
                    {dropdownVisible && (
                      <div className="relative right-32 top-2 text-sm text-[#212529] group-hover:flex">
                        <div className="bg-white absolute w-56 h-fit border-[1px] border-[#707070] rounded-md z-20 py-1">
                          {sheetNames.map((n, i) => (
                            <ul key={i}>
                              <li
                                className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm"
                                onClick={() =>
                                  setDropdownVisible(false)
                                }
                              >
                                {n}
                              </li>
                            </ul>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
          <div className='mb-[25px]'>
            <div className={`flex flex-col py-5 border border-[#C7C7C7] px-[100px] rounded-b-[10px] ${selectedValues?.length === 1 ? "" : "rounded-tr-[10px]"} overflow-y-auto`}>
              <div className='w-full flex items-center font-semibold mb-4'>
                <div className='w-[53%]'>SOURCE FIELDS</div>
                <div>DESTINATION FIELDS</div>
              </div>

              <div className='w-full flex flex-col gap-3 text-sm text-[#212529] font-semibold max-h-[60vh] overflow-y-auto'>
                {sourceTargetMappedArr.map((obj, index) => (
                  <div className='flex justify-center items-center me-3' key={index}>
                    <div className='w-[48%] h-[43px] rounded-[10px] shadow-[0_0_13px_2px_#efeded] py-[10px] border border-[#00000029] px-[20px] flex items-center'>{obj.source_field}</div>
                    <div className='ms-2 me-3 flex items-center justify-center'>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070]'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                    </div>
                    {obj.mapped ?
                      <div className={`w-[48%] h-[43px] rounded-[10px] flex justify-between items-center py-[10px] ${obj?.target_field?.prob_score >= 0.8 ? 'border border-[#00FF08] shadow-[0_0_13px_2px_#00FF081A]' : ''} ${obj?.target_field?.prob_score < 0.8 ? 'border border-[#FFA200] shadow-[0_0_13px_2px_#FFA2001A]' : ''} px-[20px]`} draggable onDragStart={(e) => handleOnDrag(e, obj.id, obj)} onDragOver={handleDragOver} >
                        <div>{obj?.target_field?.predicted_label}</div>
                        <div className='flex gap-3 items-center justify-center'>
                          <MappingPopover obj={obj} index={index} updateTargetField={updateTargetField} setSourceTargetMappedArr={setSourceTargetMappedArr} />
                          <img src={remove} alt="remove" onClick={() => handleRemoveTargetField(obj.id, obj)} />
                        </div>
                      </div> :
                      <div className='w-[48%] rounded-[10px] h-[43px] shadow-[0_0_13px_2px_#FF09001A] py-[10px] border border-[#FF0900] px-[20px]' onDrop={(e) => handleOnDropTarget(e, obj.id)} onDragOver={handleDragOver} ></div>}
                  </div>
                ))}
              </div>

              {/* <div className='font-semibold mt-[30px]'>
                UNMAPPED TARGET FIELDS
              </div>
              <div className='border h-[200px] border-[#83646429] shadow-[0_0_13px_2px_#efeded] mt-4 rounded-[10px] p-5 font-semibold text-[#212529] text-[14px] mb-[50px]' onDrop={handleOnDrop} onDragOver={handleDragOver}>
                <div className='flex items-center gap-5 flex-wrap'>
                  {unmappedTargetFields.map((obj, i) => (
                    <div className='h-[43px] border border-[#616161] rounded-[10px] flex items-center px-4 justify-center hover:bg-[#0083BF] hover:text-white hover:font-medium' key={i} draggable
                      onDragStart={(e) => handleOnDragUnmapped(e, obj.id, obj)} >
                      {obj.target_field.predicted_label}
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </div> : ''
      }

      {airbyteLoading ?
        <div className="flex justify-center items-center mt-28 flex-col">
          <div className="flex flex-col justify-center items-center">
            <img src={loadingGif} alt="loading" className="w-[50px] h-[65px]" />
            <h5 className="mt-5">Connecting to ETL Service....</h5>
          </div>
        </div> : ''
      }

      {success ?
        <Success
          clearStates={clearStates}
          message="Data transformed and loaded successfully to the destination."
        /> : ''
      }

      {showRunOrSchedule ?
        <RunOrSchedule
          handleTransferData={handleTransferData}
          setShowRunOrSchedule={setShowRunOrSchedule}
          clearStates={clearStates}
          setDestinationStepper={setDestinationStepper}
        />
        : ""
      }
    </>
  )
}

export default MappingTable
