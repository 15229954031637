import React, { useEffect, useRef, useState } from 'react';
import Nav from "../components/ReUseable/Navbar/Nav";
import Sidebar from "../components/ReUseable/Sidebar/Sidebar";
import AddWorkspaceModal from '../components/ReUseable/Modals/AddWorkspaceModal';
import EditWorkspaceModal from '../components/ReUseable/Modals/EditWorkspaceModal';
import DeleteModal from '../components/ReUseable/Modals/DeleteModal';
import axios from 'axios';
import { API } from '../global';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';
import { useAuth } from '../hooks/useAuth';

const Layout = ({ children }) => {
  const [addWorkspaceModal, setAddWorkspaceModal] = useState(false);
  const [editWorkspaceModal, setEditWorkspaceModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceName, setWorkspaceName] = useState("");
  const [description, setDescription] = useState("");
  const [privateWorkspace, setPrivateWorkspace] = useState(true);
  const [workspaceId, setWorkspaceId] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const { user, updateUser, logout } = useAuth();
  let showWorkspaceDetails = true;
  const modalRef = useRef();

  useEffect(() => {
    if (showWorkspaceDetails) {
      getAllWorkspaces();
      getAllUsers();
    }
    //eslint-disable-next-line
  }, [])

  const getAllWorkspaces = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      const workspacesResponse = await axios.get(`${API}/admin/workspace/get-workspaces`, config);
      if (!workspacesResponse.data.result || workspacesResponse.data.result.length === 0) {
        addWorkspace();
      }
      else {
        setWorkspaces(workspacesResponse.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getAllUsers = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      const usersResponse = await axios.get(`${API}/users/get_all_users`, config);
      setUsers(usersResponse.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const addWorkspace = async () => {
    try {
      const workspaceToAdd = {
        workspace_name: "Default workspace",
        description: "Default workspace",
        workspace_unique_id: uuid(),
        private_workspace: true,
        users: [user._id]
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      const newWorkspace = await axios.post(`${API}/admin/workspace/create-workspace`, workspaceToAdd, config);
      await axios.put(`${API}/users/edit_users/${user._id}`, { current_workspace: newWorkspace.data.result._id }, { "Content-Type": "application/json" });
      const workspacesResponse = await axios.get(`${API}/admin/workspace/get-workspaces`, config);
      if (workspacesResponse.data.success) {
        setWorkspaces(workspacesResponse.data.result);
        const userUpdated = { ...user, current_workspace: newWorkspace.data.result };
        updateUser(userUpdated);
      }
      else {
        if (workspacesResponse.data.error.msg === 'Unauthorized token') {
          toast.error('Session Expired. Please login again to continue');
          logout();
        } else {
          toast.error(workspacesResponse.data.error.msg);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  console.log(workspaces, 'ws');

  const handleDeleteWorkspace = async () => {
    let workspacesArr = [];
    try {
      await axios.put(`${API}/admin/workspace/delete-workspace/${workspaceId}`,
        { deleted_status: true }, { "Content-Type": "application/json" });
      setDeleteModalOpen(false);
      getAllWorkspaces();
      await axios.put(`${API}/users/edit_users/${user?._id}`, { current_workspace: workspaces[0]._id },
        { "Content-Type": "application/json" });
      toast.success("workspace deleted successfully");
      const userUpdated = { ...user, current_workspace: workspacesArr[0] };
      updateUser(userUpdated);
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Clicked outside the modal, so close it
      setAddWorkspaceModal(false);
      setEditWorkspaceModal(false);
      setDeleteModalOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${addWorkspaceModal || editWorkspaceModal ? 'overflow-hidden h-screen relative' : ''}`}>
      <Nav showWorkspaceDetails={showWorkspaceDetails} workspaces={workspaces} setWorkspaces={setWorkspaces}
        setAddWorkspaceModal={setAddWorkspaceModal} addWorkspaceModal={addWorkspaceModal}
        editWorkspaceModal={editWorkspaceModal} setEditWorkspaceModal={setEditWorkspaceModal}
        workspaceName={workspaceName} setWorkspaceName={setWorkspaceName}
        description={description} setDescription={setDescription} users={users} setUsers={setUsers}
        selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}
        privateWorkspace={privateWorkspace} setPrivateWorkspace={setPrivateWorkspace}
        workspaceId={workspaceId} setWorkspaceId={setWorkspaceId} setWorkspaceUsers={setWorkspaceUsers}
      />
      <Sidebar />
      {children}
      {addWorkspaceModal ?
        <AddWorkspaceModal setAddWorkspaceModal={setAddWorkspaceModal}
          setWorkspaces={setWorkspaces} users={users} setUsers={setUsers}
          getAllWorkspaces={getAllWorkspaces} modalRef={modalRef}
        /> : ''}
      {editWorkspaceModal ?
        <EditWorkspaceModal editWorkspaceModal={editWorkspaceModal} setEditWorkspaceModal={setEditWorkspaceModal}
          workspaces={workspaces} setWorkspaces={setWorkspaces}
          workspaceName={workspaceName} setWorkspaceName={setWorkspaceName}
          description={description} setDescription={setDescription} users={users} setUsers={setUsers}
          privateWorkspace={privateWorkspace} setPrivateWorkspace={setPrivateWorkspace}
          setSelectedUsers={setSelectedUsers} selectedUsers={selectedUsers}
          workspaceId={workspaceId} setWorkspaceId={setWorkspaceId} setDeleteModalOpen={setDeleteModalOpen}
          getAllWorkspaces={getAllWorkspaces} workspaceUsers={workspaceUsers} modalRef={modalRef}
        /> : ''}
      {deleteModalOpen ?
        <DeleteModal setModalOpen={setDeleteModalOpen} modalOpen={deleteModalOpen} handleDelete={handleDeleteWorkspace}
          deleteWorkspace={true} showSkipData={false} modalRef={modalRef} getAllWorkspaces={getAllWorkspaces} /> : ''}
    </div>
  );
}

export default Layout;
