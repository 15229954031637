import React from 'react'
import { PiArrowRightBold } from "react-icons/pi";
// import { useNavigate } from "react-router-dom";

const CsvPreview = ({ file_name, values, tableRows, setFileUploaded, handlePreview }) => {
 
  return (
    <>
      <div className=' px-5  border-l-[1px] border-[#CCCCCC]'>
        <div className="flex justify-between items-center mb-1">
          <span>Preview</span>
          <span>
            <button
              className="flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm"
              onClick={() => {
                handlePreview()
                setFileUploaded(false)
              }}
            >
              <PiArrowRightBold color="#fff" size={15} /> Continue
            </button>
          </span>
        </div>
        <div className="mt-0 overflow-x-auto border-x-[1px] border-t-[1px] border-[#DDDEE0] rounded-t-lg">
          <h5 className="prepare_h5 px-4 py-3 text-sm bg-[#F9FAFB] text-[#212529] font-semibold border-b-[1px] border-[#DDDEE0]">
            File Name : {file_name}
          </h5>
          <table className="min-w-full table-fixed text-center text-sm font-light">
            <thead className="border-b font-medium border-[#DDDEE0]">
              <tr>
                {tableRows.map((rows, index) => {
                  return (
                    <th
                      key={index}
                      className="px-4 py-3 text-[#212529] font-semibold text-sm text-left"
                    >
                      {rows}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {values.map((value, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b font-normal text-sm border-[#DDDEE0]"
                  >
                    {/* <td>{index + 1}</td> */}
                    {value.map((val, i) => {
                      return (
                        <td
                          key={i}
                          className="px-4 py-3 w-44 h-[45px] break-all text-[0.88em] text-[#212529] text-left"
                        >
                          {val}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default CsvPreview