import React, { useState, useMemo } from "react";

import { IoEyeOutline } from "react-icons/io5";

import Pagination from "../Pagination/Pagination";

const TableNameList = (props) => {
  const { tableNames, title, selectedTables, setSelectedTables } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = useMemo(() => {
    return tableNames.slice(indexOfFirstItem, indexOfLastItem);
  }, [tableNames, indexOfFirstItem, indexOfLastItem]);

  const toggleTableSelection = (tableName) => {
    setSelectedTables((prev) =>
      prev.includes(tableName)
        ? prev.filter((name) => name !== tableName)
        : [...prev, tableName]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedTables(tableNames);
    } else {
      setSelectedTables([]);
    }
  };

  return (
    <>
      <div className="overflow-x-auto border border-[#DDDEE0] rounded-md">
        <table className="min-w-full text-left table-auto">
          {tableNames.length > 0 && (
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-[15px] px-5">
                  <label className="font-semibold text-[#212529] cursor-pointer">
                    <input
                      className="mr-2.5 align-middle"
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={tableNames.length === selectedTables.length}
                    />
                    <span className="align-middle">{title}</span>
                  </label>
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((table, idx) => {
                const isSelected = selectedTables.includes(table);
                return (
                  <tr key={idx} className="border-b hover:bg-gray-50 group">
                    <td className="py-[15px] px-5 text-sm text-[#212529] flex justify-between items-center relative">
                      <label className="cursor-pointer">
                        <input
                          type="checkbox"
                          className="mr-2 align-middle"
                          checked={isSelected}
                          onChange={() => toggleTableSelection(table)}
                        />
                        <span className="align-middle">{table}</span>
                      </label>
                      <button className="hidden group-hover:block absolute right-5">
                        <span
                          className="flex gap-2 items-center bg-[#0083BF] text-white text-sm px-2 py-1 rounded"
                          // onClick={() => handlePreviewTable()}
                        >
                          <IoEyeOutline color="#fff" fontSize="17px" />
                          Preview
                        </span>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="py-[15px] px-5 text-sm text-[#212529] text-center"
                  colSpan={1}
                >
                  No tableNames available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {tableNames.length > 0 && (
        <Pagination
          data={tableNames}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          indexOfFirstItem={indexOfFirstItem}
          indexOfLastItem={indexOfLastItem}
        />
      )}
    </>
  );
};

export default TableNameList;