import React, { useState } from 'react'
import SheetLists from '../../ReUseable/SheetLists'
import FilePreview from '../../ReUseable/FilePreview'
import CsvPreview from '../../ReUseable/CsvPreview'
import { VscChromeClose } from 'react-icons/vsc'
import Xls from "../../../assets/XLS.png";
// import Loader from "../../../assets/Mask Group 6.svg";
// import loadingGif from "../../../assets/loading.gif"

const ClickToUploadFile = ({ setSourceType, setPreviewStepper, setFileUploaded, setSingleSheet, setTableshow, setSname, SheetNames, sheet, setSheet, fileName, fileType, setSheetCount, SheetCount, loading, sname, values, sheetData, csvTable, tableRows, tableshow, singleSheet, setThankyou, handlePreview, checkAll, setCheckAll, setSelectedValues, selectedValues, checkboxes, setCheckboxes }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSingleSheet = (e) => {
    setSingleSheet(true);
    setTableshow(true);
    setSname(false);
  };

  const handleSelectAll = (checked) => {
    setCheckAll(!checkAll);
    if (checked) {
      setCheckboxes(SheetNames);
    } else {
      setCheckboxes([]);
    }
  };

  const handleCheckboxChange = (value, checked) => {
    if (checked) {
      setCheckboxes([...checkboxes, value]);
    } else {
      setCheckboxes(checkboxes.filter((item) => item !== value));
    }
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    if (checkboxes.length === SheetNames.length) {
      setSelectedValues(checkboxes);
    } else {
      setSelectedValues(checkboxes);
    }
    setSingleSheet(true);
    setTableshow(true);
    setSname(false);
    setSheet(checkboxes[0]);
  };

  const handleForwardClick = () => {
    if (currentIndex < selectedValues.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBackwardClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const displayedElements = selectedValues.slice(
    currentIndex,
    currentIndex + 7
  );

  const clearStates = () => {
    setSheetCount(0);
    setCheckAll(false);
    setTableshow(false);
    setSourceType(true);
    setFileUploaded(false);
    setPreviewStepper(false);
  };

  console.log(sheetData, 'sheet data');

  return (
    <div className="flex">
      <div className="flex flex-col font-medium text-lg pr-5 my-10">
        Upload
        <div className="z-10 mt-5">
          {tableshow ? (
            <div className="flex justify-between p-5 h-20 gap-[90px] bg-white rounded-lg hover:w-fit drop-shadow-[0_0_10px_#00000029] overflow-hidden transition-all w-20 delay-[200ms]">
              <div className='flex gap-5 items-center'>
                <img src={Xls} alt="xls" className='w-10 h-10 object-cover' />
                <div className="flex flex-col text-xs text-lite-black font-semibold">
                  {fileName}
                  <span>{`text/${fileType}`}</span>
                </div>
              </div>
              <div className='flex items-center' onClick={() => clearStates()}>
                <VscChromeClose color="#707070" fontSize="15px" className="cursor-pointer" />
              </div>
            </div>
          ) : (
            <div className="p-5 flex items-center gap-9 bg-white rounded-lg shadow-[0px_0px_10px_0px_rgba(0,0,0,0.16)]">
              <span>
                <img src={Xls} alt="xls" className='w-10 h-10 object-cover' />
              </span>
              <div className="flex flex-col text-xs text-lite-black font-semibold">
                {fileName}
                <span>{`text/${fileType}`}</span>
              </div>
              <span className="ml-10" onClick={() => clearStates()}>
                <VscChromeClose color="#707070" fontSize="15px" className='cursor-pointer' />
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 font-medium text-lg my-10">
        {(SheetCount === 0 || !csvTable) && loading ? (
          <div className="px-5 border-l-[1px] border-[#CCCCCC]">
            <div>Preview</div>{" "}
            <div className="flex justify-center flex-col mt-10 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="50" height="65" viewBox="0 0 50 65">
                <defs>
                  <clipPath id="clip-path">
                    <path id="Path_15" data-name="Path 15" d="M95.487,19.043A4.471,4.471,0,0,1,91,14.6V0H68.051A7.025,7.025,0,0,0,61,6.982V58.018A7.025,7.025,0,0,0,68.051,65h35.9A7.025,7.025,0,0,0,111,58.018V19.043ZM71.513,45.7h9.323a1.9,1.9,0,1,1,0,3.809H71.513a1.9,1.9,0,1,1,0-3.809ZM69.59,37.451a1.914,1.914,0,0,1,1.923-1.9H99.718a1.9,1.9,0,1,1,0,3.809H71.513A1.914,1.914,0,0,1,69.59,37.451ZM99.718,25.391a1.9,1.9,0,1,1,0,3.809H71.513a1.9,1.9,0,1,1,0-3.809Z" transform="translate(857 340)" fill="#bcbcbc" />
                  </clipPath>
                  <linearGradient id="linear-gradient" x1="0.067" y1="1" x2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#00c8ad" />
                    <stop offset="1" stopColor="#0078c5" />
                  </linearGradient>
                </defs>
                <g id="Mask_Group_6" data-name="Mask Group 6" transform="translate(-918 -340)" clipPath="url(#clip-path)">
                  <rect id="Rectangle_105" data-name="Rectangle 105" width="50" height="65" transform="translate(918 340)" fill="url(#linear-gradient)" />
                </g>
              </svg>
              {/* <img src={loadingGif} alt="loader" className='w-[50px] h-[65px]' /> */}
              {/* <img src={Loader} alt="" /> */}
              <h5 className="mt-5">Analysing....</h5>
            </div>{" "}
          </div>
        ) : (
          <>
            {(SheetCount !== 0 || csvTable) ? (
              <div>
                {csvTable ? (
                  <>
                    <CsvPreview
                      tableRows={tableRows}
                      values={values}
                      file_name={fileName}
                      setFileUploaded={setFileUploaded}
                      setThankyou={setThankyou}
                      handlePreview={handlePreview}
                    />
                  </>
                ) : (
                  ""
                )}
                {sname ? (
                  <SheetLists
                    checkboxes={checkboxes}
                    handleSubmit={handleSubmit} handleSingleSheet={handleSingleSheet}
                    handleSelectAll={handleSelectAll}
                    handleCheckboxChange={handleCheckboxChange}
                    setSheet={setSheet}
                    sheetNames={SheetNames}
                    checkAll={checkAll} excel={true}/>) : (
                  <>
                    {singleSheet ? (
                      <div>
                        {sheetData ? (<div className="z-0 absolute left-[11rem] right-5 pb-5">
                          <FilePreview handlePreview={handlePreview}
                            sheet={sheet} sheetData={sheetData} selectedValues={selectedValues}
                            checkboxes={checkboxes}
                            setCheckboxes={setCheckboxes}
                            setTableShow={setTableshow}
                            setCheckAll={setCheckAll}
                            setSheet={setSheet}
                            displayedElements={displayedElements}
                            handleBackwardClick={handleBackwardClick}
                            currentIndex={currentIndex}
                            handleForwardClick={handleForwardClick}
                            setThankyou={setThankyou}
                            setSname={setSname}
                            setFileUploaded={setFileUploaded}
                          />  </div>) : ""}
                      </div>
                    ) : ""}
                  </>
                )}
              </div>) : " "
            }
          </>
        )}
      </div>
    </div>
  )
}

export default ClickToUploadFile