import React, { useState } from "react";

import CustomDropdown from "../../ReUseable/Dropdown/CustomDropdown";
import CustomTimePicker from "../../ReUseable/CustomTimePicker/CustomTimePicker";
import CustomDatePicker from "../../ReUseable/CustomDatePicker/CustomDatePicker";
import Success from "../../ReUseable/Success/Success";

const ScheduleFrequency = (props) => {
  const { setShowScheduleOptions, clearStates, setShowRunOrSchedule, setDestinationStepper } = props;

  const [frequency, setFrequency] = useState("Once");
  const [onceDate, setOnceDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("12:00 PM");
  const [selectedDay, setSelectedDay] = useState("Sunday");
  const [monthlyDayOption, setMonthlyDayOption] = useState("Start date of each month");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleFrequencyChange = (option) => {
    setFrequency(option);
    if (option === "Once") {
      setOnceDate(new Date());
    } else {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };

  const handleDayChange = (option) => {
    if (frequency === "Weekly") {
      setSelectedDay(option);
    } else if (frequency === "Monthly") {
      setMonthlyDayOption(option);
    }
  };

  const handleSchedule = () => {
    setIsSuccess(true);
    setDestinationStepper(true);
  };

  const handleCancel = () => {
    setShowScheduleOptions(false);
  };

  const clearStatesUpdated = () => {
    clearStates();
    setShowRunOrSchedule(false);
    setShowScheduleOptions(false);
  }

  return (
    <>
      {isSuccess ? (
        <Success
          clearStates={clearStatesUpdated}
          message="The task has been scheduled successfully."
        />
      ) : (
        <div className="flex flex-col items-center rounded-md w-[480px] mx-auto mt-10 xl:mt-16 text-sm">
          <h2 className="text-xl font-medium mb-2">Schedule Frequency</h2>
          <hr className="w-full border-t-2 border-[#0083BF] mb-6" />

          <div className="flex justify-between w-full mb-4 items-center">
            <label htmlFor="frequency" className="block text-sm font-medium text-[#333333]">
              Repeat
            </label>
            <div className="w-[70%]">
              <CustomDropdown
                options={["Once", "Daily", "Weekly", "Monthly"]}
                placeholder="Select frequency"
                onSelect={handleFrequencyChange}
                width="70%"
                selected={frequency}
              />
            </div>
          </div>

          {frequency === "Once" && (
            <div className="flex justify-between w-full mb-4 items-center">
              <label htmlFor="once-date" className="block text-sm font-medium text-[#333333]">
                Date
              </label>
              <div className="w-[70%]">
                <CustomDatePicker
                  selectedDate={onceDate}
                  setSelectedDate={setOnceDate}
                />
              </div>
            </div>
          )}

          {frequency !== "Once" && (
            <>
              <div className="flex justify-between w-full mb-4 items-center">
                <label htmlFor="start-date" className="block text-sm font-medium text-[#333333]">
                  Start Date
                </label>
                <div className="w-[70%]">
                  <CustomDatePicker
                    selectedDate={startDate}
                    setSelectedDate={setStartDate}
                  />
                </div>
              </div>

              <div className="flex justify-between w-full mb-4 items-center">
                <label htmlFor="end-date" className="block text-sm font-medium text-[#333333]">
                  End Date
                </label>
                <div className="w-[70%]">
                  <CustomDatePicker
                    selectedDate={endDate}
                    setSelectedDate={setEndDate}
                  />
                </div>
              </div>
            </>
          )}

          {frequency === "Weekly" && (
            <div className="flex justify-between w-full mb-4 items-center">
              <label htmlFor="day" className="block text-sm font-medium text-[#333333]">
                Day
              </label>
              <div className="w-[70%]">
                <CustomDropdown
                  options={["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]}
                  placeholder="Select day"
                  width="100%"
                  onSelect={handleDayChange}
                  selected={selectedDay}
                />
              </div>
            </div>
          )}

          {frequency === "Monthly" && (
            <div className="flex justify-between w-full mb-4 items-center">
              <label htmlFor="month-day" className="block text-sm font-medium text-[#333333]">
                Day
              </label>
              <div className="w-[70%]">
                <CustomDropdown
                  options={["Start date of each month", "End date of each month"]}
                  placeholder="Select option"
                  width="100%"
                  onSelect={handleDayChange}
                  selected={monthlyDayOption}
                />
              </div>
            </div>
          )}

          <div className="flex justify-between w-full mb-6 items-center">
            <label htmlFor="time" className="block text-sm font-medium text-[#333333]">
              Time
            </label>
            <div className="w-[70%]">
              <CustomTimePicker
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
              />
            </div>
          </div>

          <div className="w-full flex mt-4 gap-2.5 justify-end">
            <button
              className="px-4 py-2 border border-[#B6B6B6] text-[#333333] rounded-md hover:bg-gray-100"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-[#0078C5] text-white rounded-md hover:bg-blue-600"
              onClick={handleSchedule}
            >
              Schedule
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleFrequency;