import React, { useEffect, useRef, useState } from 'react';
import downArrow from "../../../assets/downArrow.svg";
import search from "../../../assets/searchDropdown.svg";

const UsersDropdown = ({ options, width, placeholder, selectedUsers, setSelectedUsers, selectUsersError, setSelectUsersError }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, so close it
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredOptions = options.length > 0 ? options.filter(option =>
    option?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  ) : [];

  const handleUserSelection = (e, option) => {
    if (e.target.checked) {
      setSelectedUsers((selectedUsers) => [...selectedUsers, option]);
    } else {
      setSelectedUsers(selectedUsers => selectedUsers.filter(obj => obj.user_id && obj.user_id._id ? obj.user_id._id !== option._id : obj._id !== option._id));
    }
  }

  const userNames = selectedUsers?.map(user => user.name ? user.name : user.user_id.name);
  const joinedUserNames = userNames.join(', ');

  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setSelectUsersError(false);
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedUsers(filteredOptions);
    } else {
      setSelectedUsers([]);
    }
  }

  return (
    <div className={`${width} font-medium relative h-full cursor-pointer`} ref={dropdownRef}>
      <div className={`bg-white h-full px-2.5 py-1.5 mb-1 text-sm flex justify-between gap-3 rounded-md border border-[#B6B6B6]`} onClick={() => handleDropdown()}>
        {selectedUsers.length > 0 ?
          <div className='text-sm w-full overflow-hidden text-ellipsis whitespace-nowrap'>
            {joinedUserNames}
          </div>
          :
          <span className='text-lite-grey-3'>{placeholder}</span>
        }
        <div className='flex h-full items-center' >
          <img src={downArrow} alt="down-arrow" />
        </div>
      </div>
      {!selectUsersError ?
        <ul className={`border border-lite-grey rounded-md ${width} z-10 absolute bg-white pt-1 pb-2 max-h-44 xl:max-h-56 overflow-y-auto ${dropdownOpen ? 'block' : 'hidden'}`}>
          <li className='text-sm py-2 px-2.5 flex text-lite-black gap-2 border-b-lite-grey-4 border-b mb-1'>
            <img src={search} alt="search" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search"
              className="placeholder:text-lite-grey-3 outline-none"
            />
          </li>
          <li className='text-sm px-2.5 py-1 overflow-hidden flex gap-2 text-ellipsis
             text-lite-black whitespace-nowrap hover:bg-lite-grey-1 cursor-pointer'
          >
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="h-4 w-4 border-[#C7C7C7] border rounded me-3"
                onChange={(e) => handleSelectAll(e)}
              />
              Select All
            </label>
          </li>
          {filteredOptions?.map((option, index) => (
            <li key={index} className='text-sm px-2.5 py-1 overflow-hidden flex gap-2 text-ellipsis
             text-lite-black whitespace-nowrap hover:bg-lite-grey-1 cursor-pointer'
            >
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={null || (selectedUsers.some(user => user._id === option._id || (user.user_id && user.user_id._id === option._id)))}
                  className="h-4 w-4 border-[#C7C7C7] border rounded me-3"
                  onChange={(e) => handleUserSelection(e, option)}
                />
                {option.name}
              </label>
            </li>
          ))}
        </ul>
        :
        <div className='text-red text-xs absolute'>Select atleast one user</div>
      }
    </div>
  )
}

export default UsersDropdown
