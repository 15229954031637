import React from 'react';
import WorkspaceDropdown from '../Dropdown/WorkspaceDropdown';
import profile from "../../../assets/profile.svg";
// import logoutIcon from "../../../assets/logout.svg";
import { useAuth } from '../../../hooks/useAuth';

const Nav = ({ showWorkspaceDetails, setAddWorkspaceModal, workspaces,
  setEditWorkspaceModal, workspaceName, setWorkspaceName, description, setDescription,
  users, setUsers, privateWorkspace, setPrivateWorkspace, setSelectedUsers, selectedUsers,
  workspaceId, setWorkspaceId, setWorkspaceUsers }) => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  }

  return (
    <nav className='fixed top-0 left-0 h-[50px] right-0 bg-gradient-to-r from-[#00C8AD] to-[#0078C5] z-30'>
      <div className='flex justify-between items-center h-full px-5'>
        <div className='text-white text-[22px]'>
          Ignitho Data Accelerator
        </div>
        {showWorkspaceDetails ?
          <div className='flex gap-5 items-center'>
            <div>
              <WorkspaceDropdown options={workspaces} selectedValue={user?.current_workspace ? user.current_workspace : workspaces[0]}
                placeholder='Select Workspace' width="w-[160px]" dropdownWidth="w-[240px]"
                setAddWorkspaceModal={setAddWorkspaceModal} setEditWorkspaceModal={setEditWorkspaceModal}
                workspaceName={workspaceName} setWorkspaceName={setWorkspaceName} description={description}
                setDescription={setDescription} users={users} setUsers={setUsers}
                setPrivateWorkspace={setPrivateWorkspace} privateWorkspace={privateWorkspace}
                selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}
                workspaceId={workspaceId} setWorkspaceId={setWorkspaceId} setWorkspaceUsers={setWorkspaceUsers}
              />
            </div>
            <div className='font-medium relative text-sm group'>
              <img src={profile} alt="profile" className='w-6 h-6' />
              <div className="bg-white absolute border border-lite-grey-4 invisible group-hover:visible right-0 rounded-md z-20">
                <ul className="z-10 bg-white max-h-[85px] overflow-y-auto rounded-t-lg">
                  <li className="text-sm px-2.5 py-2.5 overflow-hidden text-ellipsis flex justify-center text-lite-black whitespace-nowrap">
                    {user?.name}
                  </li>
                  <li className="px-2.5 py-2 rounded-md overflow-hidden m-0.5 flex justify-center gap-2 cursor-pointer text-ellipsis whitespace-nowrap bg-[#0083BF] hover:bg-[#00578F] text-white" onClick={() => handleLogout()}>
                    {/* <img src={logoutIcon} alt="logout"/> */}
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </div> : ''}
      </div>
    </nav>

  )
}

export default Nav
