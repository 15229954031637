import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import deleteIcon from "../../../../assets/delete.svg";
import editIcon from "../../../../assets/edit.svg";
import add from "../../../../assets/plusWhite.svg";
import DeleteModal from '../../../ReUseable/Modals/DeleteModal';
import { API } from '../../../../global';
import axios from "axios";
import toast from 'react-hot-toast';
import loadingGif from "../../../../assets/loadingGif.gif";

const DataValidationGroupsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 13;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dataValidationGroups, setDataValidationGroups] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDataValidationGroups();
  }, [])

  const getDataValidationGroups = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API}/data-validation/data-validation-groups`, {
        "Content-Type": 'application/json'
      })
      setDataValidationGroups(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  console.log(dataValidationGroups);

  const handleDeleteDataValidationGroups = async (row) => {
    console.log(row);
    try {
      const response = await axios.put(`${API}/data-validation/data_validation_groups/${row._id}`, { deleted_status: 1 }, {
        "Content-Type": 'application/json'
      })
      console.log(response);
      setDeleteModalOpen(false);
      getDataValidationGroups();
      toast.success("Data Validation Group deleted successfully");
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteModalOpen = (row) => {
    setDeleteModalOpen(true);
    setSelectedRow(row);
  }

  // Function to calculate the serial number
  const calculateSerialNumber = (index) => {
    return (
      <div>
        {(currentPage - 1) * rowsPerPage + index + 1}
      </div>
    )
  };

  const validationGroupNameColumn = (row) => {
    return (
      <div>
        {row.group_name}
      </div>
    )
  }

  const actionColumn = (row) => {
    return (
      <div className='gap-4 flex'>
        <img src={editIcon} alt="edit" className='cursor-pointer' onClick={() => navigate(`/data-validation-rules/edit/${row._id}`)} />
        <img src={deleteIcon} alt="delete" className='cursor-pointer' onClick={() => handleDeleteModalOpen(row)} />
      </div>
    )
  }

  const columns = [
    {
      name: "S.No",
      sortable: true,
      cell: (row, index) => calculateSerialNumber(index),
      width: "150px",
      center: true,
    },
    {
      name: "Name",
      sortable: true,
      cell: (row) => validationGroupNameColumn(row),
      selector: (row) => row.group_name,
      width: "800px",
      // center: true,
    },

    {
      name: "Action",
      cell: (row) => actionColumn(row),
      // width: "200px",
      center: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontSize: "0.875rem",
        backgroundColor: "#F9FAFB",
        fontWeight: 600,
        fontFamily: "poppins",
        color: "#212529",
      },
    },
    rows: {
      style: {
        // textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "0.875rem",
        borderBottom: "1px solid #DDDEE0",
        fontFamily: "poppins",
        color: "#212529",
        fontWeight: 400,
      },
    },
  };

  return (
    <div className={`${deleteModalOpen ? 'overflow-hidden h-screen' : ''} `}>
      <div className='flex justify-between mt-16 px-5 ms-[50px]'>
        <div className='text-[#212529] text-[18px] font-medium'>Validation Groups</div>
        <button className='bg-[#0078C5] px-3 py-1.5 rounded-md flex justify-center border-none items-center text-[#ffffff] gap-2 text-sm  font-normal' onClick={() => navigate('/data-validation-rules/create')}><img src={add} alt="add" />Create</button>
      </div>
      {loading ?
        <div className="flex justify-center items-center mt-28 flex-col">
          <div className="flex flex-col justify-center items-center">
            <img src={loadingGif} alt="loading" className="w-[50px] h-[65px]" />
            <h5 className="mt-5">Loading....</h5>
          </div>
        </div> :
        <div className='border border-[#DDDEE0] ms-[70px] me-5 mt-5 rounded-t-lg font-medium text-[#212529]'>
          {dataValidationGroups?.length < 9 ? (
            <DataTable
              columns={columns.filter((column) => column !== null)}
              data={dataValidationGroups}
              customStyles={customStyles}
              defaultSortFieldId={2}
            />
          ) : (
            <DataTable
              columns={columns.filter((column) => column !== null)}
              data={dataValidationGroups}
              customStyles={customStyles}
              defaultSortFieldId={2}
              pagination
              paginationPerPage={rowsPerPage}
              paginationTotalRows={dataValidationGroups.length}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
            />
          )}
        </div>}
      {deleteModalOpen ?
        <DeleteModal setModalOpen={setDeleteModalOpen} modalOpen={deleteModalOpen} handleDelete={handleDeleteDataValidationGroups} selectedRow={selectedRow} /> : ''
      }
    </div>
  )
}

export default DataValidationGroupsList
