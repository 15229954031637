import React from 'react';

const RegisterPopup = ({ modalRef, handleCancel, handleRegister }) => {
  return (
    <div>
      <div className='flex items-center justify-center w-full h-screen fixed top-0 left-0 backdrop-brightness-75 z-40'>
        <div ref={modalRef} className='bg-[#ffffff] w-[520px] rounded-lg flex flex-col p-5 text-center'>
          <div className='text-[#0078C5] text-xl font-medium my-2.5 '>You are not registered with us!!</div>
          <div>Would you like to create an account on Ignitho Data Accelerator ?</div>
          <div className='flex gap-2.5 justify-center text-sm mt-3'>
            <button className='py-1.5 px-2 border-none bg-[#888888] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleCancel()}>Not Now</button>
            <button className='py-1.5 px-2 border-none bg-[#0078C5] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleRegister()}>Create</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterPopup
