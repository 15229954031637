import React, { useState, useRef, useEffect } from 'react';

import downArrow from "../../../assets/downArrow.svg";

const CustomDropdown = ({ options, placeholder, onSelect, width, selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selected);
  const dropdownRef = useRef(null);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`${width} relative w-full`}>
      <div
        className={`${width} border border-[#B6B6B6] rounded-md shadow-sm bg-white cursor-pointer text-[#212529]`}
        onClick={toggleDropdown}
      >
        <div className="px-3 py-2 flex justify-between items-center">
          <span className={selectedOption ? '' : 'text-gray-400'}>
            {selectedOption || placeholder}
          </span>
          <span className={`transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
            <img src={downArrow} alt="img" />
          </span>
        </div>
      </div>
      {isOpen && (
        <ul className={`${width} absolute z-10 w-full bg-white border border-[#B6B6B6] rounded-md mt-1 shadow-lg`}>
          {options.map((option) => (
            <li
              key={option}
              className="px-3 py-2 hover:bg-[#F1F1F1] cursor-pointer text-[#212529]"
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;