import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
// import { AuthProvider } from './AuthContext/AuthContext';
import { AuthProvider } from './hooks/useAuth';
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from './authConfig';

// const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <AuthProvider>
      {/* <MsalProvider instance={msalInstance}> */}
      <App />
      {/* </MsalProvider> */}
    </AuthProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
