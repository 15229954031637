import React, { useState } from 'react';

import run from "../../../assets/RunOrScheduleAssets/run.png";
import schedule from "../../../assets/RunOrScheduleAssets/schedule.png";
import ActionCard from './ActionCard';
import ScheduleFrequency from './ScheduleFrequency';

const RunOrSchedule = (props) => {
  const { handleTransferData, clearStates, setShowRunOrSchedule, setDestinationStepper } = props;
  const [showScheduleOptions, setShowScheduleOptions] = useState(false);

  const handleShowScheduleOptions = () => {
    setShowScheduleOptions(true);
  }

  return (
    <>
      {!showScheduleOptions ? (
        <div className="flex justify-center space-x-8 mt-16">
          <ActionCard
            icon={run}
            title="Run Now"
            description={["Execute the process instantly", "with a single click."]}
            onClick={handleTransferData}
          />
          <ActionCard
            icon={schedule}
            title="Schedule"
            description={["Set a future date and time", "to run the process automatically."]}
            onClick={handleShowScheduleOptions}
          />
        </div>
      ) : (
        <ScheduleFrequency
          setShowScheduleOptions={setShowScheduleOptions}
          clearStates={clearStates}
          setShowRunOrSchedule={setShowRunOrSchedule}
          setDestinationStepper={setDestinationStepper}
        />
      )}
    </>
  );
};

export default RunOrSchedule;
