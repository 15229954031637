import React from 'react';

import closeIcon from "../../../assets/closeIcon.svg";
import greenTickBig from "../../../assets/greenTickBig.svg";

const Success = ({ clearStates, message }) => {

  return (
    <div className="flex justify-center mt-16 w-full">
      <div className="bg-[#eff0f3] flex flex-col w-[480px] items-center gap-3 rounded-xl p-5 relative">
        <img src={closeIcon}
          onClick={clearStates}
          alt="close"
          className='cursor-pointer absolute right-5 top-5'
        />
        <img src={greenTickBig} alt="tick" className='w-14 h-14' />
        <div className="text-3xl font-medium">Success</div>
        <div className="text-xs max-w-[200px] text-center">
          {message}
        </div>
      </div>
    </div>
  )
}

export default Success