import React, { useEffect, useState } from "react";
import DbTablePreview from "../../ReUseable/DbTablePreview";
import database from '../../../assets/databaseIcon.svg'
import axios from "axios";
import { API } from "../../../global";
import SheetLists from "../../ReUseable/SheetLists";
import { VscChromeClose } from 'react-icons/vsc';
import loadingGif from "../../../assets/loading.gif";
import toast from "react-hot-toast";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const ConnectToDb = ({ setSourceType, setPreviewStepper, setSheetNames, setSheetData, setFileUploaded,
  setSingleSheet, setTableshow, setSname, SheetNames, sheet, setSheet, sname, sheetData, tableshow,
  handlePreview, checkAll, setCheckAll, setSelectedValues, selectedValues, clearStates, setThankyou,
  setCheckboxes, checkboxes, uploadCsvFile, uploadExcelFile }) => {
  const [hideDbform, setHideDbform] = useState(false);
  const [rawDataFromDb, setRawDataFromDb] = useState(null);
  const [selectedDb, setSelectedDb] = useState("MySQL");
  const [databaseHost, setDatabaseHost] = useState("");
  const [portNumber, setPortNumber] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDbName, setShowDbName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [databaseNameError, setDatabaseNameError] = useState(false);
  const [databaseHostError, setDatabaseHostError] = useState(false);
  const [userNameError, setuserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [portNumberError, setPortNumberError] = useState(false);

  const [messageIndex, setMessageIndex] = useState(0);
  const messages = ["Uploading Data...", "Analysing...", "Training Model..."];
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    let timeout;
    if (uploading && messageIndex < messages.length - 1) {
      timeout = setTimeout(() => {
        setMessageIndex((prevIndex) => prevIndex + 1);
      }, 2000); // Change message every 2 seconds
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [uploading, messageIndex]);

  const handleFinish = () => {
    setThankyou(true);
  };

  const handleChange = (e) => {
    if (e.target.id === "databaseHost") {
      setDatabaseHost(e.target.value);
      setDatabaseHostError(false);
    }
    if (e.target.id === "password") {
      setPassword(e.target.value);
      setPasswordError(false);
    }
    if (e.target.id === "databaseName") {
      setDatabaseName(e.target.value);
      setDatabaseNameError(false);
    }
    if (e.target.id === "userName") {
      setUserName(e.target.value);
      setuserNameError(false);
    }
    if (e.target.id === "portNumber") {
      setPortNumber(e.target.value);
      setPortNumberError(false);
    }
  };

  const handleCancel = () => {
    setSourceType(true);
  };

  const validate = () => {
    let error = false;
    if (!databaseHost) {
      error = true;
      setDatabaseHostError(true);
    } if (!databaseName) {
      error = true;
      setDatabaseNameError(true);
    } if (!userName) {
      error = true;
      setuserNameError(true);
    } if (!password) {
      error = true;
      setPasswordError(true);
    } if (!portNumber) {
      error = true;
      setPortNumberError(true);
    }

    return error;
  };

  const handleConnectToDatabase = async () => {
    if (!validate()) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      setMessageIndex(0);
      setHideDbform(true);
      setLoading(true);
      setShowDbName(true);
      if (selectedDb === 'MySQL') {
        try {
          const dbDetails = {
            databaseHost: databaseHost,
            portNumber: portNumber,
            databaseName: databaseName,
            userName: userName,
            password: password
          }
          const response = await axios.post(`${API}/connect_database/mySql`, dbDetails, config);
          if (response.data.success) {
            const dbDetailsDestination = {
              "destinationType": "MySQL",
              "destinationDefinitionId": "ca81ee7c-3163-4246-af40-094cc31e5e42",
              "connectionConfiguration": {
                "ssl": true,
                "port": 3306,
                "tunnel_method": {
                  "tunnel_method": "NO_TUNNEL"
                },
                "host": databaseHost,
                "database": databaseName,
                "username": userName,
                "password": password
              },
            }
            await axios.post(`${API}/airbyte/create-destination`, dbDetailsDestination, config);
            setRawDataFromDb(response.data.result);
            const transformedObject = transformObject(response.data.result);
            setSheetData(transformedObject);
            setSheet(Object.keys(transformedObject)[0]);
            setSheetNames(Object.keys(transformedObject));
            setSname(true);
            setLoading(false);
            setPreviewStepper(true);
          } else {
            toast.error('Sorry, something went wrong, please try again');
            setHideDbform(false);
            setLoading(false);
            setShowDbName(false);
          }
        } catch (error) {
          console.log(error);
          toast.error(error);
          setHideDbform(false);
          setLoading(false);
          setShowDbName(false);
        }
      }
      if (selectedDb === 'MongoDB') {
        try {
          const dbDetails = {
            clusterUrl: databaseHost,
            portNumber: portNumber,
            databaseName: databaseName,
            userName: userName,
            password: password
          }
          const response = await axios.post(`${API}/connect_database/mongoDb`, dbDetails, config);
          if (response.data.success) {
            const dbDetailsDestination = {
              "destinationType": 'MongoDB',
              "destinationDefinitionId": "8b746512-8c2e-6ac1-4adc-b59faafd473c",
              "connectionConfiguration": {
                "auth_type": {
                  "authorization": "login/password",
                  "username": userName,
                  "password": password
                },
                "instance_type": {
                  "instance": "atlas",
                  "cluster_url": databaseHost
                },
                "tunnel_method": {
                  "tunnel_method": "NO_TUNNEL"
                },
                "database": databaseName
              },
            }
            await axios.post(`${API}/airbyte/create-destination`, dbDetailsDestination, config);
            setRawDataFromDb(response.data.result);
            const transformedObject = transformObject1(response.data.result);
            setSheetData(transformedObject);
            setSheet(Object.keys(transformedObject)[0]);
            setSheetNames(Object.keys(transformedObject));
            setSname(true);
            setLoading(false);
            setPreviewStepper(true);
          } else {
            toast.error('Sorry, something went wrong, please try again');
            setHideDbform(false);
            setLoading(false);
            setShowDbName(false);
          }
        } catch (error) {
          console.log(error);
          toast.error(error);
          setHideDbform(false);
          setLoading(false);
          setShowDbName(false);
        }
      }
    }
  };

  const transformObject = (inputData) => {
    let outputData = {};

    // Filter out keys starting with '_airbyte_raw_'
    let filteredData = {};
    for (let key in inputData) {
      if (inputData.hasOwnProperty(key) && !key.startsWith('_airbyte_raw_')) {
        filteredData[key] = inputData[key];
      }
    }

    // Iterate over each key in the filtered input data
    for (let key in filteredData) {
      if (filteredData.hasOwnProperty(key)) {
        let section = filteredData[key];
        let sectionData = [];

        // Extract column names
        if (section.columns && section.columns.length > 0) {
          let filteredColumns = section.columns.filter(column => column !== "my_row_id");
          sectionData.push(filteredColumns);
        }

        // Extract records
        if (section.records && section.records.length > 0) {
          section.records.forEach(record => {
            let recordValues = [];
            Object.values(record).forEach(value => {
              recordValues.push(value);
            });
            sectionData.push(recordValues);
          });
        }

        // Add section data to outputData
        outputData[key] = sectionData;
      }
    }
    return outputData;
  }

  const transformObject1 = (obj) => {
    const transformedObj = {};

    // Filter out keys starting with '_airbyte_raw_'
    let filteredData = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && !key.startsWith('_airbyte_raw_')) {
        filteredData[key] = obj[key];
      }
    }

    for (const key in filteredData) {
      if (filteredData.hasOwnProperty(key)) {
        transformedObj[key] = [];

        // If the array in the original object is not empty
        if (filteredData[key].length > 0) {
          // Extract keys
          const keysSet = new Set();

          // Extract keys from all objects in the array
          filteredData[key].forEach(item => {
            Object.keys(item).forEach(k => keysSet.add(k));
          });

          const keys = Array.from(keysSet);
          transformedObj[key].push(keys);

          // Extract values
          filteredData[key].forEach(item => {
            const values = keys.map(k => item[k]);
            transformedObj[key].push(values);
          });

          // Extract values    
          filteredData[key].forEach(item => {
            const values = keys.map(k => item[k] !== undefined ? item[k] : '');
            // const values = Object.values(item);
            transformedObj[key].push(values);
          });
        } else {
          // If the array in the original object is empty, add an empty array for that key
          transformedObj[key].push([]);
        }
      }
    }
    return transformedObj;
  }

  const displayedElements = selectedValues.slice(
    currentIndex,
    currentIndex + 7
  );

  const handleForwardClick = () => {
    if (currentIndex < selectedValues.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBackwardClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleSingleSheet = (e) => {
    setSingleSheet(true);
    setTableshow(true);
    setSname(false);
  };

  const handleSelectAll = (checked) => {
    setCheckAll(!checkAll);
    if (checked) {
      setCheckboxes(SheetNames);
    } else {
      setCheckboxes([]);
    }
  };

  const handleCheckboxChange = (value, checked) => {
    if (checked) {
      setCheckboxes([...checkboxes, value]);
    } else {
      setCheckboxes(checkboxes.filter((item) => item !== value));
    }
  };

  const handleSubmit = () => {
    setSelectedValues(checkboxes);
    setSingleSheet(true);
    setTableshow(true);
    setSname(false);
    setSheet(checkboxes[0]);
    setShowDbName(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  console.log(sheetData, 'sheet data');

  return (
    <div className="flex mt-2 connect-db-container">
      {!hideDbform ?
        <div className="flex connect-form my-10">
          <div className="connect-db mr-5">
            <div className="font-medium text-lg">Connect</div>
            <div className="flex flex-col form-container gap-2 font-medium text-sm mb-3">
              <label htmlFor="DatabaseType" className="mt-2">
                Database Type
              </label>
              <select
                name="DatabaseType"
                value={selectedDb}
                onChange={(e) => setSelectedDb(e.target.value)}
                className="border border-1 border-[#B6B6B6] w-full px-3 py-2 rounded-lg text-[#212529] outline-0"
              >
                <option value="MySQL" selected>MySQL</option>
                {/* <option value="MariaDB">MariaDB</option> */}
                <option value="MongoDB">MongoDB</option>
              </select>

              <label htmlFor="Databasehost" className="mt-2">
                Database Host
              </label>
              <input
                type="text" id="databaseHost"
                value={databaseHost}
                onChange={handleChange}
                placeholder="Eg: rds.us-east-1.rds.amazonaws.com"
                className="border border-1 border-[#B6B6B6] w-full px-3 py-2 rounded-lg text-[#212529] outline-0"
              />
              {databaseHostError ? (
                <div className='text-red text-xs'>Database Host is required</div>
              ) : null}

              <label htmlFor="portnumber" className="mt-2">
                Port Number
              </label>
              <input
                type="number" id="portNumber" step="any"
                value={portNumber}
                onChange={handleChange}
                placeholder="Eg: 3306"
                className="border border-1 border-[#B6B6B6] w-full px-3 py-2 rounded-lg text-[#212529] outline-0"
              />
              {portNumberError ? (
                <div className='text-red text-xs'>Port Number is required</div>
              ) : null}

              <label htmlFor="databaseName" className="mt-2">
                Database Name
              </label>
              <input
                type="text" id="databaseName"
                value={databaseName}
                onChange={handleChange}
                placeholder="DB Name"
                className="border border-1 border-[#B6B6B6] w-full px-3 py-2 rounded-lg text-[#212529] outline-0"
              />
              {databaseNameError ? (
                <div className='text-red text-xs'>Database Name is required</div>
              ) : null}

              <label htmlFor="username" className="mt-2">
                Username
              </label>
              <input
                type="text" id="userName"
                value={userName}
                onChange={handleChange}
                placeholder="Eg: DBUser"
                className="border border-1 border-[#B6B6B6] w-full px-3 py-2 rounded-lg text-[#212529] outline-0"
              />
              {userNameError ? (
                <div className='text-red text-xs'>User name is required</div>
              ) : null}

              <label htmlFor="password" className="mt-2">
                Database Password
              </label>
              <div className='flex w-full rounded-md border border-[#B6B6B6]'>
                <input
                  type={showPassword ? "text" : "password"} id="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="********"
                  className="border-none w-[95%] px-3 py-2 rounded-lg text-[#212529] outline-0"
                />
                <span onClick={() => togglePasswordVisibility()} className='cursor-pointer flex justify-center items-center px-2 bg-[#E7E7E7]'>
                  {!showPassword ? <BsEyeSlash /> : <BsEye />}
                </span>
              </div>

              {passwordError ? (
                <div className='text-red text-xs'>Password is required</div>
              ) : null}

              <div className="flex justify-end gap-2 mb-3 mt-2">
                <button
                  type="button"
                  className="bg-[#BFBFBF] px-3 py-1 w-20 text-white rounded text-sm hover:bg-[#A5A5A5]"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="bg-[#0062A1] px-3 py-1 w-20 text-white rounded text-sm hover:bg-[#0062A1]"
                  onClick={() => handleConnectToDatabase()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className="px-5 flex flex-col flex-2 border-l-[1px] border-[#CCCCCC] instruction-section ">
            <div className="">
              {" "}
              <div className="font-medium text-lg ">Instructions</div>
              <div className="text-sm font-normal text-[#212529] mt-2 flex flex-col gap-7">
                <p>
                  • Select the appropriate "Database Type" from the drop-down
                  menu. Options may include MySQL, SQL, MongoDB, PostgreSQL.
                </p>
                <p>
                  {" "}
                  • Enter the "Database Host" provided by your database provider
                  in the "Database Host" field. This could be an IP address or
                  domain name.
                </p>
                <p>
                  • Enter the "Port Number" provided by your database provider in
                  the "Port Number" field. This is typically a number between 1
                  and 65535.
                </p>
                <p>
                  • Enter your "Username" in the "Username" field. This should be
                  the username provided to you by your database provider.
                </p>
                <p>
                  • Enter your "Database Name" in the "Database Name"
                  field. This should be the database name that you want
                  to connect.{" "}
                </p>
                <p>
                  • Enter your "Database Password" in the "Database Password"
                  field. This should be the password provided to you by your
                  database provider.{" "}
                </p>
                <p>
                  • Ensure that all fields have been entered correctly by
                  double-checking the values entered in each field.
                </p>
                <p>
                  • Once you have confirmed that all fields have been entered
                  correctly, click the "Next" button to proceed.
                </p>
              </div>
            </div>
          </div>
        </div>
        : ''}

      {showDbName ?
        <div className="my-10">
          <div className="font-medium text-lg text-lite-black">
            Connect
          </div>
          <div className="p-5 flex items-center my-5 gap-9 h-20 bg-white rounded-lg shadow-[0px_0px_10px_0px_rgba(0,0,0,0.16)]">
            <span>
              <img src={database} alt="db" className='w-10 h-10 object-cover' />
            </span>
            <div className="flex flex-col text-xs text-lite-black font-semibold">
              {databaseName}
              <span>{selectedDb}</span>
            </div>
            <span className="ml-10" onClick={() => clearStates()}>
              <VscChromeClose color="#707070" fontSize="15px" className='cursor-pointer' />
            </span>
          </div>
        </div>
        : ''}

      {tableshow ?
        <div className="my-10 z-20">
          <div className="text-lg font-medium text-lite-black">
            Connect
          </div>
          <div className="flex justify-between mt-5 p-5 z-30 h-20 gap-[90px] bg-white rounded-lg hover:w-fit drop-shadow-[0_0_10px_#00000029] overflow-hidden transition-all w-20 delay-[200ms]">
            <div className='flex gap-5 items-center'>
              <img src={database} alt="db" className='w-10 h-10 object-cover' />
              <div className="flex flex-col text-xs text-lite-black font-semibold">
                {databaseName}
                <span>{selectedDb}</span>
              </div>
            </div>
            <div className='flex items-center' onClick={() => clearStates()}>
              <VscChromeClose color="#707070" fontSize="15px" className="cursor-pointer" />
            </div>
          </div>
        </div>
        : ''}

      {loading ?
        <div className="flex-1 my-10 border-l ms-5 border-[#CCCCCC] h-80">
          <div className="px-5 text-lg font-medium">
            Preview
          </div>
          <div className="flex justify-center flex-col items-center pt-20">
            <img src={loadingGif} alt="uploading" className="w-[50px] h-[65px]" />
            <h5 className="analyse mt-5">Analysing...</h5>
          </div>
        </div>
        : sname ?
          <div className="flex-1 ms-5 my-10">
            <SheetLists
              checkboxes={checkboxes} handleSubmit={handleSubmit}
              handleSingleSheet={handleSingleSheet} handleSelectAll={handleSelectAll}
              handleCheckboxChange={handleCheckboxChange} setSheet={setSheet}
              fileType="MySQL" icon={database} setSingleSheet={setSingleSheet}
              sheetNames={SheetNames} checkAll={checkAll} />
          </div>
          : ''}

      {tableshow ?
        <div className="z-0 absolute left-[11rem] right-5 pb-5 my-10">
          <DbTablePreview sheetData={sheetData} sheet={sheet} setSheetData={setSheetData}
            setSheet={setSheet} selectedValues={selectedValues} rawDataFromDb={rawDataFromDb}
            setSelectedValues={selectedValues} handlePreview={handlePreview} setThankyou={setThankyou}
            handleCancel={handleCancel} handleFinish={handleFinish} fileName={databaseName}
            fileType={selectedDb} icon={database} checkboxes={checkboxes} setUploading={setUploading}
            setCheckboxes={setCheckboxes} currentIndex={currentIndex} setSingleSheet={setSingleSheet}
            displayedElements={displayedElements} handleBackwardClick={handleBackwardClick}
            handleForwardClick={handleForwardClick} setFileUploaded={setFileUploaded}
            setCheckAll={setCheckAll} setTableshow={setTableshow} setSname={setSname}
            setShowDbName={setShowDbName} selectedDb={selectedDb} uploadCsvFile={uploadCsvFile}
            uploadExcelFile={uploadExcelFile} />
        </div>
        : ''
      }

      {uploading ?
        <div className="my-10 w-full flex justify-center">
          <div className="flex justify-center flex-col mt-10 items-center">
            <img src={loadingGif} alt="uploading" className="w-[50px] h-[65px]" />
            <h5 className="analyse mt-5">{messages[messageIndex]}</h5>
          </div>
        </div> : ''
      }
    </div>
  );
};

export default ConnectToDb;