import React, { useEffect, useState } from 'react';
import { API } from "../../global";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import homeBg from "../../assets/homeBg.png";
import Nav from '../ReUseable/Navbar/Nav';
import backArrow from "../../assets/backArrow.svg";
import validator from "email-validator";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState("");

  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is a required field.")
        .test(
          "email-valid",
          "Please, use standard email format. (for e.g., username@example.com)",
          (value) => validator.validate(value)
        ),
      name: yup
        .string()
        .required("Full Name is a required field.")
        .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed"),
      companyName: yup.string().required("Company Name is a required field"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const emailValue = localStorage.getItem('emailData');
    if (emailValue) {
      setValue("email", emailValue);
    }
    // eslint-disable-next-line
  }, [])

  const onSubmit = (data) => {
    setLoading(true);
    localStorage.removeItem('emailData');
    const obj = {
      company_name: data.companyName,
      name: data.name,
      email: data.email
    }
    fetch(`${API}/admin/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          navigate('/verify-email');
        } else {
          if (response.error.msg === ('We regret to inform you that the requested business email address is already in use; please contact our support team for further assistance.'
            || 'Your company is already registered with us. Please proceed to log in, or alternatively, reach out to your administrator or our support team for further assistance.')) {
            navigate('/resend-verify-email');
            localStorage.setItem("email", data.email);
          }
          else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch((error) => {
        toast.error(error);
        console.log(error);
        setLoading(false);
      });
  }

  // const handleGetTenant = (email) => {
  //   fetch(`${API}/admin/tenant/get-tenant-details`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ email: email }),
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       console.log(response, 'response');
  //       // setLoading(false);
  //       // if (response.success) {
  //       //   if (response.result) {
  //       //     navigate('/login');
  //       //     console.log(response.result);
  //       //     localStorage.setItem('tenantId', response.result._id);
  //       //     localStorage.setItem('emailData', email)
  //       //   }
  //       // } else {
  //       //   if (response.error.msg === "Tenant Not Found, please contact admin") {
  //       //     // setShowPopUp(true);
  //       //   }
  //       // }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // }

  const handleBack = () => {
    navigate('/');
  }

  return (
    <div className='bg-[#E5F8F8] h-screen w-full relative'>
      <Nav showWorkspaceDetails={false} />

      <div className='flex w-full h-full'>
        <div className='h-full w-[39.7%] pt-[50px] flex items-center justify-center z-20 relative'>
          <img src={homeBg} alt="img" className='w-full absolute left-0 top-0 h-screen' />
          <div className='px-[48px] text-white absolute'>
            <div className='font-medium text-5xl pb-5 flex justify-center'>Ignitho<br /> Data Accelerator</div>
            <div className='text-lg py-5 text-center'>Unlock the power of data acceleration with IDA</div>
            <div className='pt-5 pb-3 text-sm text-center'>The cutting-edge data accelerator solution designed to supercharge your organization's data-driven operations. </div>
            <div className='py-3 text-sm text-center'>Whether you're a small startup or a large enterprise, revolutionizes the way you handle data, empowering you to make faster, smarter decisions.</div>
            <div className='py-3 text-sm text-center'>Experience the future of data acceleration with Ignitho Data Product. Contact us today for a personalized demo and see how Ignitho Data Product can transform your organization's data capabilities.</div>
          </div>
        </div>

        <div className='w-[60.3%] relative'>
          <div className='bg-[#C7C7C7] absolute rounded-full w-[30px] h-[30px] 
          cursor-pointer flex items-center justify-center mt-[70px] ms-5 hover:bg-[#0083BF]' onClick={handleBack}>
            <img src={backArrow} alt="back" />
          </div>
          <div className='flex items-center justify-center h-full pt-[50px]'>
            <div className='w-[352px] py-10 bg-white flex rounded-[20px] flex-col drop-shadow-[0_0_30px_#00578F1A]'>
              <div className='text-blue-1 text-xl text-center mb-6'>
                Register
              </div>
              <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => e.key === 'Enter' ? handleSubmit(onSubmit) : ''}>
                <div className='flex flex-col gap-5 mx-10 text-lite-black text-sm'>
                  <div className='flex flex-col gap-1.5'>
                    <label className='font-medium' htmlFor='fullName'>
                      Full Name
                    </label>
                    <input type="text" placeholder="Enter your full name" id="fullName"
                      className='bg-[#F7F7F7] focus:outline-0 focus:border-[#86b7fe] focus:border focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#F7F7F7] border px-3 py-2 rounded-md'
                      {...register("name")} autoFocus />
                    {errors?.name?.message ? (
                      <div className='text-red text-xs'>{errors.name.message}</div>
                    ) : null}
                  </div>
                  <div className='flex flex-col gap-1.5'>
                    <label className='font-medium' htmlFor='email'>
                      E-Mail ID
                    </label>
                    <input type="email" placeholder="Enter your E-Mail ID" id="email"
                      className='bg-[#F7F7F7] focus:outline-0 focus:border-[#86b7fe] focus:border focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#F7F7F7] border px-3 py-2 rounded-md'
                      {...register("email")}
                    />
                    {errors?.email?.message ? (
                      <div className='text-red text-xs'>{errors.email.message}</div>
                    ) : null}
                  </div>
                  <div className='flex flex-col gap-1.5'>
                    <label className='font-medium' htmlFor='companyName'>
                      Company Name
                    </label>
                    <input type="text" placeholder="Enter your company name" id="companyName"
                      className='bg-[#F7F7F7] focus:outline-0 focus:border-[#86b7fe] focus:border focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#F7F7F7] border px-3 py-2 rounded-md'
                      {...register("companyName")} />
                    {errors?.companyName?.message ? (
                      <div className='text-red text-xs'>{errors.companyName.message}</div>
                    ) : null}
                  </div>
                  <div className='flex justify-center gap-2.5'>
                    <button type="submit" className='bg-blue-1 text-white w-full rounded-md py-1.5 hover:bg-[#00578F]' disabled={loading}>
                      {loading ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
