import React, { useEffect, useState } from 'react';
import wrongIcon from "../../../assets/wrongIcon.svg";
import upArrow from "../../../assets/upArrow.svg";
import downArrow from "../../../assets/downArrow.svg";
import error from "../../../assets/error.svg";
import greenTick from "../../../assets/greenTick.svg";

const Accordion = ({ column, index, noOfColumns, sheet }) => {
  const [showAccordion, setShowAccordion] = useState(false);

  useEffect(() => {
    setShowAccordion(false);
  }, [sheet])


  const stringWithBoldNumber = (originalString) => {
    // Regular expression to find a number in the string
    const numberRegex = /\d+(\.\d+)?/;

    // Extract the number from the string
    const match = originalString.match(numberRegex);
    const foundNumber = match ? match[0] : null;

    // Split the original string based on the found number
    const parts = originalString.split(numberRegex);

    // Create a new array with JSX elements, bold the number
    const elements = parts.map((part, index) => (
      <span key={index}>
        {index % 2 === 0 ? part : <span className='font-bold'>{foundNumber}</span>}
      </span>
    ));

    return <>{elements}</>;
  };

  return (
    <div key={index}>
      <button key={index} className={`${showAccordion && column.validation_data?.length >= 1 ? 'bg-[#F1F1F1]' : 'bg-[#F9FAFB]'} ${column.data_integrity_error >= 1 ? ' border-t-[#FF0900] border-r-[#FF0900] border-l-[#FF0900] border-b-[#FF0900]' : 'border-t-[#DDDEE0] border-r-[#DDDEE0] border-l-[#DDDEE0]'} ${((index === noOfColumns - 1) && column.data_integrity_error >= 1 && !showAccordion) ? 'rounded-b-[10px] border-b-[#FF0900]' : ''}  flex justify-between border items-center w-full text-[#444] cursor-pointer py-[14px] px-5 w-100  outline-none transition-[0.4s] text-sm font-semibold`} onClick={() => setShowAccordion(!showAccordion)}>
        <span>{column?.column_name}</span>
        {column.validation_data?.length >= 1 ?
          <span className='flex gap-3'>{column?.data_integrity_error >= 1 ? <><img src={error} alt="error" /><span> Data Integrity Error : {" "}{column.data_integrity_error} </span></> : ''}<img src={`${showAccordion ? upArrow : downArrow}`} alt="uparrow" /></span> : <span className=''>Skipped</span>}
      </button>
      {column.validation_data?.length >= 1 ?
        <div className={`${showAccordion ? 'max-h-fit' : ''} duration-300 ease-out max-h-0 overflow-hidden transition-max-height bg-white border-x border-x-[#DDDEE0] ${index === noOfColumns - 1 ? 'border-b border-b-[#DDDEE0]' : ''}`}>
          <div className='flex justify-center'>
            <table className='w-[70%] text-[#212529] text-sm table-fixed'>
              <thead>
                <tr className='font-semibold'>
                  <th className='text-center py-4 w-36'>Expectations</th>
                  <th className='text-center py-4'>Status</th>
                  <th className='text-center py-4 w-[430px]'>Description</th>
                </tr>
              </thead>
              <tbody>
                {column.validation_data.map((obj, index) => (
                  <tr className='font-regular' key={index}>
                    <td className='text-center align-top py-4 w-36'>
                      {obj.Operation} <span className='font-semibold'>{obj.column_name1 && obj.column_name1 !== 'Null' ? <>{obj.column_name1}{" "}</> : ''}</span><span className='font-semibold'>{obj.min_value === 'NaN' ? '' : <>{" "}{obj.min_value}</>}{obj.max_value === 'NaN' ? '' : <>{" "}{obj.max_value}</>}</span>
                    </td>
                    <td className='text-center align-top py-4'>
                      {obj.constraint_status === 'Success' ?
                        <span className='relative group inline-block'>
                          <img src={greenTick} alt="greentick" className='w-4 h-4 mx-auto' />
                          <span className='w-28 group-hover:visible invisible border-[#1DA64D] bg-[#E9FEF0] text-lite-black absolute bottom-[150%] left-1/2 ml-[-56px] z-30 rounded-md py-1 after:content-[""] after:absolute after:top-full after:left-1/2 after:border-[5px] after:border-t-[#1DA64D] after:border-transparent after:ml-[-5px]'>Success</span>
                        </span> :
                        <span className='relative group inline-block'>
                          <img src={wrongIcon} alt="error" className='w-4 h-4 mx-auto' />
                          <span className='w-28 group-hover:visible invisible bg-[#FFE1E1] text-lite-black border border-red absolute bottom-[150%] left-1/2 ml-[-56px] z-30 rounded-md py-1 after:content-[""] after:absolute after:top-full after:left-1/2 after:border-[5px] after:border-t-red after:border-transparent after:ml-[-5px]'>Failure</span>
                        </span>}
                    </td>
                    <td className='text-center align-top py-4 w-[430px]'>
                      {obj.constraint_status === 'Success' && obj.constraint_message === '' ? 'constraint satisfied' : ''}
                      {obj.constraint_status === 'Failure' && obj.Operation === 'Unique' && (obj.rule_type === 'string' || obj.rule_type === 'numeric') ? "The column does not contain Unique values" : ''}
                      {obj.constraint_status === 'Failure' && obj.Operation === 'Not Null' && (obj.rule_type === 'string' || obj.rule_type === 'numeric') ? "The column contains Null values" : ''}
                      {obj.constraint_status === 'Failure' && (obj.Operation === 'Min Length' || obj.Operation === 'Max Length') && obj.rule_type === 'string' ? stringWithBoldNumber(obj.constraint_message) : ''}
                      {obj.constraint_status === 'Failure' && obj.Operation === 'Email Check' && obj.rule_type === 'string' ? 'The column contains values that is not Email' : ''}
                      {obj.constraint_status === 'Failure' && (obj.Operation === 'Less Than' || obj.Operation === 'Less Than or Equalto') && obj.rule_type === 'numeric' ? <>The column <span className='font-bold'>{obj.column_name}</span> contains values greater than column <span className='font-bold'>{obj.column_name1}</span></> : ''}
                      {obj.constraint_status === 'Failure' && (obj.Operation === 'Greater Than' || obj.Operation === 'Greater Than or Equalto') && obj.rule_type === 'numeric' ? <>The column <span className='font-bold'>{obj.column_name}</span> contains values lesser than column <span className='font-bold'>{obj.column_name1}</span></> : ''}
                      {obj.constraint_status === 'Failure' && (obj.Operation === 'Min Value' || obj.Operation === 'Max Value') && obj.rule_type === 'numeric' ? stringWithBoldNumber(obj.constraint_message) : ''}
                      {obj.constraint_status === 'Failure' && obj.Operation === 'Non negative' && obj.rule_type === 'numeric' ? "The column contains Negative values" : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> : ''}
    </div>
  )
}

export default Accordion;