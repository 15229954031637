import React from 'react';

// import { IoIosArrowForward } from "react-icons/io";

const WorkflowStepper = ({ previewStepper, destinationTableStepper, dataValidationStepper, autoMappingStepper, reviewStepper, destinationStepper }) => {

  return (
    <div>
      {/* <div className="flex gap-5 text-lg font-medium leading-9 text-lite-black">
        Workflow
        <IoIosArrowForward style={{ color: "#CECECE", marginTop: "7px", fontSize: "1.1em" }} />
        Map source target
      </div> */}

      <div className="w-full flex justify-center mt-10">
        <ul className="progressbar_workflow font-medium text-sm text-[#616161]">
          <li className={`${destinationStepper ? 'green' : 'active'}`}>Source</li>
          <li className={`${previewStepper && destinationStepper ? 'green' : previewStepper ? 'active' : ''}`}>Preview</li>
          <li className={`${destinationTableStepper && destinationStepper ? 'green' : destinationTableStepper ? 'active' : ''}`}>Destination Table</li>
          <li className={`${dataValidationStepper && destinationStepper ? 'green' : dataValidationStepper ? 'active' : ''}`}>Data Validation</li>
          <li className={`${autoMappingStepper && destinationStepper ? 'green' : autoMappingStepper ? 'active' : ''}`}>Auto Mapping</li>
          <li className={`${reviewStepper && destinationStepper ? 'green' : reviewStepper ? 'active' : ''}`}>Review</li>
          <li className={`${destinationStepper ? 'green' : ''}`}>Destination</li>
        </ul>
      </div>
    </div>
  )
}

export default WorkflowStepper