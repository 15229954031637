import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Layout from "./Layout";

export const ProtectedRoutes = () => {
  const { user } = useAuth();

  return (
    user?.token ?
      <Layout>
        <Outlet />
      </Layout>
      : <Navigate to="/" />
  )
};