import React, { useEffect, useState } from 'react';
import { PiArrowRightBold } from "react-icons/pi";
import add from "../../../assets/plusWhite.svg";
import deleteIcon from "../../../assets/delete.svg";
import { v4 as uuid } from 'uuid';
import { API } from '../../../global';
import axios from "axios";
import Dropdown from '../../ReUseable/Dropdown/Dropdown';
import toast from 'react-hot-toast';
import loadingGif from "../../../assets/loadingGif.gif";
import DataValidationResponse from './DataValidationResponse';
import Loader from "../../../assets/Mask Group 6.svg";
import DeleteModal from '../../ReUseable/Modals/DeleteModal';
import backWhite from "../../../assets/backWhite.svg";
import LeftArrow from "../../../assets/leftArrow.svg";
import RightArrow from "../../../assets/rightArrow.svg";
import disableLeft from "../../../assets/disableLeft.svg";
import disableRight from "../../../assets/disableRight.svg";
import { BlobServiceClient } from '@azure/storage-blob';
import { useAuth } from '../../../hooks/useAuth';

const DataValidation = ({ sheetData, fileName, clearStates, sheet, setDataValidationStepper,
  handleMapping, deleteModalOpen, selectedValues, currentIndex, setDeleteModalOpen, setSheet,
  sheetNames, setDataValidation, setSheetPreview, displayedElements, setTableShow,
  handleBackwardClick, handleForwardClick, checkboxes, excelFile }) => {
  const [validationGroupName, setValidationGroupName] = useState('');
  const [dataValidationGroups, setDataValidationGroups] = useState([]);
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRules, setLoadingRules] = useState(false);
  const [dataValidationResponse, setDataValidationResponse] = useState(false);
  // const [validationGroupId, setValidationGroupId] = useState("");
  const [singleSheetValidationResponseData, setSingleSheetValidationResponseData] = useState([]);
  const [applyValidationToAllSheets, setApplyValidationToAllSheets] = useState(false);
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [mappingDropdownVisible, setMappingDropdownVisible] = useState(false);
  const { user } = useAuth();

  const ruleTypes = ['numeric', 'string'];
  const numericOperationTypes = ['Max Value', 'Min Value', 'Greater Than', 'Less Than', 'Greater Than or Equalto', 'Less Than or Equalto', 'Not Null', 'Unique', 'Non negative'];
  const stringOperationTypes = ['Max Length', 'Min Length', 'Email Check', 'Not Null', 'Unique'];

  useEffect(() => {
    setSheet(selectedValues[0]);
    //eslint-disable-next-line
  }, [])

  const validateHandleMapping = () => {
    let responseForSheets = 0;
    sheetNames.forEach(name => {
      const response = JSON.parse(localStorage.getItem(`api_val_${name}`));
      if (response) {
        responseForSheets = responseForSheets + 1;
      }
    });
    if (responseForSheets === sheetNames.length) {
      handleMapping();
    }
    else {
      setDeleteModalOpen(true);
    }
  }

  const handleBackFromDataValidation = () => {
    let validationResponse = false;
    sheetNames.forEach(name => {
      if (!validationResponse) {
        const response = JSON.parse(localStorage.getItem(`api_val_${name}`));
        if (response) {
          validationResponse = true;
          setDataValidationResponse(false);
          setValidationGroupName('');
          localStorage.removeItem(`api_val_${name}`);
        } else {
          setDataValidation(false);
          setSheetPreview(true);
          setTableShow(true);
        }
      }
    })
    setDataValidationStepper(false);
    setApplyValidationToAllSheets(false);
    setValidationGroupName('');
  }

  useEffect(() => {
    let data_validation_arr = [];
    const validationResponse = JSON.parse(localStorage.getItem(`api_val_${sheet}`));
    if (validationResponse) {
      const columnNamesArr = sheetData[sheet][0];
      columnNamesArr.forEach((item) => {
        const result = validationResponse?.filter((obj) => obj.original_col_name === item)
        const integrityError = result?.filter((obj) => obj.constraint_status === 'Failure')
        data_validation_arr.push({
          column_name: item,
          validation_data: result,
          data_integrity_error: integrityError?.length
        })
      })
      setSingleSheetValidationResponseData(data_validation_arr);
      setDataValidationResponse(true);
      setDataValidationStepper(true);
    } else {
      setDataValidationResponse(false);
    }
    const applyValidationCheckbox = JSON.parse(localStorage.getItem('applyValidationToAllSheets'));
    setApplyValidationToAllSheets(applyValidationCheckbox);
    if (applyValidationCheckbox) {
      const selectedValidationGroup = JSON.parse(localStorage.getItem('validationGroupNameAllSheets'));
      if (selectedValidationGroup) {
        setValidationGroupName(selectedValidationGroup);
      }
    }
    else {
      const selectedValidationGroup = JSON.parse(localStorage.getItem(`validationGroupName-${sheet}`));
      if (selectedValidationGroup) {
        setValidationGroupName(selectedValidationGroup);
      } else {
        setValidationGroupName('');
      }
    }
    getDataValidationGroups();
    //eslint-disable-next-line
  }, [sheet])

  const getDataValidationGroups = async () => {
    try {
      const response = await axios.get(`${API}/data-validation/data-validation-groups`, {
        "Content-Type": 'application/json'
      })
      const responseArr = response.data.data;
      responseArr.forEach((Object) => {
        Object.id = uuid();
      })
      setDataValidationGroups(responseArr);
    } catch (error) {
      console.log(error);
    }
  }

  const updateColumnName = (columnName, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, column_name: columnName } : obj))
  }

  const updateRuleType = (ruleType, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, group_id: obj.group_id, id: obj.id, _id: obj._id, rule_type: ruleType } : obj))
  }

  const updateOperation = (operation, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, group_id: obj.group_id, id: obj.id, _id: obj._id, rule_type: obj.rule_type, operation: operation } : obj));
  }

  const updateOtherColumn = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, column_name1: value } : obj));
  }

  const updateValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, value: value } : obj));
  }

  const updateStringValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { ...obj, value: value } : obj));
  }

  const updateStringMaximumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, group_id: obj.group_id, id: obj.id, _id: obj._id, operation: obj.operation, rule_type: obj.rule_type, max_value: Number(value) } : obj));
  }

  const updateStringMinimumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, group_id: obj.group_id, id: obj.id, _id: obj._id, operation: obj.operation, rule_type: obj.rule_type, min_value: Number(value) } : obj));
  }

  const updateNumberMaximumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, group_id: obj.group_id, id: obj.id, _id: obj._id, operation: obj.operation, rule_type: obj.rule_type, max_value: Number(value) } : obj));
  }

  const updateNumberMinimumValue = (value, id) => {
    setRules(rules => rules.map(obj => id === obj.id ? { column_name: obj.column_name, group_id: obj.group_id, id: obj.id, _id: obj._id, operation: obj.operation, rule_type: obj.rule_type, min_value: Number(value) } : obj));
  }

  const addRule = () => {
    const lastObj = rules[rules.length - 1];
    const ruleDuplicate = rules.filter(d => d.column_name === lastObj.column_name
      && d.rule_type === lastObj.rule_type
      && d.operation === lastObj.operation
      && d.max_value === lastObj.max_value
      && d.min_value === lastObj.min_value
      && d.column_name1 === lastObj.column_name1
    );
    if (ruleDuplicate.length > 1) {
      toast.error('Rule already exist')
    } else {
      setRules([...rules, { id: uuid(), column_name: "", rule_type: "" }]);
    }
  }

  const deleteRule = async (id, objId) => {
    if (objId) {
      try {
        const response = await axios.put(`${API}/data-validation/data_validation_rules/rule/delete/${objId}`, { deleted_status: 1 }, {
          "Content-Type": 'application/json'
        })
        const responseArr = response.data.rules;
        console.log(responseArr);
        toast.success('Rule deleted successfully')
        setRules(rules => rules.filter(obj => id !== obj.id));

      } catch (error) {
        console.log(error);
      }
    } else {
      setRules(rules => rules.filter(obj => id !== obj.id));
    }
  }

  const getSelectedValidationGroupRules = async (value) => {
    localStorage.setItem('group_id', value._id);
    setValidationGroupName(value.group_name);
    try {
      setLoadingRules(true);
      const response = await axios.get(`${API}/data-validation/data-validation-rules/${value._id}`, {
        "Content-Type": 'application/json'
      })
      const responseArr = response.data.rules;
      responseArr.forEach((obj) => {
        obj.id = uuid();
      })
      setRules(responseArr);
      setLoadingRules(false);
      if (applyValidationToAllSheets) {
        localStorage.setItem('validationGroupNameAllSheets', JSON.stringify(value.group_name));
      }
      localStorage.setItem(`validationGroupName-${sheet}`, JSON.stringify(value.group_name));
    } catch (error) {
      console.log(error);
    }
  }

  const saveDataValidationRules = async () => {
    if (rules.length > 0) {
      const group_id = localStorage.getItem('group_id');
      rules.forEach(async (d) => {
        if (d._id) {
          try {
            await axios.put(`${API}/data-validation/data_validation_rules/${group_id}`, d, { "Content-Type": 'application/json' })
          } catch (error) {
            console.log(error);
          }
        } else {
          d.group_id = group_id
          let b = [];
          b.push(d)
          try {
            await axios.post(`${API}/data-validation/data_validation_rules`, b, {
              "Content-Type": 'application/json'
            })
          } catch (error) {
            console.log(error);
          }
        }
      })
    } else {
      toast.error("Please add a rule to save it");
    }
  }

  //upload files to Azure Storage 
  const uploadFile = async (myFile) => {
    const blobServiceClient = new BlobServiceClient(`${localStorage.getItem('sas_url')}`);
    const containerName = user?.current_workspace._id.toString().toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    // Upload the file
    const options = {
      blobHTTPHeaders: {
        blobContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    };

    try {
      // Generate a unique filename using UUID
      const fileName = uuid() + ".xlsx";
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(myFile, options);
      return blockBlobClient.url;
    } catch (error) {
      console.error('Error uploading File:', error);
    }
  };

  const handleDataValidation = async () => {
    const countDict = rules.reduce((acc, curr) => {
      const { column_name, rule_type, operation, max_value, min_value, column_name1 } = curr;
      if (acc[column_name] && acc[rule_type] && acc[operation] && acc[max_value] && acc[min_value] && acc[column_name1]) {
        ++acc[column_name];
        ++acc[rule_type];
        ++acc[operation];
        ++acc[max_value];
        ++acc[min_value];
        ++acc[column_name1];
      } else {
        acc[column_name] = 1
        acc[rule_type] = 1
        acc[operation] = 1
        acc[max_value] = 1
        acc[min_value] = 1
        acc[column_name1] = 1
      };
      return acc;
    }, {});

    const result = rules.map((obj) => {
      obj["count"] = countDict[obj.column_name];
      return obj;
    });
    let finalDuplicateFilter = result.filter(d => d.count > 1);
    if (finalDuplicateFilter.length === 0) {
      const result = [...new Set(rules.flatMap(
        obj => Object.keys(obj)
          .filter(key => obj[key] === '')
      ))]
      if (result.length > 0) {
        toast.error('Fill all the fields');
        return;
      } else {
        try {
          setLoading(true);
          saveDataValidationRules();
          const updatedRules = rules.map(({ _id, id, group_id, createdAt, updatedAt, deleted_status, operation, count, ...rest }) => ({ Operation: operation, ...rest }));
          let objData = {};
          let count = 0;
          updatedRules.forEach((d, i) => {
            count = count + 1
            let index = (count - 1).toString()
            objData[index] = d
          })
          const jsonString2 = JSON.stringify(objData);
          console.log(objData, 'obj data');
          const fileUrl = await uploadFile(excelFile);
          const fileDetails = {
            url: fileUrl,
            validation_rules: jsonString2
          }
          const config = {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage.getItem("auth_token")}`
            }
          };
          const res = await axios.post(`${API}/ai-api/validation`, fileDetails, config);
          if (res.data.success) {
            if (applyValidationToAllSheets) {
              for (let [key, value] of Object.entries(sheetData)) {
                console.log(value);
                localStorage.setItem(`api_val_${key}`, JSON.stringify(res.data.result));
              }
            }
            else {
              localStorage.setItem(`api_val_${sheet}`, JSON.stringify(res.data.result));
            }
            let data_validation_arr = [];
            const validationResponse = JSON.parse(localStorage.getItem(`api_val_${sheet}`));
            if (validationResponse) {
              const columnNamesArr = sheetData[sheet][0];
              columnNamesArr.forEach((item) => {
                const result = validationResponse?.filter((obj) => obj.original_col_name === item)
                const integrityError = result?.filter((obj) => obj.constraint_status === 'Failure')
                data_validation_arr.push({
                  column_name: item,
                  validation_data: result,
                  data_integrity_error: integrityError?.length
                })
              })
            }
            setSingleSheetValidationResponseData(data_validation_arr);
            setLoading(false);
            setDataValidationResponse(true);
            setDataValidationStepper(true);
          } else {
            toast.error(res.data.error.msg);
            setLoading(false);
          }
        } catch (error) {
          toast.error('Unable to validate the data, please try again.');
          setLoading(false);
        }
      }
    } else {
      toast.error('Remove Duplicate Rules')
    }
  }

  const handleApplyValidationToAllSheetsChange = (e) => {
    setApplyValidationToAllSheets(e.target.checked);
    localStorage.setItem('applyValidationToAllSheets', JSON.stringify(e.target.checked));
    if (e.target.checked && validationGroupName) {
      localStorage.setItem('validationGroupNameAllSheets', JSON.stringify(validationGroupName));
    }
  }

  // console.log(rules, 'rules');         

  return (
    <>
      <div className="flex justify-between mb-5">
        <div className='flex gap-5 justify-center items-center'>
          <form className="max-w-sm">
            {/* <div className="relative">
              <img src={search} alt="search" className='absolute top-0 bottom-0 my-auto text-gray-400 left-3 ' />
              <input
                type="text"
                placeholder="Search source fields"
                className="w-full py-[0.4rem] pl-12 pr-4 text-gray-500 border rounded-md outline-none text-sm"
              />
            </div> */}
          </form>
          {/* <div className="text-[14px] group">
                <button
                  className="px-3 py-1 rounded-md border-[1px] flex items-center justify-between"
                  onClick={() => setMappingDropdownVisible(!mappingDropdownVisible)}
                >
                  All source fields <img src={downArrow} alt="downarrow" className='ms-3' />
                </button>
                {mappingDropdownVisible && (
                  <div className="relative top-2 text-sm text-[#212529] group-hover:flex">
                    <div className="bg-white absolute w-56 h-fit border-[1px] border-[#707070] rounded-md z-20 py-1">
                      <ul onClick={() =>
                        setMappingDropdownVisible(false)
                      }>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Show mapped fields
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Show unmapped fields
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Reset mapping
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Clear mapping
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          All source fields
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div> */}

          {/* <div className='flex gap-2 text-sm font-semibold'>
            <img src={Warn} alt='warn' width="24px" />
            Errors: 2
          </div> */}
        </div>

        <div className='flex gap-4'>
          <button className="flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm"
            onClick={() => {
              validateHandleMapping();
            }}>
            <PiArrowRightBold color="#fff" size={15} />{" "}
            Continue to Auto Mapping
          </button>
          <button className="flex items-center gap-2 bg-[#BFBFBF] px-[10px] py-[5px] hover:bg-[#A5A5A5] back-btn text-white rounded-md text-sm"
            onClick={() => {
              handleBackFromDataValidation();
            }}>
            <img src={backWhite} alt="back" className='back-btn-img' />
            Go Back
          </button>
        </div>
      </div>
      <div className="mt-2 text-sm flex justify-start">
        {/* {selectedValues?.length === 1 ? ( 
           <h5 className="mt-0 overflow-auto  bg-[#F9FAFB] border-x-[1px] border-t-[1px] border-[#DDDEE0] px-4 py-3 w-full rounded-t-lg font-semibold text-sm">
            Sheet Name : {sheet}
          </h5> )         
        : ( */}
        <div className="flex border-b-1 border-lite-grey-1">
          {displayedElements?.map((s, i) => (
            <div className='group relative'>
              <label htmlFor={`${s}`}>
                <h5
                  key={i}
                  className={`px-2 py-2.5 text-center rounded-t-lg w-28 border-t-[1px] border-lite-grey-2 border drop-item cursor-pointer font-medium ${s === sheet
                    ? "sheet_active"
                    : "bg-[#F9FAFB]"
                    }`}
                >
                  <input
                    id={`${s}`}
                    name={`${s}`}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded hidden"
                    checked={s === sheet}
                    value={s}
                    onChange={(e) => {
                      setSheet(e.target.value);
                    }}
                  />
                  {s}
                </h5>
              </label>
              <span className='group-hover:visible invisible down-arrow text-sm'>
                {s}
              </span>
            </div>
          ))}
        </div>
        {/* )}  */}

        {/* Buttons to switch tab */}
        {checkboxes.length <= 7 ? (
          " "
        ) : (
          <div className="flex justify-end w-full gap-5 items-center">
            <div className="flex gap-6">
              <button
                onClick={handleBackwardClick}
                disabled={currentIndex === 0}
              >
                {currentIndex === 0 ? (
                  <img src={disableLeft} alt="left" />
                ) : (
                  <img src={LeftArrow} alt="left" />
                )}
              </button>

              <button
                onClick={handleForwardClick}
                disabled={
                  currentIndex ===
                  selectedValues.length - 5
                }
              >
                {currentIndex ===
                  selectedValues.length - 5 ? (
                  <img src={disableRight} alt="right" />
                ) : (
                  <img src={RightArrow} alt="right" />
                )}
              </button>
            </div>

            {/* dropdown */}
            <div className="text-[14px] group">
              {/* <button
                className="px-1.5 py-1 rounded-sm border-[1px] flex items-center w-24 justify-between"
                onClick={() => setDropdownVisible(!dropdownVisible)}
              >
                Sheet <img src={downArrow} alt="downarrow" />
              </button>
              {dropdownVisible && (
                <div className="relative right-32 top-2 text-sm text-[#212529] group-hover:flex">
                  <div className="bg-white absolute w-56 h-fit border-[1px] border-[#707070] rounded-md z-20 py-1">
                    {sheetNames.map((n, i) => (
                      <ul key={i}>
                        <li
                          className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm"
                          onClick={() =>
                            setDropdownVisible(false)
                          }
                        >
                          {n}
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              )} */}
            </div>
          </div>
        )}
      </div>
      {dataValidationResponse ?
        <DataValidationResponse validationGroupName={validationGroupName} singleSheetValidationResponseData={singleSheetValidationResponseData} sheet={sheet} />
        :
        <div className="flex gap-10 mt-5 mb-9">
          <div>
            <div className='mb-2.5'>
              <label htmlFor="Validation-Group-Name" className="font-medium text-lite-black text-[18px]">
                Select Validation Group
              </label>
            </div>
            <Dropdown options={dataValidationGroups} validationGroups={true} selectedValue={validationGroupName} width="w-[200px]"
              validationGroupName={validationGroupName} setValidationGroupName={setValidationGroupName}
              getSelectedValidationGroupRules={getSelectedValidationGroupRules} placeholder="Select Group Name" sheet={sheet} />

            <div className='text-lite-black text-sm font-normal mt-8 flex gap-2.5'>
              <input type="checkbox" id="all-sheets" name="All-Sheets" value="All-Sheets" onChange={(e) => handleApplyValidationToAllSheetsChange(e)} checked={applyValidationToAllSheets} />
              <label htmlFor="all-sheets">Apply to all sheets</label>
            </div>
          </div>

          <div className='border-lite-grey border-l-[1px] grow min-h-[27rem] ps-7'>
            <div className='flex justify-between'>
              <div className={`text-lite-black font-medium ${validationGroupName ? '' : 'opacity-30'} `}>Preview</div>
              <button
                className={`flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm tracking-wide ${validationGroupName === '' ? 'opacity-30 pointer-events-none' : ''}`} onClick={() => handleDataValidation()}>
                <PiArrowRightBold color="#fff" size={15} />{" "}
                Validate Data
              </button>
            </div>

            {loadingRules ?
              <div className="flex justify-center items-center mt-24">
                <div className="flex flex-col justify-center items-center">
                  <img src={Loader} alt="loader" className="w-[50px] h-[65px]" />
                  <h5 className="mt-5">Loading....</h5>
                </div>
              </div> :
              loading ?
                <div className="flex justify-center items-center mt-28 flex-col">
                  <div className="flex flex-col justify-center items-center">
                    <img src={loadingGif} alt="loading" className="w-[50px] h-[65px]" />
                    <h5 className="mt-5">Validating....</h5>
                  </div>
                </div> :
                validationGroupName ?
                  <>
                    {
                      rules.map((rule, index) => (
                        <div className='flex justify-between items-end mt-8' key={index}>
                          <div className='flex gap-5 items-center'>
                            <div className='flex flex-col gap-2.5  text-sm'>
                              <label htmlFor="Column-Name" className="text-lite-black font-semibold">
                                Column Name
                              </label>
                              <input type="text" placeholder="Enter Column Name" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Column-Name" value={rule.column_name} onChange={(e) => updateColumnName(e.target.value, rule.id)} />
                            </div>

                            {/* Rule types dropdown */}
                            <div className='flex flex-col gap-2.5  text-sm'>
                              <label htmlFor="Rule-Type" className="text-lite-black font-semibold">
                                Rule Type
                              </label>
                              <Dropdown options={ruleTypes} width="w-[180px]" selectedValue={rule.rule_type ? rule.rule_type : ''} updateSelectedValue={updateRuleType} rule={rule} placeholder="Select Rule Type" />
                            </div>

                            {/* Numeric operation dropdown */}
                            {(rule.rule_type !== '' && rule.rule_type === 'numeric') ?
                              <div className='flex flex-col gap-2.5  text-sm'>
                                <label htmlFor="operation" className="text-lite-black font-semibold">
                                  Operation
                                </label>
                                {/* <Dropdown options={numericOperationTypes} width="w-[180px]" selectedValue={rule.operation ? rule.operation : ''} updateSelectedValue={updateOperation} rule={rule} placeholder="Select Operation" /> */}
                                <Dropdown options={numericOperationTypes} width="w-[180px]" selectedValue={(rule.rule_type === "numeric" && numericOperationTypes.includes(rule.operation)) ? rule.operation : ''} updateSelectedValue={updateOperation} rule={rule} placeholder="Select Operation" />
                              </div> : ''}

                            {/* Numeric other column textboxes */}
                            {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Greater Than' || rule.operation === 'Less Than' || rule.operation === 'Greater Than or Equalto' || rule.operation === 'Less Than or Equalto')) ?
                              <div className='flex flex-col gap-2.5  text-sm'>
                                <label htmlFor="Value" className="text-[#212529] font-semibold">
                                  Other column name
                                </label>
                                <input type="text" placeholder="Enter column name" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.column_name1 ? rule.column_name1 : ''} onChange={(e) => updateOtherColumn(e.target.value, rule.id)} />
                              </div> : ''}

                            {/* Numeric value textboxes */}
                            {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Count of Distinct Values')) ?
                              <div className='flex flex-col gap-2.5  text-sm'>
                                <label htmlFor="Value" className="text-[#212529] font-semibold">
                                  Value
                                </label>
                                <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.value ? rule.value : ''} onChange={(e) => updateValue(e.target.value, rule.id)} />
                              </div> : ''}

                            {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Max Value')) ?
                              <div className='flex flex-col gap-2.5 text-sm'>
                                <label htmlFor="Value" className="text-[#212529] font-semibold">
                                  Value
                                </label>
                                <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.max_value ? rule.max_value : ''} onChange={(e) => updateNumberMaximumValue(Number(e.target.value), rule.id)} />
                              </div> : ''}

                            {((rule.rule_type !== '') && (rule.rule_type === 'numeric') && (rule.operation === 'Min Value')) ?
                              <div className='flex flex-col gap-2.5 text-sm'>
                                <label htmlFor="Value" className="text-[#212529] font-semibold">
                                  Value
                                </label>
                                <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.min_value ? rule.min_value : ''} onChange={(e) => updateNumberMinimumValue(Number(e.target.value), rule.id)} />
                              </div> : ''}

                            {/* String operation dropdown */}
                            {(rule.rule_type !== '' && rule.rule_type === 'string') ?
                              <div className='flex flex-col gap-2.5  text-sm'>
                                <label htmlFor="operation" className="text-[#212529] font-semibold">
                                  Operation
                                </label>
                                <Dropdown options={stringOperationTypes} width="w-[180px]" selectedValue={(rule.rule_type === "string" && stringOperationTypes.includes(rule.operation)) ? rule.operation : ''} updateSelectedValue={updateOperation} rule={rule} placeholder="Select Operation" />
                              </div> : ''}

                            {/* String value textboxes */}
                            {((rule.rule_type !== '') && (rule.rule_type === 'string') && (rule.operation === 'Contains' || rule.operation === 'Count of Distinct Values')) ?
                              <div className='flex flex-col gap-2.5  text-sm'>
                                <label htmlFor="Value" className="text-[#212529] font-semibold">
                                  String
                                </label>
                                <input type="text" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.value ? rule.value : ''} onChange={(e) => updateStringValue(e.target.value, rule.id)} />
                              </div> : ''}

                            {/* String Maximum Value textboxes */}
                            {((rule.rule_type !== '') && (rule.rule_type === 'string') && (rule.operation === 'Max Length')) ?
                              <div className='flex flex-col gap-2.5  text-sm'>
                                <label htmlFor="Value" className="text-[#212529] font-semibold">
                                  Maximum Length
                                </label>
                                <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.max_value ? rule.max_value : ''} onChange={(e) => updateStringMaximumValue(Number(e.target.value), rule.id)} />
                              </div> : ''}

                            {/* String Minimum Value textboxes */}
                            {((rule.rule_type !== '') && (rule.rule_type === 'string') && (rule.operation === 'Min Length')) ?
                              <div className='flex flex-col gap-2.5  text-sm'>
                                <label htmlFor="Value" className="text-[#212529] font-semibold">
                                  Minimum Length
                                </label>
                                <input type="number" step="any" placeholder="Enter Value" className="border border-[#C7C7C7] rounded-md w-[180px] text-lite-black custom-input px-2.5 py-1 font-normal focus-visible:outline-none" id="Value" value={rule.min_value ? rule.min_value : ''} onChange={(e) => updateStringMinimumValue(Number(e.target.value), rule.id)} />
                              </div> : ''}
                          </div>

                          <div className='flex items-center align-middle gap-5'>
                            {index === rules.length - 1 &&
                              <div>
                                <button className={`${((rule.column_name?.toString().trim().length !== 0) && (((rule.column_name1) || rule.value || rule.max_value || rule.min_value) || rule.string || rule.operation === 'Unique' || rule.operation === 'Not Null' || rule.operation === 'Non negative' || rule.operation === 'Email Check')) ? 'bg-[#0078C5]' : 'bg-[#9ac8e6] cursor-default pointer-events-none'}  disabled:bg-[#9ac8e6] px-3 py-1.5 rounded-md flex justify-center border-none items-center text-[#ffffff] gap-2 text-sm  font-normal`} onClick={() => addRule()}><img src={add} alt="add" />Add Rule</button>
                              </div>}
                            <div onClick={() => deleteRule(rule.id, rule._id)} className='cursor-pointer'><img src={deleteIcon} alt="delete" /></div>
                          </div>
                        </div>
                      ))
                    }
                  </> :
                  <div className='text-lg font-medium text-lite-black flex justify-center items-center mt-24'>
                    Please select a validation group
                  </div>
            }
          </div>
        </div>
      }
      {deleteModalOpen ?
        <DeleteModal setModalOpen={setDeleteModalOpen} modalOpen={deleteModalOpen} handleDelete={handleMapping} showSkipData={true} /> : ''
      }
    </>
  )
}

export default DataValidation
