import React, { useState } from 'react';
import axios from "axios";
import { API } from '../../../global';
import toast from 'react-hot-toast';
import UsersDropdown from '../Dropdown/UsersDropdown';
import { v4 as uuid } from 'uuid';
import Switch from "react-switch";
import { useAuth } from '../../../hooks/useAuth';

const AddWorkspaceModal = ({ setAddWorkspaceModal, users, getAllWorkspaces, modalRef }) => {
  const [workspaceName, setWorkspaceName] = useState("");
  const [description, setDescription] = useState("");
  const [workspaceNameError, setWorkspaceNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectUsersError, setSelectUsersError] = useState(false);
  const [privateWorkspace, setPrivateWorkspace] = useState(false);
  const { user } = useAuth();
  let usersSelected = [];

  const validate = () => {
    let error = false;
    if (!workspaceName) {
      error = true;
      setWorkspaceNameError(true);
    } if (!description) {
      error = true;
      setDescriptionError(true);
    } if (privateWorkspace && selectedUsers.length === 0) {
      error = true;
      setSelectUsersError(true);
    }
    return error;
  };

  const handleAddWorkspace = async () => {
    if (!validate()) {
      try {
        let selectedUsersId = selectedUsers.map(user => user._id);
        let workspaceToAdd;
        if (privateWorkspace) {
          workspaceToAdd = {
            workspace_name: workspaceName,
            description: description,
            workspace_unique_id: uuid(),
            private_workspace: privateWorkspace,
            users: [...selectedUsersId, user._id]
          }
        } else {
          workspaceToAdd = {
            workspace_name: workspaceName,
            description: description,
            workspace_unique_id: uuid(),
            private_workspace: privateWorkspace
          }
        }
        const config = {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("auth_token")}`
          }
        };
        const newWorkspace = await axios.post(`${API}/admin/workspace/create-workspace`, workspaceToAdd, config);
        if (!newWorkspace.data.success) {
          toast.error(newWorkspace.data.error.msg);
          return;
        }
        usersSelected = [...selectedUsers, user];
        usersSelected.forEach((obj) => {
          obj.workspace_id = newWorkspace.data.result.data._id;
        })
        const updatedUsers1 = usersSelected.map(({ _id, workspace_id, ...rest }) => ({ user_id: _id, workspace_id }));
        await axios.post(`${API}/admin/workspace/users/create_workspace_users`, updatedUsers1, { "Content-Type": "application/json" });
        getAllWorkspaces();
        toast.success('Workspace created successfully');
        setAddWorkspaceModal(false);
      } catch (error) {
        console.error('Error in API calls:', error);
        setAddWorkspaceModal(false);
      }
    }
  }

  const handleCancel = () => {
    setWorkspaceName('');
    setDescription('');
    setAddWorkspaceModal(false);
  }

  const handleTogglePrivate = (checked) => {
    setPrivateWorkspace(checked);
  }

  const handleChange = (e) => {
    if (e.target.id === "workspaceName") {
      setWorkspaceName(e.target.value);
      setWorkspaceNameError(false);
    }
    if (e.target.id === "workspaceDescription") {
      setDescription(e.target.value);
      setDescriptionError(false);
    }
  };

  return (
    <div className='flex items-center justify-center w-full h-screen fixed top-0 left-0 backdrop-brightness-75 z-40'>
      <div ref={modalRef} className='bg-[#ffffff] w-[520px] rounded-lg flex flex-col p-5'>
        <div className='text-lite-black-3 text-xl font-medium my-2.5'>Add Workspace</div>
        <div className='text-sm'>
          <div className='flex items-center mb-3'>
            <div className='w-[146px]'>
              Workspace Name
            </div>
            <div className='grow'>
              <input type="text" placeholder="Enter Workspace Name" id="workspaceName"
                value={workspaceName}
                className='focus:outline-none h-[36px] placeholder:text-lite-grey-3 focus:border-[#86b7fe] focus:border w-full
              focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#B6B6B6] border px-3 py-1 rounded-md'
                onChange={(e) => handleChange(e)} autoFocus />
              {workspaceNameError ? (
                <div className='text-red text-xs'>Workspace name is required</div>
              ) : null}
            </div>
          </div>
          <div className='flex mb-3'>
            <div className='w-[146px] mt-3'>
              Description
            </div>
            <div className='grow'>
              <textarea
                id="workspaceDescription"
                name="workspaceDescription"
                rows="4"
                className='focus:outline-none placeholder:text-lite-grey-3 focus:border-[#86b7fe] focus:border w-full
              focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#B6B6B6] border px-3 py-1 rounded-md'
                placeholder="Enter Description"
                value={description}
                onChange={(e) => handleChange(e)}
              ></textarea>
              {descriptionError ? (
                <div className='text-red text-xs'>Description is required</div>
              ) : null}
            </div>
          </div>
          <div className='flex mb-3'>
            <div className='w-[146px]'>
              Set as Private
            </div>
            <div>
              <Switch onChange={handleTogglePrivate} checked={privateWorkspace} height={24} width={54} borderRadius={18} uncheckedIcon={<div className='text-white pt-0.5 ps-1'>NO</div>} checkedIcon={<div className='text-white pt-0.5 ps-1'>YES</div>} />
            </div>
          </div>
          {privateWorkspace ?
            <div className='flex mb-3'>
              <div className='w-[146px] mt-3'>
                Users
              </div>
              <div className='grow h-[36px]'>
                <UsersDropdown placeholder="Select Users" options={users.filter((obj) => obj._id !== user._id)} width="w-[336px]" selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} selectUsersError={selectUsersError} setSelectUsersError={setSelectUsersError} />
              </div>
            </div> : ''}
        </div>

        <div className='flex gap-2.5 justify-end text-sm'>
          <button className='py-1.5 px-2 border-none bg-[#888888] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleCancel()}>Cancel</button>
          <button className='py-1.5 px-2 border-none bg-[#0078C5] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleAddWorkspace()}>Save</button>
        </div>
      </div>
    </div>
  )
}

export default AddWorkspaceModal
