import React from 'react';
import greenTick from "../../../assets/greenTick.svg";
import Accordion from "../../ReUseable/Accordion/Accordion";
import download from "../../../assets/download.svg";
import Papa from 'papaparse';

const DataValidationResponse = ({ validationGroupName, singleSheetValidationResponseData, sheet }) => {

  console.log(singleSheetValidationResponseData);

  const handleDownloadReport = () => {
    const result = [];
    singleSheetValidationResponseData.forEach(item => {
      if (item.validation_data.length === 0) {
        result.push({
          column_name: item.column_name,
          expectations: '',
          status: 'Skipped',
          description: 'No rule applied'
        });
      }
      else {
        item.validation_data?.forEach(validationItem => {
          const maxExpectation = validationItem.max_value === 'NaN' || validationItem.max_value === undefined ? '' : ` ${validationItem.max_value}`;
          const minExpectation = validationItem.min_value === 'NaN' || validationItem.min_value === undefined ? '' : ` ${validationItem.min_value}`;
          const col1Expectation = validationItem.column_name1 === 'Null' || validationItem.column_name1 === undefined ? '' : ` ${validationItem.column_name1}`;
          const expectations = `${validationItem.Operation}${maxExpectation}${minExpectation}${col1Expectation}`;
          result.push({
            column_name: item.column_name,
            expectations,
            status: validationItem.constraint_status,
            description: validationItem.constraint_message === '' ? "constraint satisfied" : validationItem.constraint_message
          });
        });
      }
    });

    const csv = Papa.unparse(result);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${sheet}-validationResponse`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <div className='mb-5'>
      {singleSheetValidationResponseData.length !== 0 ?
        <>
          <div>
            {singleSheetValidationResponseData.map((column, index) => (
              <Accordion column={column} index={index} noOfColumns={singleSheetValidationResponseData.length} key={index} sheet={sheet} />
            ))}
          </div>

          <div className='mt-5 flex justify-end'>
            <button className="flex items-center gap-2 bg-[#0078C5] px-2 py-2 hover:bg-[#00578F] text-white rounded-md text-sm"
              onClick={() => {
                handleDownloadReport();
              }}>
              <img src={download} alt="download" />
              Download Report
            </button>
          </div>

          {/*  Rule Applied Info */}
          <div className='border border-[#1DA64D] bg-[#E9FEF0] rounded-md mt-5 text-sm text-[#212529] p-2.5'>
            <div className='font-semibold flex items-center'>
              <img className='me-2.5 w-4 h-4' src={greenTick} alt="greentick" />{validationGroupName}
              <span className="ms-2">-</span>
              <span className='font-normal ms-2'>Validation Rules applied here</span></div>
          </div>
        </> : ""
      }
    </div>
  )
}

export default DataValidationResponse


