import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from "../../global";
import toast from 'react-hot-toast';
import Nav from '../ReUseable/Navbar/Nav';
import { useAuth } from '../../hooks/useAuth';
import homeBg from "../../assets/homeBg.png";
import validator from "email-validator";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('Email is required');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const emailValue = localStorage.getItem('emailData');
    if (emailValue) {
      setEmail(emailValue);
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    if (e.target.id === "email") {
      setEmail(e.target.value);
      setEmailError(false);
    }
    if (e.target.id === "password") {
      setPassword(e.target.value);
      setPasswordError(false);
    }
  };

  const validateEmail = (email) => {
    return validator.validate(email)
  };

  const validate = () => {
    let error = false;
    if (!email) {
      error = true;
      setEmailError(true);
    }
    if (!password) {
      error = true;
      setPasswordError(true);
    }

    if (email && !validateEmail(email)) {
      setEmailError(true);
      error = true;
      setEmailErrorMsg('Email Id is not valid')
    }

    return error;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!validate()) {
      setLoading(true);
      setPasswordError(false);
      setEmailError(false);
      fetch(`${API}/admin/auth/login/email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
          tenant_id: localStorage.getItem("tenantId"),
        }),
      })
        .then((res) => res.json())
        .then((val) => {
          if (val.success) {
            login(val.result);
            localStorage.setItem("sas_url", val.result.sas_url);
            localStorage.setItem("auth_token", val.result.token);
            navigate('/prepare');
            setLoading(false);
          } else {            
            setLoading(false);
            toast.error(val.error.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-[#E5F8F8] h-screen w-full relative">
      {/* navbar */}
      <Nav showWorkspaceDetails={false} />

      <div className='flex w-full h-full'>
        <div className='h-full w-[39.7%] flex items-center justify-center z-20 relative pt-[50px]'>
          <img src={homeBg} alt="img" className='w-full absolute left-0 top-0 h-screen' />
          <div className='px-[48px] text-white absolute'>
            <div className='font-medium text-5xl pb-5 flex justify-center'>Ignitho<br /> Data Accelerator</div>
            <div className='text-lg py-5 text-center'>Unlock the power of data acceleration with IDA</div>
            <div className='pt-5 pb-3 text-sm text-center'>The cutting-edge data accelerator solution designed to supercharge your organization's data-driven operations. </div>
            <div className='py-3 text-sm text-center'>Whether you're a small startup or a large enterprise, revolutionizes the way you handle data, empowering you to make faster, smarter decisions.</div>
            <div className='py-3 text-sm text-center'>Experience the future of data acceleration with Ignitho Data Product. Contact us today for a personalized demo and see how Ignitho Data Product can transform your organization's data capabilities.</div>
          </div>
        </div>

        <div className='w-[60.3%]'>
          <div className='flex items-center justify-center h-full pt-[50px]'>
            <div className='w-[352px] py-10 text-sm bg-white flex rounded-[20px] flex-col drop-shadow-[0_0_30px_#00578F1A]'>
              <div className='text-blue-1 text-xl text-center mb-6'>
                Login
              </div>
              <form onSubmit={(e) => handleLogin(e)} onKeyDown={(e) => e.key === 'Enter' ? handleLogin(e) : ''}>
                <div className='flex flex-col gap-5 mx-10 text-lite-black-3'>
                  <div className='flex flex-col gap-1.5'>
                    <label className='font-medium' htmlFor='email'>
                      E-Mail ID
                    </label>
                    <input type="email" placeholder="Enter your E-Mail ID" id="email" value={email}
                      className='bg-[#F7F7F7] focus:outline-0 focus:border-[#86b7fe] focus:border focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#E9E9E9] border px-3 py-2 rounded-md'
                      onChange={handleChange} autoFocus />
                    {emailError ? (
                      <div className='text-red text-xs'>{emailErrorMsg}</div>
                    ) : null}
                  </div>
                  <div className='flex flex-col gap-1.5'>
                    <label htmlFor='password' className='font-medium'>
                      Password
                    </label>
                    <div className='flex w-full border border-[#E9E9E9] rounded-md'>
                      <input id='password' type={showPassword ? "text" : "password"} placeholder="Enter your password" value={password}
                        className='bg-[#F7F7F7] w-[95%] focus:outline-0 focus:border-[#86b7fe] focus:border focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#E9E9E9] border px-3 py-2 rounded-md rounded-r-none'
                        onChange={handleChange} />
                      <span onClick={() => togglePasswordVisibility()} className='cursor-pointer flex justify-center items-center px-2 bg-[#E7E7E7]'>
                        {!showPassword ? <BsEyeSlash /> : <BsEye />}
                      </span>
                    </div>
                    {passwordError ? (
                      <div className='text-red text-xs'>Password is required</div>
                    ) : null}
                  </div>
                  <div className='flex justify-center gap-2.5'>
                    <button type="submit" className='bg-blue-1 text-white w-full outline-none rounded-md py-1.5 hover:bg-[#00578F]' disabled={loading} onClick={handleLogin}>
                      {loading ? "Loading..." : "Login"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Login
