import React, { useState } from 'react';
import axios from "axios";
import { API } from '../../../global';
import toast from 'react-hot-toast';
import UsersDropdown from '../Dropdown/UsersDropdown';
import Switch from "react-switch";
import { useAuth } from '../../../hooks/useAuth';

const EditWorkspaceModal = ({ setEditWorkspaceModal, setWorkspaces, workspaceName,
  setWorkspaceName, description, setDescription, users, setUsers, privateWorkspace,
  setPrivateWorkspace, setSelectedUsers, selectedUsers, workspaceId, setDeleteModalOpen,
  getAllWorkspaces, workspaceUsers, modalRef }) => {
  const [workspaceNameError, setWorkspaceNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectUsersError, setSelectUsersError] = useState(false);
  const { user, updateUser } = useAuth();

  let usersSelected = [];
  let usersRemoved = [];

  const validate = () => {
    let error = false;
    if (!workspaceName) {
      error = true;
      setWorkspaceNameError(true);
    } if (!description) {
      error = true;
      setDescriptionError(true);
    } if (privateWorkspace && selectedUsers.length === 0) {
      error = true;
      setSelectUsersError(true);
    }
    return error;
  };

  const handleEditWorkspace = async () => {
    if (!validate()) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("auth_token")}`
          }
        };
        const workspaceToUpdate = {
          workspace_name: workspaceName,
          description: description,
          private_workspace: privateWorkspace
        }
        const updatedWorkspace = await axios.put(`${API}/admin/workspace/edit-workspace/${workspaceId}`,
          workspaceToUpdate,
          config);
        if (workspaceId === user.current_workspace._id) {
          const userUpdated = { ...user, current_workspace: updatedWorkspace.data.data };
          updateUser(userUpdated);
        }
        if (!updatedWorkspace.data.success) {
          toast.error(updatedWorkspace.data.error.msg);
          return;
        }
        // Remove users
        usersRemoved = workspaceUsers.filter((user) => !selectedUsers.includes(user));
        usersRemoved.forEach(async (obj) => {
          const removedUser = await axios.put(`${API}/admin/workspace/users/update_users/${obj._id}`, { deleted_status: true }, { "Content-Type": "application/json" });
          console.log(removedUser, 'updated User');
        })
        // Add users
        usersSelected = selectedUsers;
        const updatedUsers = usersSelected.filter((user) => !(user.user_id && user.user_id._id));
        const updatedUsers1 = updatedUsers.map(({ _id, workspace_id, ...rest }) => ({ user_id: _id, workspace_id: workspaceId }));
        await axios.post(`${API}/admin/workspace/users/create_workspace_users`, updatedUsers1, { "Content-Type": "application/json" });
        getAllWorkspaces();
        toast.success('Workspace updated successfully');
        setEditWorkspaceModal(false);
      } catch (error) {
        console.error('Error in API calls:', error);
        setEditWorkspaceModal(false);
      }
    }
  }

  const handleDelete = async () => {
    setEditWorkspaceModal(false);
    setDeleteModalOpen(true);
  }

  const handleCancel = () => {
    setWorkspaceName('');
    setDescription('');
    setEditWorkspaceModal(false);
  }

  const handleTogglePrivate = (checked) => {
    setPrivateWorkspace(checked);
  }

  const handleChange = (e) => {
    if (e.target.id === "workspaceName") {
      setWorkspaceName(e.target.value);
      setWorkspaceNameError(false);
    }
    if (e.target.id === "workspaceDescription") {
      setDescription(e.target.value);
      setDescriptionError(false);
    }
  };

  return (
    <div className='flex items-center justify-center w-full h-screen fixed top-0 left-0 backdrop-brightness-75 z-40'>
      <div ref={modalRef} className='bg-[#ffffff] w-[520px] rounded-lg flex flex-col p-5'>
        <div className='text-lite-black-3 text-xl font-medium my-2.5'>Edit Workspace</div>
        <div className='text-sm'>
          <div className='flex items-center mb-3'>
            <div className='w-[146px]'>
              Workspace Name
            </div>
            <div className='grow'>
              <input type="text" placeholder="Enter Workspace Name" id="workspaceName"
                value={workspaceName}
                className='focus:outline-none h-[36px] placeholder:text-lite-grey-3 focus:border-[#86b7fe] focus:border w-full
              focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#B6B6B6] border px-3 py-1 rounded-md'
                onChange={(e) => handleChange(e)} autoFocus />
              {workspaceNameError ? (
                <div className='text-red text-xs'>Workspace name is required</div>
              ) : null}
            </div>
          </div>
          <div className='flex mb-3'>
            <div className='w-[146px] mt-3'>
              Description
            </div>
            <div className='grow'>
              <textarea
                id="workspaceDescription"
                name="workspaceDescription"
                rows="4"
                className='focus:outline-none placeholder:text-lite-grey-3 focus:border-[#86b7fe] focus:border w-full
              focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#B6B6B6] border px-3 py-1 rounded-md'
                placeholder="Enter Description"
                value={description}
                onChange={(e) => handleChange(e)}
              ></textarea>
              {descriptionError ? (
                <div className='text-red text-xs'>Description is required</div>
              ) : null}
            </div>
          </div>
          <div className='flex mb-3'>
            <div className='w-[146px]'>
              Set as Private
            </div>
            <div>
              <Switch onChange={handleTogglePrivate} checked={privateWorkspace} height={24} width={54} borderRadius={18} uncheckedIcon={<div className='text-white pt-0.5 ps-1'>NO</div>} checkedIcon={<div className='text-white pt-0.5 ps-1'>YES</div>} />
            </div>
          </div>
          {privateWorkspace ?
            <div className='flex mb-3'>
              <div className='w-[146px] mt-3'>
                Users
              </div>
              <div className='grow h-[36px]'>
                <UsersDropdown placeholder="Select Users" options={users} width="w-[336px]"
                  selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}
                  selectUsersError={selectUsersError} setSelectUsersError={setSelectUsersError} />
              </div>
            </div> : ''}
        </div>

        <div className='flex justify-between text-sm'>
          <button className='py-1.5 px-2 border-none bg-[#FF0900] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleDelete()}>Delete</button> :
          <div className='flex gap-2.5'>
            <button className='py-1.5 px-2 border-none bg-[#888888] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleCancel()}>Cancel</button>
            <button className='py-1.5 px-2 border-none bg-[#0078C5] rounded-[5px] text-[#FFFFFF] cursor-pointer' onClick={() => handleEditWorkspace()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditWorkspaceModal
