import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../global";
import { useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
import { useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { TailSpin } from "react-loader-spinner";
import homeBg from "../../../assets/homeBg.png";
import warningIcon from "../../../assets/Warning-icons.jpg";
import PasswordChecklist from "react-password-checklist";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useAuth } from "../../../hooks/useAuth";
// import Nav from "../ReUseable/Navbar/Nav";

const SetPassword = () => {
  const { uuid, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [subHeading, setSubHeading] = useState("");
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [checklist, setCheckList] = useState(false);
  const { login } = useAuth();

  const schema = yup
    .object({
      password: yup
        .string()
        .min(8, "Password is too short - should be 8 characters minimum.")
        .required("Password is required"),
      reTypePassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    fetch(`${API}/admin/auth/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ verify_account_key: uuid }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setSubHeading(
            `Your email verification is confirmed. To proceed, kindly set your password.`
          );
          localStorage.setItem(
            "forget_password_key",
            response.result.forget_password_key
          );
          localStorage.setItem("setPasswordToken", response.result.token);
        } else {
          const passwordKey = localStorage.getItem("forget_password_key");
          if (!passwordKey) {
            setShowError(true);
            setSubHeading(
              `The verification link you provided seems to be incorrect.`
            );
          } else {
            setSubHeading(
              `Your email verification is confirmed.\nTo proceed, kindly set your password.`
            );
          }
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error)
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    fetch(`${API}/admin/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("setPasswordToken")}`,
      },
      body: JSON.stringify({
        forget_password_key: localStorage.getItem("forget_password_key"),
        password: data.password,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          localStorage.setItem("sas_url", response.result.sas_url);
          localStorage.setItem("auth_token", response.result.token);
          localStorage.setItem("user_name", response.result.name);
          localStorage.setItem("user_role_keys", JSON.stringify(response.result.user_role_keys));
          localStorage.setItem("current_role_key", response.result.current_role_key);
          if (localStorage.getItem("sharedLink")) {
            navigate(localStorage.getItem("sharedLink"));
          } else {
            getTenantDataWithEmail(response.result.token);
          }
          login(response.result);
        } else {
          setLoading(false);
          toast.error(response.error.msg);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
        setLoading(false);
      });
  };

  const getTenantDataWithEmail = (token) => {
    const decoded = jwtDecode(token);
    fetch(`${API}/admin/tenant/get-tenant-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tenant_id: decoded.tenant_id }),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          toast.success("You are logged in successfully.");
          localStorage.removeItem("forget_password_key");
          localStorage.removeItem("setPasswordToken");        
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  const togglePasswordVisibility = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowRetypePassword(!showRetypePassword);
    }
  };

  const passwordChecklistFun = (e) => {
    setCheckList(e);
  };

  const SuccessIcon = () => {
    return (
      <svg
        width="100"
        height="100"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          fill="none"
          stroke="#68E534"
          stroke-width="5"
          cx="100"
          cy="100"
          r="90"
          strokeLinecap="round"
          transform="rotate(-90 100 100)"
          className="circle"
        />
        <polyline
          fill="none"
          stroke="#68E534"
          points="44,114 86.5,142 152,69"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="tick"
        />
      </svg>
    )
  }

  return (
    <div className='bg-[#E5F8F8] h-screen w-full relative'>
      {/* <Nav showWorkspaceDetails={false} /> */}

      <div className='flex w-full h-full'>
        <div className='h-full w-[39.7%] pt-[50px] flex items-center justify-center z-20 relative'>
          <img src={homeBg} alt="img" className='w-full absolute left-0 top-0 h-screen' />
          <div className='px-[48px] text-white absolute'>
            <div className='font-medium text-5xl pb-5 flex justify-center'>Ignitho<br /> Data Accelerator</div>
            <div className='text-lg py-5 text-center'>Unlock the power of data acceleration with IDA</div>
            <div className='pt-5 pb-3 text-sm text-center'>The cutting-edge data accelerator solution designed to supercharge your organization's data-driven operations. </div>
            <div className='py-3 text-sm text-center'>Whether you're a small startup or a large enterprise, revolutionizes the way you handle data, empowering you to make faster, smarter decisions.</div>
            <div className='py-3 text-sm text-center'>Experience the future of data acceleration with Ignitho Data Product. Contact us today for a personalized demo and see how Ignitho Data Product can transform your organization's data capabilities.</div>
          </div>
        </div>

        <div className='w-[60.3%]'>
          <div className='flex items-center justify-center h-full'>
            <div className='w-[450px] py-5 bg-white flex gap-5 rounded-[20px] items-center flex-col drop-shadow-[0_0_30px_#00578F1A]'>
              {loader ?
                <div role="status">
                  <TailSpin visible={true}
                    height="50"
                    width="50"
                    color="#0083BF"
                    ariaLabel="tail-spin-loading"
                    radius="1" />
                </div>
                :
                <>
                  {showError ?
                    <div className="">
                      <div className="flex justify-center items-center">
                        <img className="h-44 w-44"
                          src={warningIcon}
                          alt="Error"
                        />
                      </div>
                      <div>
                        <p className="text-4xl text-center mb-4">
                          Sorry!!
                        </p>
                        <p className="px-5 text-center">{subHeading}</p>
                      </div>
                    </div> :
                    <div className='w-20 h-20 flex justify-center'>
                      <SuccessIcon />
                    </div>
                  }
                  {!showError && (
                    <>
                      <div>
                        <p className="text-center px-5">{subHeading}</p>
                      </div>
                      <p className="text-blue-1 font-semibold">
                        Set your password
                      </p>
                    </>
                  )}
                  {!showError && (
                    <>
                      <form onSubmit={handleSubmit(onSubmit)} className="w-full px-5">
                        <div>
                          <div className="flex mb-3">
                            <input className="bg-[#F7F7F7] w-[95%] focus:outline-0 focus:border-[#86b7fe] 
                            focus:border focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#E9E9E9] 
                            border px-3 py-2 rounded-md rounded-r-none" autoFocus
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter password"
                              defaultValue={watch("password")}
                              {...register("password")}
                            />
                            <span onClick={() => togglePasswordVisibility("password")} className='cursor-pointer flex justify-center items-center px-2 bg-[#E7E7E7]'>
                              {!showPassword ? <BsEyeSlash /> : <BsEye />}
                            </span>
                          </div>
                          {errors?.password?.message ? (
                            <div
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginLeft: "6px",
                              }}
                            >
                              {errors.password.message}
                            </div>
                          ) : null}

                          <div className="flex mb-3">
                            <input
                              className="bg-[#F7F7F7] w-[95%] focus:outline-0 focus:border-[#86b7fe] focus:border
                             focus:shadow-[0_0_0_0.15rem_rgba(13,110,253,.25)] border-[#E9E9E9] border px-3 py-2 
                             rounded-md rounded-r-none"
                              type={
                                showRetypePassword ? "text" : "password"
                              }
                              placeholder="Re-enter password"
                              defaultValue={watch("reTypePassword")}
                              {...register("reTypePassword")}
                            />
                            <span onClick={() => togglePasswordVisibility("reTypePassword")} className='cursor-pointer flex justify-center items-center px-2 bg-[#E7E7E7]'>
                              {!showRetypePassword ? <BsEyeSlash /> : <BsEye />}
                            </span>
                          </div>

                          {errors?.reTypePassword?.message ? (
                            <div
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginLeft: "6px",
                              }}
                            >
                              {errors.reTypePassword.message}
                            </div>
                          ) : null}

                          {watch("password") && (
                            <div className="m-2">
                              <PasswordChecklist
                                rules={[
                                  "minLength",
                                  "specialChar",
                                  "number",
                                  "capital",
                                  "match",
                                ]}
                                minLength={8}
                                value={watch("password")}
                                valueAgain={watch("reTypePassword")}
                                onChange={(e) => passwordChecklistFun(e)}
                                messages={{
                                  minLength:
                                    "The password must contain at least 8 characters.",
                                  specialChar:
                                    "The password must contain a special character.                                      ",
                                  number:
                                    "The password must contain a number.",
                                  capital:
                                    "The password must contain a capital letter.",
                                  match: "Passwords should be matched.",
                                }}
                              />
                            </div>
                          )}
                          <div className="mt-4">
                            {loading ? (
                              <button
                                className={`bg-blue-1 text-white w-full rounded-md py-1.5 hover:bg-[#00578F]`}
                              >
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className={`bg-blue-1 text-white w-full rounded-md py-1.5 hover:bg-[#00578F]`}
                                disabled={!checklist}
                              >
                                {loading ? "Loading.." : "Set Password"}
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetPassword
