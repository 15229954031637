import React, { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import calendarIcon from "../../../assets/RunOrScheduleAssets/calendar.svg";

const CustomDatePicker = (props) => {
  const { selectedDate, setSelectedDate } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const datePickerRef = useRef(null);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const handleDateInputClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  return (
    <div ref={datePickerRef} className="relative">
      <input
        type="text"
        value={formatDate(selectedDate)}
        readOnly
        onClick={handleDateInputClick}
        className="block w-full px-3 py-2 border border-[#B6B6B6] rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#212529] cursor-pointer"
      />
      <img
        src={calendarIcon}
        alt="calendar"
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
        onClick={handleDateInputClick}
      />

      {showCalendar && (
        <div className="absolute top-1/2 left-[100%] transform -translate-y-1/2 ml-2 z-10 border border-[#B6B6B6] rounded-md shadow-lg bg-white">
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;